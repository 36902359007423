import React, {useState, useEffect} from "react";
// nodejs library that concatenates classes
// import classNames from "classnames";
import {useDispatch, useSelector} from 'react-redux'
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {Title, Error} from '../Common';
import {containerStyle} from "../../Styles";
import {TextField, Button, Fade, CircularProgress} from '@material-ui/core';
import {createAccount, signIn, forgottenPassword} from '../../ActionReducers/Actions';
// Sections for this page

const useStyles = makeStyles(containerStyle);

export default function Password() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [error, setError] = useState(false)
    const [password, setPassword] = useState('')
    const {newUser, email, authError, authLoading, forgottenPasswordMessage} = useSelector(state => state.auth);
    useEffect(() => {
      if (!email) {
          history.push('/signin')
      }
    }, [email, history])

    function checkPassword() {
        if (password.length < 6) {
            setError(true)
        } else {
            if (newUser) {
                dispatch(createAccount(email, password, history))
            } else {
                dispatch(signIn(email, password, history))
            }
            
        }
    }

    let title;
    let ButtonText;
    if (newUser) {
        title = 'Welcome, please create a password.'
        ButtonText = 'create account'
    } else {
        title = 'Welcome back, please enter your password.'
        ButtonText = 'sign in'
    }

    return (
        <Fade in={true} timeout={{enter: 100}}>
            <div className={classes.answerDiv}>
            <div className={classes.raisedCard}>
            <Title title={title} />
                <TextField
                    error={error}
                    required
                    type='password'
                    id="password"
                    label="Password"
                    variant="outlined"
                    className={classes.detailsInput}
                    onChange={(a) => {setPassword(a.target.value); setError(false)}}
                    helperText="Must be at least 6 characters."
                />
                <br />
                {!authLoading && <Button variant="contained" color="primary" onClick={() => checkPassword()}>{ButtonText}</Button>}
                {authLoading &&  <CircularProgress />}
                <br/>
                <Button color="primary" onClick={() => dispatch(forgottenPassword(email))}>Forgotten password</Button>

                <Error title={authError}/>

                <Error title={forgottenPasswordMessage}/>
            </div>
            </div>
        </Fade>
    );
}