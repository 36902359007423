import React from 'react';
import {useSelector} from 'react-redux';
import {containerStyle, textStyle} from "../../../Styles";
import GridItem from "../../Grid/GridItem.js";
// import {onShowModal, onDeleteSelect, sharePost} from '../../actions';
import { makeStyles } from "@material-ui/core/styles";
import Watermark from '../../../Images/watermark.png';

const style = {
  ...containerStyle,
  ...textStyle
};

const useStyles = makeStyles(style);

export default function ImageListOld(props) {
    // const dispatch = useDispatch()
    const {userDoc} = useSelector(state => state.auth);
    const classes = useStyles();
    const { image, setImage } = props;
    
    if (userDoc) {
      if (userDoc.press) {
        return (
          <GridItem xs={12} sm={12} md={4}>
            <a href={image.url} target="blank">
                <img
                    src={image.urlSocial || image.url}
                    className={classes.postImage}
                    alt={'users'}
                />
            </a>           
          </GridItem>
        )
      } else if (image.type === 'photo') {
        return (
          <GridItem xs={12} sm={12} md={4} onClick={setImage}>
            <div>
                <img
                    src={image.urlSocial || image.url}
                    className={classes.postImage}
                    alt={'users'}
                />
                <img
                    src={Watermark}
                    className={classes.watermark}
                    alt={'users'}
                />
            </div>
              
          </GridItem>
        )
      }  else {
        return (
          <GridItem xs={12} sm={12} md={4}>
            <a href={image.url} target="blank">
                <img
                    src={image.urlSocial || image.url}
                    className={classes.postImage}
                    alt={'users'}
                />
            </a>
              
          </GridItem>
        )
      }
    } else {

      if (image.type === 'photo') {
        return (
          <GridItem xs={12} sm={12} md={4} onClick={setImage}>
            <div>
                <img
                    src={image.urlSocial || image.url}
                    className={classes.postImage}
                    alt={'users'}
                />
                <img
                    src={Watermark}
                    className={classes.watermark}
                    alt={'users'}
                />
            </div>
              
          </GridItem>
        )
      }  else {
        return (
          <GridItem xs={12} sm={12} md={4}>
            <a href={image.url} target="blank">
                <img
                    src={image.urlSocial || image.url}
                    className={classes.postImage}
                    alt={'users'}
                />
            </a>
              
          </GridItem>
        )
      }
      
    }
}