import React, { useEffect } from 'react';
// import {useDispatch} from 'react-redux';
// import {onShowModal, onDeleteSelect, sharePost} from '../../actions';
import List from '@material-ui/core/List';
import {getProducts, closeImageModal, onAddToBasket} from '../../ActionReducers/Actions';
import {useDispatch, useSelector} from 'react-redux'
import ListItemComponent from './ListItemComponent';
import { useHistory } from "react-router-dom";

export default function OrderList() {
    const dispatch = useDispatch()
    const {products} = useSelector(state => state.basket)
    // const [modalVis, setModalVis] = useState(false)
    // const {eventData, imageInModal} = useSelector(state => state.events)
    const {user} = useSelector(state => state.auth)
    const history = useHistory();
    useEffect(() => {
        dispatch(getProducts())
    }, [dispatch])

    return (
        <div>
            <List>
                {products.length > 0 && products.map((product) => {
                    return <ListItemComponent product={product} onClick={() => {dispatch(onAddToBasket(product, user, history)); dispatch(closeImageModal())}}/>
                })}
            </List>
        </div>
        
    )
}

//dispatch(addToBasket(eventData, imageInModal, product)