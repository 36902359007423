import React, {useState} from "react";
import {useDispatch, useSelector} from 'react-redux'
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {Title} from '../Common';
import {containerStyle, textStyle} from "../../Styles";
import {TextField, Button, Fade, Divider} from '@material-ui/core';
import {setAddress} from '../../ActionReducers/Actions';

const style = {
    ...containerStyle,
    ...textStyle
  }
const useStyles = makeStyles(style);

export default function Address() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const {userDoc} = useSelector(state => state.auth)
    const [address1, setAddress1] = useState()
    const [address2, setAddress2] = useState()
    const [city, setCity] = useState()
    const [county, setCounty] = useState()
    const [postcode, setPostcode] = useState()
    const [phone, setPhone] = useState()
    const [error, setError] = useState(null)
    // useEffect(() => {
    //     dispatch(getAddress(user))
    //     dispatch(getUser(user))
    // }, [dispatch, user])

    function onSetAddress() {
        if (!address1) {
            setError(1)
        } else if (!city) {
            setError(2)
        } else if (!county) {
            setError(3)
        } else if (!postcode) {
            setError(4)
        } else if (!phone) {
            setError(5)
        } else {
            dispatch(setAddress(address1,address2,city,county,postcode,phone,userDoc,history))
        }
    }

    return (
        <Fade in={true} out={true} timeout={{enter: 100}}>
        <div className={classes.answerDiv}>
          <div className={classes.raisedCard}>
          <Title title="Enter your shipping address." />
          <form style={{display: 'flex', flexDirection: 'column'}}>
            <TextField
              error={error === 1}
              required
              id="Address1"
              label="Address line 1"
              variant="outlined"
              className={classes.detailsInput}
              onChange={(a) => {setAddress1(a.target.value); setError(false)}}
            />
            <br/>
            <TextField
              id="Address2"
              label="Address line 2"
              variant="outlined"
              className={classes.detailsInput}
              onChange={(a) => setAddress2(a.target.value)}
            />
            <br/>
            <TextField
              error={error === 2}
              required
              id="City"
              label="City"
              variant="outlined"
              className={classes.detailsInput}
              onChange={(a) => {setCity(a.target.value); setError(false)}}
            />
            <br/>
            <TextField
              error={error === 3}
              required
              id="County"
              label="County"
              variant="outlined"
              className={classes.detailsInput}
              onChange={(a) => {setCounty(a.target.value); setError(false)}}
            />
            <br/>
            <TextField
              error={error === 4}
              required
              id="Postcode"
              label="Postcode"
              variant="outlined"
              className={classes.detailsInput}
              onChange={(a) => {setPostcode(a.target.value); setError(false)}}
            />
            <br/>
            <Divider />
            <br/>
            <TextField
              error={error === 5}
              required
              type="tel"
              id="phone"
              label="Phone number"
              variant="outlined"
              className={classes.detailsInput}
              onChange={(a) => {setPhone(a.target.value); setError(false)}}
            />
          </form>
          <br/>
          <Button variant="contained" color="primary" onClick={() => onSetAddress()}>Set address</Button>
          
           
          </div>
          
        </div>
        </Fade>
    );
}