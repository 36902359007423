import {
    GO_TO_PHOTO_UPLOAD,
    UNSORTED_FILES_SELECTED,
    UNSORTED_LOAD_IMAGES_START,
    UNSORTED_LOADED_SINGLE_IMAGE,
    UNSORTED_LOAD_IMAGES_FINISHED,
  } from '../Actions/Types';
  
  const INITIAL_STATE = {
    event: null,
    unsortedSelectedFiles: null,
    unsortedLoadingImages: false,
    unsortedImagesLoaded: 0,
    unsortedTotalFiles: 0,
  };
  
  export function PhotoReducer (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GO_TO_PHOTO_UPLOAD:
            return { ...state, event: action.payload}
        case UNSORTED_FILES_SELECTED:
            return { ...state, unsortedSelectedFiles: action.payload };
        case UNSORTED_LOAD_IMAGES_START:
            return { ...state, unsortedLoadingImages: true, unsortedTotalFiles: action.payload, unsortedImagesLoaded: 0 };
        case UNSORTED_LOADED_SINGLE_IMAGE:
            return { ...state, unsortedImagesLoaded: state.unsortedImagesLoaded + 1 };
        case UNSORTED_LOAD_IMAGES_FINISHED:
            return { ...state, unsortedLoadingImages: false };
      default:
        return state;
    }
  };