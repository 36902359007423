import { firebaseApp } from '../../firebase';
import _ from 'lodash';
import {generateUUID} from '../../Utils'
import {
    GET_PRODUCTS,
    ADD_TO_BASKET,
    ON_ADD_TO_BASKET,
    ADD_TO_BASKET_ERROR,
    BASKET_LOADING,
    GO_TO_BASKET,
    GET_BASKET,
    DELETE_BASKET_ITEM,
    UPDATE_QUANTITY,
    GET_ADDRESS,
    SET_ADDRESS,
    UPDATE_EMAIL,
    UPDATE_NAME,
    SET_BASKET_AS_ORDER,
    SET_ORDER,
    GET_ORDERS,
    ORDER_DETAIL,
} from './Types';

export const getProducts = () => {
    return async dispatch => {
      const products = await firebaseApp.firestore()
      .collection('products')
      .doc('0afMKqDNdKrfHAijbnfy')
      .get()

      const data = products.data()

      const ProductsSorted = _.sortBy(data.products, 'price')

      dispatch({
        type: GET_PRODUCTS,
        payload: ProductsSorted
      });
    }
  }

export const onAddToBasket = (product, user, history) => {
  return dispatch => {
    let to;
    if (user) {
        to = '/image-cropper'
    } else {
        to = '/signin'
    }

    dispatch({
      type: ON_ADD_TO_BASKET,
      payload: product,
    })


    history.push(to)
  }
  
}

// export const addToBasket = (image, cropOutline, imageData, selectedImage, item, selectedResult, user, history, quantity) => {
//   return async dispatch => {
//     dispatch({
//       type: BASKET_LOADING,
//     })
//     try {
//       if (!user) {
//         history.push('/signin')
//       }
//       const uuid = generateUUID()
//       // const storageRef = firebaseApp.storage('gs://athalens-51eb0-new').ref(`basket/${user}/${uuid}`)
//       // const metadata = {
//       //   contentType: 'image/jpeg'
//       // };
  
//       // //Converts the uri to an image file.
  
//       // const imageFile = dataURItoBlob(image)
//       // const store = await storageRef.put(imageFile, metadata)
//       const basket = await firebaseApp.firestore().collection('users')
//       .doc(user)
//       .collection('basket')
//       .doc(uuid)
//       .set({
//         item,
//         selectedImage,
//         selectedResult,
//         quantity,
//       })

//       const crop = await firebaseApp.firestore().collection('cropped')
//       .doc(uuid)
//       .set({
//         cropOutline,
//         imageData,
//         user,
//         url: selectedImage.url
//       })
  
//       dispatch({
//         type: ADD_TO_BASKET,
//         crop,
//         basket,
//       })
//     } catch (e) {
//       dispatch({
//         type: ADD_TO_BASKET_ERROR,
//       })
//     }
    
//   }
// }

export const addToBasket = (selectedImage, item, selectedResult, user, history, quantity) => {
  return async dispatch => {
    dispatch({
      type: BASKET_LOADING,
    })
    try {
      if (!user) {
        history.push('/signin')
      }
      const uuid = generateUUID()
      // const storageRef = firebaseApp.storage('gs://athalens-51eb0-new').ref(`basket/${user}/${uuid}`)
      // const metadata = {
      //   contentType: 'image/jpeg'
      // };
  
      // //Converts the uri to an image file.
  
      // const imageFile = dataURItoBlob(image)
      // const store = await storageRef.put(imageFile, metadata)
      const basket = await firebaseApp.firestore().collection('users')
      .doc(user)
      .collection('basket')
      .doc(uuid)
      .set({
        item,
        selectedImage,
        selectedResult,
        quantity,
      })


      dispatch({
        type: ADD_TO_BASKET,
        basket,
      })
    } catch (e) {
      dispatch({
        type: ADD_TO_BASKET_ERROR,
      })
    }
    
  }
}

export const goToBasket = (history) => {
  return dispatch => {
    dispatch({
      type: GO_TO_BASKET
    })
    history.push('/basket')
  }
}

export const continueShopping = (eventData, number, history) => {
  return dispatch => {
    dispatch({
      type: GO_TO_BASKET
    })
    history.push(`/event-view/${eventData}/${number}`)
  }
}

export const getBasket = (user) => {
  return dispatch => {
    dispatch({
      type: BASKET_LOADING
    })
    
    firebaseApp.firestore()
    .collection('users')
    .doc(user)
    .collection('basket')
    .onSnapshot((querySnapshot) => {
      const basketList = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data()
        data.uid = doc.id;
        basketList.push(data);
      })
      dispatch({
        type: GET_BASKET,
        payload: basketList
      }) 
    })
  }
}

export const removeFromBasket = (item, user, history) => {
  return dispatch => {
    firebaseApp.firestore()
    .collection('users')
    .doc(user)
    .collection('basket')
    .doc(item)
    .delete()
    .then(() => {
      history.push('/basket')
      dispatch({
        type: DELETE_BASKET_ITEM,
      }) 
    })

  }
}

export const setQuantity = (userId, basketId, quantity) => {
  return dispatch => {
    firebaseApp.firestore()
    .collection('users')
    .doc(userId)
    .collection('basket')
    .doc(basketId)
    .update({
      quantity
    })
    .then(() => {
      dispatch({
        type: UPDATE_QUANTITY,
      })
    })
  }
}

export const goToCheckout = (userdoc, user, history) => {
  return async dispatch => {
    const user1 = firebaseApp.auth().currentUser;
    console.log('userDoc: ', userdoc)
    console.log('user: ', user)
    console.log('email: ', user1.email)
    if (!userdoc) {

      console.log('hello')
      const address = {
        AddressLine1: null,
      }

      const profile = {
        email: user1.email
      }

      firebaseApp.firestore()
      .collection('users')
      .doc(user)
      .set({
        address,
        lastName: null,
        name: null,
        profile,
        userType: 'Athlete'
      })
      .then(() => {
        dispatch({
          type: GET_ADDRESS,
        })
        history.push('/username')
      })
    } else if (!userdoc.name || !userdoc.lastName) {
      dispatch({
        type: GET_ADDRESS,
      })
      history.push('/username')
    } else if (!userdoc.profile.email) {
      dispatch({
        type: GET_ADDRESS,
      })
      history.push('/update-email')
    } else if (!userdoc.address.AddressLine1) {
      dispatch({
        type: GET_ADDRESS,
      })
      history.push('/address')
    } else {
      dispatch({
        type: GET_ADDRESS,
      })
      history.push('/checkout')
    }

    
  }
}

export const setAddress = (address1,address2,city,county,postcode,phone,userDoc,history) => {
  return async dispatch => {
    let addressLine2;
    if (address2) {
      addressLine2 = address2
    } else {
      addressLine2 = ''
    }

    const address = {
      AddressLine1: address1,
      AddressLine2: addressLine2,
      City: city,
      County: county,
      PostCode: postcode,
      PhoneNumber: phone,
    }

    firebaseApp.firestore()
    .collection('users')
    .doc(userDoc.uid)
    .update({
      address
    })
    .then(() => {
      dispatch({
        type: SET_ADDRESS,
      })
      if (!userDoc.profile.email) {
        history.push('/update-email')
      } else if (!userDoc.address.AddressLine1) {
        history.push('/address')
      } else {
        history.push('/checkout')
      }
    })


  }
}

export const updateEmail = (user, email, history) => {
    return dispatch => {
      const profile = {
        email
      }
      firebaseApp.firestore()
      .collection('users')
      .doc(user.uid)
      .update({
        profile
      })
      .then(() => {

        dispatch({
          type: UPDATE_EMAIL,
        })
        if (!user.name || !user.lastName) {
          history.push('/update-name')
        } else if (!user.address.AddressLine1) {
          history.push('/address')
        } else {
          history.push('/checkout')
        }
      })
    }
}

export const updateName = (user, name, lastName, history) => {
  return dispatch => {
    firebaseApp.firestore()
    .collection('users')
    .doc(user.uid)
    .update({
      name,
      lastName
    })
    .then(() => {

      dispatch({
        type: UPDATE_NAME,
      })
      if (!user.profile.email) {
        history.push('/update-email')
      } else if (!user.address.AddressLine1) {
        history.push('/address')
      } else {
        history.push('/checkout')
      }
    })
  }
}

export const paymentSuccess = (user, basket, history) => {
  return async dispatch => {
    const timeOfOrder = Date.now()
    dispatch({
      type: SET_BASKET_AS_ORDER,
      payload: basket,
    })
    history.push('/payment-success')
    
    const itemObject = {};
    const photographersArray = [];
    const competitionsArray = [];

    basket.forEach((doc) => {
      const itemUid = doc.uid
      itemObject[itemUid] = doc;
      
      const { photographerUid, competitionUid } = doc.selectedImage;

      let competitionID;
      if (competitionUid === undefined) {
        competitionID = '1'
      } else {
        competitionID = competitionUid
      }

      photographersArray.push(photographerUid);
      competitionsArray.push(competitionID);
    })

    const photographers = [...new Set(photographersArray)];
    const competitions = [...new Set(competitionsArray)];

    console.log('competitions: ', competitions)

    return firebaseApp.firestore()
    .collection('customerOrders')
    .doc()
    .set({
      'items': itemObject,
      timeOfOrder,
      'userUid': user.uid,
      'photographers': photographers,
      'user': user,
      'competitions': competitions
    })
    .then(() => {
      console.log('succes in action')
      dispatch({
        type: SET_ORDER
      })
    })
    .catch(async (e) =>{
      console.log('failure in action', e)
    })
  }
}

export const getOrders = (user) => {
  return async dispatch => {
  
    dispatch({
      type: BASKET_LOADING
    })

    const orders = await firebaseApp.firestore()
    .collection('customerOrders')
    .where("userUid", '==', user)
    .get()
    

    const orderList = [];
    orders.forEach((doc) => {
      const data = doc.data()
      data.uid = doc.id;
      orderList.push(data);
    })

    const orderListSorted = _.sortBy(orderList, 'timeOfOrder').reverse()
    dispatch({
      type: GET_ORDERS,
      payload: orderListSorted,
    })
  }
}

export const orderDetail = (order, history) => {
  return dispatch => {
    dispatch({
      type: ORDER_DETAIL,
      payload: order
    })

    history.push('/order-detail')
  }
}