import React, {useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux';
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {Title} from '../../Common';
import {containerStyle, textStyle} from "../../../Styles";
import {Fade, ListItemText, ListItem, Divider, Button} from '@material-ui/core';
import {adminOrdersFetch, goToOrderDetail} from '../../../ActionReducers/Actions';

const style = {
    ...containerStyle,
    ...textStyle
  }
const useStyles = makeStyles(style);

export default function AdminOrders() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const {orders} = useSelector(state => state.admin)
    const {userDoc} = useSelector(state => state.auth)

      useEffect(() => {
        dispatch(adminOrdersFetch())
      }, [dispatch])
    return (
        <Fade in={true} out={true} timeout={{enter: 100}}>
        <div className={classes.answerDiv}>
          <div className={classes.raisedCard}>
          <Title title="Orders" />
          {userDoc && !userDoc.admin && <a href='/'>
            <Button variant="contained" color="primary" >Back</Button>
          </a>}
          {!userDoc && <a href='/'>
            <Button variant="contained" color="primary" >Back</Button>
          </a>}
          {userDoc && userDoc.admin && orders && orders.length > 0 && orders.map((event, index) => {
              return (
                  <div>
                    <ListItem button key={4} onClick={() => dispatch(goToOrderDetail(event, index, history))}>
                        <ListItemText primary={event.uid} secondary={event.orderNumber}/>
                    </ListItem>
                    <Divider />
                </div>
              )
          })}
          <br/>
            
          </div>
          
        </div>
        </Fade>
    );
}