import {colors} from './colors';
import {fontSizes, fontWeight} from './fontSizes'
import { container, title } from "./Main.js";

export const containerStyle = {
    view: {
        backgroundColor: colors.primaryGrey,
    },
    header: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '70px',
        width: '100%',
        display: 'flex',
        backgroundColor: '#fff',
        boxShadow: '0 4px 20px 10px rgba(116, 110, 110, 0.26);',
        borderTopWidth: '2px',
        borderTopColor: colors.primaryGreen,
        borderTopStyle: 'solid',
        zIndex: 9999
    },
    headerImage: {
        height: '45px',
        marginLeft: '10px',
        borderRadius: '30px',
        marginTop: '6px',
        top: '50%',
        left: '50%',
    },
    bigButton: {
        "&,&:hover,&:visited": {
        height: 'auto',
        width: 'auto',
        margin: '10px',
        marginBottom: '40px',
        paddingLeft: 'auto',
        borderRadius: '5px',
        background: '#63C5B9',
        color: colors.primaryBlack,
        fontSize: fontSizes.small,
        fontWeight: fontWeight.medium,
        fontFamily: "'Mukta', sans-serif",
        // boxShadow: '0 1px 3px 2px rgba(116, 110, 110, 0.26);',
      },
      "&:hover": {
        boxShadow: '0 0px 5px 1px rgba(116, 110, 110, 0.26);',
        borderColor: colors.primaryGreen,
      },
      "&:focus": {
        boxShadow: '0 0px 5px 1px rgba(116, 110, 110, 0.26);',
        borderWidth: '0px',
        outline: 'none',
        color: colors.primaryGreen,
        backgroundColor: colors.primaryGreen
      }
    },
    answerDiv: {
        textAlign: 'center',
        marginTop: '90px',
    },
    raisedCard: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      padding: '30px',
      maxWidth: 'fit-content',
      boxShadow: '0 0px 5px 1px rgba(116, 110, 110, 0.26);',
      borderRadius: '2px',
    },
    resultsCard: {
      display: 'flex',
      flexDirection: 'column',
      padding: '30px',
      maxWidth: '700px',
      margin: 'auto',
      borderRadius: '10px',
      marginBottom: '50px',
    },
    raisedResult: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: '300px',
      boxShadow: '0 0px 5px 1px rgba(116, 110, 110, 0.26);',
      borderRadius: '10px',
      marginBottom: '50px',
    },
    answerDivSlim: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    answerDivPlace: {
        textAlign: 'center',
        flexDirection: 'column',
        width: '340px',
        margin: 'auto'
    },
    answerRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '340px',
    },
    backButton: {
        "&,&:hover,&:visited": {
        minWidth: '200px',
        padding: '10px 20px',
        margin: '10px',
        color: colors.primaryGreen,
        borderColor: colors.primaryGreen,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '90px',
        backgroundColor: '#ffffff',
        fontSize: fontSizes.medium,
        fontWeight: 300,
        fontFamily: "'Mukta', sans-serif",
      },
      "&:hover": {
        boxShadow: '0 0px 2px 1px rgba(116, 110, 110, 0.26);',
      },
      "&:focus": {
        boxShadow: '0 0px 2px 1px rgba(116, 110, 110, 0.26);',
        outline: 'none',
      }
    },
    nextButton: {
        "&,&:hover,&:visited": {
        minWidth: '200px',
        padding: '10px 20px',
        margin: '10px',
        borderColor: colors.primaryGreen,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '90px',
        backgroundColor: colors.primaryGreen,
        color: colors.primaryWhite,
        fontSize: fontSizes.medium,
        fontWeight: 300,
        fontFamily: "'Mukta', sans-serif",
      },
      "&:hover": {
        boxShadow: '0 0px 2px 1px rgba(116, 110, 110, 0.26);',
      },
      "&:focus": {
        boxShadow: '0 0px 2px 1px rgba(116, 110, 110, 0.26);',
        outline: 'none'
      }
    },
    slimButton: {
        "&,&:hover,&:visited": {
        height: '40px',
        width: '300px',
        margin: '10px',
        borderColor: '#d1d1d1',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '10px',
        backgroundColor: '#ffffff',
        boxShadow: '0 1px 3px 1px rgba(116, 110, 110, 0.26);',
      },
      "&:hover": {
        boxShadow: '0 0px 5px 1px rgba(116, 110, 110, 0.26);',
        borderColor: colors.primaryGreen,
      },
      "&:focus": {
        boxShadow: '0 0px 5px 1px rgba(116, 110, 110, 0.26);',
        borderWidth: '0px',
        outline: 'none',
        color: '#fff',
        backgroundColor: colors.primaryGreen
      }
    },
    placeButton: {
        "&,&:hover,&:visited": {
        height: '40px',
        width: '150px',
        margin: '10px',
        borderColor: '#d1d1d1',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '10px',
        backgroundColor: '#ffffff',
        boxShadow: '0 1px 3px 1px rgba(116, 110, 110, 0.26);',
      },
      "&:hover": {
        boxShadow: '0 0px 5px 1px rgba(116, 110, 110, 0.26);',
        borderColor: colors.primaryGreen,
        color: colors.primaryGreen,
      },
      "&:focus": {
        boxShadow: '0 0px 5px 1px rgba(116, 110, 110, 0.26);',
        borderWidth: '0px',
        outline: 'none',
        color: '#fff',
        backgroundColor: colors.primaryGreen
      }
    },
    pickerButton: {
        "&,&:hover,&:visited": {
        height: '50px',
        width: '300px',
        borderColor: '#d1d1d1',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '10px',
        backgroundColor: '#ffffff',
        boxShadow: '0 1px 3px 1px rgba(116, 110, 110, 0.26);',
        paddingLeft: '10px',
        fontSize: '16px',
        fontFamily: "'Mukta', sans-serif",
        margin: 'auto',
      },
      "&:focus": {
        outline: 'none',
      },
      "&:hover": {
        boxShadow: '0 0px 5px 1px rgba(116, 110, 110, 0.26);',
        borderColor: colors.primaryGreen,
        color: colors.primaryGreen,
      },
    },
    progress: {
      width: '100%',
      backgroundColor: 'red'
    },
    directionButtons: {
      marginTop: '20px',
      marginBottom: '0px'
    },
    textArea: {
      width: '300px',
      height: '150px',
      borderRadius: '5px',
      fontFamily: "'Mukta', sans-serif",
      padding: '10px',
      fontSize: '16px',
      "&:focus": {
        outline: 'none',
      },
    },
    detailsDiv: {
      textAlign: 'center',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      width: '300px',
      backgroundColor: '#f1f1f1',
      margin: 'auto',
      padding: '30px',
      borderRadius: '10px',
      marginBottom: '100px',
    },
    detailsInput: {
      "&:focus": {
        outline: 'none',
        borderColor: colors.primaryBlack,
      },
      "&:hover": {
        outline: 'none',
      },
    },
    detailImage: {
      width: '120px',
      marginLeft: '10px',
      marginRight: '10px',
    },
    detailImageDiv: {
      marginBottom: '20px'
    },
    resultImageDiv: {
      width: '130px',
      maxHeight: '130px',
      float: 'right',
      borderRadius: '10px',
      marginTop: '30px',
      textAlign: 'center',
      zIndex: 2
    },
    resultDescDiv: {
      padding: '10px',
      maxWidth: '300px',
      minHeight: '180px',
      marginBottom: '30px',
      marginRight: 0,
    },
    resultImage: {
      width: '90px',
      boxShadow: '0 0px 5px 1px rgba(116, 110, 110, 0.26);',
      zIndex: 2,
    },
    cover: {
      position: 'absolute',
      width: '300px',
      height: '75px',
      objectFit: 'cover',
      zIndex: 1,
      borderRadius: '10px 10px 0px 00px'
    },
    container: {
      zIndex: "12",
      color: "#FFFFFF",
      ...container,
    },
    callToAction: {
      marginLeft: '-30px',
    },
    title: {
      ...title,
      display: "inline-block",
      position: "relative",
      marginTop: "30px",
      minHeight: "32px",
      color: "#FFFFFF",
      textDecoration: "none",
      fontFamily: "'Mukta', sans-serif",
      fontSize: 'calc(42px + 2vmin)',
    },
    parallax: {
      height: "50vh",
      maxHeight: "1000px",
      overflow: "hidden",
      position: "relative",
      backgroundPosition: "center center",
      backgroundSize: "cover",
      margin: "0",
      padding: "0",
      border: "0",
      display: "flex",
      alignItems: "center"
    },
    filter: {
      "&:before": {
        background: "rgba(0, 0, 0, 0)"
      },
      "&:after,&:before": {
        position: "absolute",
        zIndex: "1",
        width: "100%",
        height: "100%",
        display: "block",
        left: "0",
        top: "0",
        content: "''"
      }
    },
    small: {
      height: "380px"
    },
    main: {
      background: "#FFFFFF",
      position: "relative",
      zIndex: "3"
    },
    mainRaised: {
      margin: "-60px 15px 0px",
      borderRadius: "10px",
      paddingTop: '60px',
      paddingBottom: '30px',
      boxShadow:
        "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    },
    labelStyle: {
      color: colors.primaryBlack,
      fontSize: fontSizes.small,
      fontWeight: fontWeight.medium,
      fontFamily: "'Mukta', sans-serif",
    },
    containerFor5: {
      display: 'flex',
      margin: 'auto',
      alignItems: 'center',
      backgroundColor: 'red'
    },
    postDiv: {
        backgroundColor: '#fff',
        borderRadius: '3px',
        margin: 'auto',
        marginTop: '20px',
        marginBottom: '20px',
        boxShadow: "0px 2px 10px 0px #b5b5b5",
        width: '90%',
        height: 'auto',
    },
    postDiv2: {
        display: 'flex',
        flexDirection: 'row',
        width: 'auto'
    },
    thumbnailProfile: {
        width: '30px',
        height: '30px',
        borderRadius: '15px',
        textAlign: 'left',
        margin: '10px',
    },
    postImage: {
        maxWidth: '98%',
        maxHeight: '300px',
        height: 'auto',
        objectFit: 'cover',
        textAlign: 'center',
        zIndex: 1,
        marginBottom: 20,
        boxShadow: "0px 2px 10px 0px #b5b5b5",
    },
    postImageNoShadow: {
      width: '98%',
      height: 'auto',
      maxHeight: '200px',
      overflow: 'hidden',
      objectFit: 'cover',
      textAlign: 'center',
      zIndex: 1,
    },
    watermark: {
        maxWidth: '98%',
        maxHeight: '300px',
        height: 'auto',
        objectFit: 'cover',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 2,
        margin: 'auto',
        textAlign: 'center',
        opacity: 1,
    },
    watermarkCropped: {
        width: '300px',
        height: 'auto',
        objectFit: 'cover',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 2,
        margin: 'auto',
        textAlign: 'center',
        opacity: 1,
    },
    watermarkBasket: {
        width: '200px',
        height: 'auto',
        objectFit: 'cover',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 2,
        margin: 'auto',
        textAlign: 'center',
        opacity: 1,
    },
    raisedCheckout: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      marginBottom: 10,
      padding: '30px',
      width: '250px',
      boxShadow: '0 0px 5px 1px rgba(116, 110, 110, 0.26);',
      borderRadius: '2px',
    },
}