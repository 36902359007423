import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Drawer from './Drawer';
import image from '../../Images/athalens_logo.png'
import {containerStyle} from "../../Styles";
const useStyles = makeStyles(containerStyle);


export function Header() {
  const classes = useStyles();

  return (
      <AppBar color="inherit" position="fixed" >
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <Drawer />
          </IconButton>
            <a href="/">
                <img src={image} className={classes.headerImage} alt='header' />
            </a>
        </Toolbar>
      </AppBar>
  );
}