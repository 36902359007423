import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

import {containerStyle} from "../../Styles";
const useStyles = makeStyles(containerStyle);
export function PlaceButton(props) {
    const {title, onClick} = props;
    const classes = useStyles();
    return (
        <div className='fadeIn1'>
            <button
                className={classes.placeButton}
                onClick={onClick}
            >{title}</button>            
        </div>
        
    );
}