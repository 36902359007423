import React, {useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import {Title} from '../../Common';
import {containerStyle, textStyle} from "../../../Styles";
import {Fade, TextField, Divider, Button} from '@material-ui/core';
import {getPhotographersPhotos} from '../../../ActionReducers/Actions';

const style = {
    ...containerStyle,
    ...textStyle
  }
const useStyles = makeStyles(style);

export default function ViewPhotographersPhotos() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {photographerPhotos} = useSelector(state => state.admin)
    const {userDoc} = useSelector(state => state.auth)
    const [photographerUid, setPhotographerUid] = useState('')

    console.log('photos: ', photographerPhotos);

    return (
        <Fade in={true} out={true} timeout={{enter: 100}}>
        <div className={classes.answerDiv}>
          <div className={classes.raisedCard}>
          <Title title="View photos" />
          {userDoc && !userDoc.admin && <a href='/'>
            <Button variant="contained" color="primary" >Back</Button>
          </a>}
          {!userDoc && <a href='/'>
            <Button variant="contained" color="primary" >Back</Button>
          </a>}

          
            <TextField
                required
                type='text'
                id="email"
                label="Photographer uid"
                variant="outlined"
                className={classes.detailsInput}
                onChange={(a) => {setPhotographerUid(a.target.value)}}
            />

            <br />
            <Button variant="contained" color="primary" onClick={() => dispatch(getPhotographersPhotos(photographerUid))}>Go</Button>
          {userDoc && userDoc.admin && photographerPhotos && photographerPhotos.length > 0 && photographerPhotos.map((photo, index) => {
              return (
                  <div>
                    <img alt='hello' src={photo.urlSocial} style={{width: '500px', height: 'auto'}} />
                    <Divider />
                </div>
              )
          })}
          <br/>
            
          </div>
          
        </div>
        </Fade>
    );
}