import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

import {textStyle} from "../../Styles";
const useStyles = makeStyles(textStyle);
export function ResultTitle(props) {
    const {title} = props;
    const classes = useStyles();
    return (
        <h1 className={classes.resultTitle}>{title}</h1>
        
    );
}