import React, {useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux';
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {Title} from '../Common';
import {containerStyle, textStyle} from "../../Styles";
import {Fade, ListItemText, ListItem, Divider} from '@material-ui/core';
import {eventsFetchPhotographer, goToUploadPhotos} from '../../ActionReducers/Actions';

const style = {
    ...containerStyle,
    ...textStyle
  }
const useStyles = makeStyles(style);

export default function PhotographersEventList() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const {eventList} = useSelector(state => state.events)
      useEffect(() => {
        const date = (Date.now()/1000)
        dispatch(eventsFetchPhotographer(date))
      }, [dispatch])
    console.log(eventList)
    return (
        <Fade in={true} out={true} timeout={{enter: 100}}>
        <div className={classes.answerDiv}>
          <div className={classes.raisedCard}>
          <Title title="Select event" />
          {eventList && eventList.length > 1 && eventList.map((event) => {
              return (
                  <div>
                    <ListItem button key={4} onClick={() => dispatch(goToUploadPhotos(event, history))}>
                        <ListItemText primary={event.name} secondary={event.date + '   (' + event.uid + ')'} />
                    </ListItem>
                    <Divider />
                </div>
              )
          })}
          <br/>
            
          </div>
          
        </div>
        </Fade>
    );
}