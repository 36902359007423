import React from 'react';
import {useDispatch} from 'react-redux';
import {removeFromBasket, setQuantity} from '../../ActionReducers/Actions';
import {Title} from '../Common';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useHistory } from "react-router-dom";
import Watermark from '../../Images/watermark.png';
import {containerStyle} from '../../Styles';
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(containerStyle);

export default function BasketItem(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const { item, user } = props;
    const price = '£' + item.item.price
    function handleChange(event) {
        dispatch(setQuantity(user, item.uid, event.target.value));
    };
    return (
      <div>
        <ListItem>
            <div style={{display:"flex", width: '300px', flexDirection:"column"}}>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"flex-end"}}>
                <img
                    src={item.selectedImage.urlCropped || item.selectedImage.url}
                    style={{height: '120px', width: 'auto', maxWidth: '250px'}}
                    alt='horse'
                />
                <img
                    src={Watermark}
                    className={classes.watermarkBasket}
                    alt={'users'}
                />
                <FormControl variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">Quantity</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={item.quantity}
                        onChange={handleChange}
                        label="Age"
                        >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={7}>7</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                        <MenuItem value={9}>9</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                    </Select>
                </FormControl>
                </div>
                <br/>
                <div style={{display:"flex", flexDirection:"column", alignItems:"flex-end"}}>
                <ListItemText primary={item.item.name}/>
                <br/>
                <Title title={price}/>
                <Button onClick={() => dispatch(removeFromBasket(item.uid, user, history))} variant="outlined">Remove</Button>
                </div>
            </div>
            
            
        </ListItem>
        <Divider />
      </div>
    )
}