import React, {useEffect} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import {useDispatch, useSelector} from 'react-redux'

import { makeStyles } from "@material-ui/core/styles";
import {ParallaxTitle, Title, Footer, RiderEvent} from '../Common';
import {containerStyle} from "../../Styles";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Parallax from '../Grid/Parallax';
import {getRidersEntries, setRiderEvent} from '../../ActionReducers/Actions';
import { useHistory } from "react-router";
// Sections for this page

const useStyles = makeStyles(containerStyle);

export default function RiderProfile() {
    const classes = useStyles();
    const history = useHistory()
    const dispatch = useDispatch()
    const {rider, riderEntries, totalEntries} = useSelector(state => state.rider)

    useEffect(() => {
      if (rider) {
          dispatch(getRidersEntries(rider))
      } else {
        history.push('/rider-search')
      }
    }, [dispatch, rider, history])

    console.log(totalEntries)
    const Background = 'https://firebasestorage.googleapis.com/v0/b/athalens-51eb0-profile-pics/o/athalens-color.png?alt=media&token=24d88ce6-8cfa-4312-a160-e7416c55e150'

    const title1 = 'Entries'

    return (
      <div className={classes.answerDiv}>
        <Parallax filter image={Background} fixedImage={true}>
                <div className={classes.container}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    {rider && <ParallaxTitle title={rider.Rider} />}
                </GridItem>
                </GridContainer>
                
                </div>
          </Parallax>
          <div className={classNames(classes.main, classes.mainRaised)}>
            <Title title={title1} />
            {riderEntries.length > 0 && riderEntries.map(event => <RiderEvent event={event} onClick={() => dispatch(setRiderEvent(event, totalEntries, history))}/>)}
          </div>
          <Footer />
      </div>
    );
}
