import React, {useEffect} from "react";
import {useSelector} from 'react-redux'
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {Title} from '../Common';
import {containerStyle, textStyle} from "../../Styles";
import {Fade} from '@material-ui/core';
import _ from 'lodash';

import Checkout1 from '../Checkout/Checkout1';
const style = {
    ...containerStyle,
    ...textStyle
  }
const useStyles = makeStyles(style);
console.log('adding the card1111111111111')
export default function AddCard() {
    const classes = useStyles();
    const history = useHistory();
    const {userDoc} = useSelector(state => state.auth)
    const {basket} = useSelector(state => state.basket)
      useEffect(() => {
            if (!userDoc || !basket) {
                  history.push('/basket')
            }
      }, [history, userDoc, basket])

    return (
        <Fade in={true} out={true} timeout={{enter: 100}}>
        <div className={classes.answerDiv}>
          <div className={classes.raisedCard}>
          <Title title="Enter card details" />
          <br/>
            {basket && <div className={classes.raisedCheckout}>
                  <Checkout1 price={showTotal(basket)} user={userDoc}/> 
            </div>}
          </div>
          
        </div>
        </Fade>
    );
}

function showTotal(basket) {
    const postage = []
    const total = []
    basket.forEach((doc) => {
        postage.push(doc.item.pAndP)
        if (!doc.quantity) {
            total.push(doc.item.price)
          } else {
                const price = doc.item.price * doc.quantity
                total.push(price)
          }
    })

    const sorted = _.sortBy(postage).reverse();
    const postagePrice = sorted[0];
    const imagePrice = total.reduce((a, b) => a + b)
    const price = (postagePrice + imagePrice).toFixed(2)
    return price
}