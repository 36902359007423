import {
    SET_USER,
    IS_NEW_USER,
    SIGN_IN,
    SIGN_IN_ERROR,
    CREATE_ACCOUNT,
    CREATE_ACCOUNT_ERROR,
    AUTH_LOADING,
    SIGN_OUT,
    SIGN_OUT_ERROR,
    GET_USER,
    FORGOTTEN_PASSWORD,
  } from '../Actions/Types';
  
  const INITIAL_STATE = {
    user: null,
    newUser: false,
    authError: '',
    authLoading: false,
    userDoc: null,
    forgottenPasswordMessage: '',
  };
  
  export function AuthReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_USER:
            return { ...state, user: action.payload };
        case IS_NEW_USER:
            return {...state, newUser: action.newUser, email: action.email, authLoading: false};
        case SIGN_IN:
            return { ...state, user: action.payload, authError: '', authLoading: false };
        case SIGN_IN_ERROR:
            return { ...state, authError: action.payload, authLoading: false };
        case CREATE_ACCOUNT:
            return { ...state, user: action.payload, authError: '', authLoading: false };
        case CREATE_ACCOUNT_ERROR:
            return { ...state, authError: action.payload, authLoading: false };
        case AUTH_LOADING:
            return {...state, authError: '', authLoading: true}
        case SIGN_OUT:
            return {...state, authError: '', authLoading: false}
        case SIGN_OUT_ERROR:
            return {...state, authError: action.payload, authLoading: false}
        case GET_USER:
            return {...state, userDoc: action.payload}
        case FORGOTTEN_PASSWORD:
            return {...state, forgottenPasswordMessage: action.message}
      default:
        return state;
    }
  };
  