import { firebaseApp } from '../../firebase';
import _ from 'lodash';

import {
  DEFAULT_CASE,
  EVENTS_FETCH,
  IND_EVENT_FETCH,
  SEARCHING_FOR_IMAGES,
  SEARCHED_FOR_IMAGES,
  SET_IMAGE_MODAL,
  SET_NUMBER,
  CLOSE_IMAGE_MODAL,
  SEARCHED_FOR_IMAGES_OLD,
  SEARCHED_BY_TIME_SJ,
  SEARCHED_BY_TIME_XC,
  SEARCHED_BY_TIME_DR,
  SEARCHED_BY_TIME_MAIN,
  PRIZE_GIVING,
} from './Types';

import {apiInstanceSearchNumber, apiInstanceSearchNumberApp} from '../../Utils';

export const defaultAction = () => {
    return (dispatch) => {
      dispatch({
        type: DEFAULT_CASE,
      });
    } 
  };

export const setNumber = (number) => {

  return (dispatch) => {
    dispatch({
      type: SET_NUMBER,
      payload: number
    });
  } 
}

export function eventsFetch(date) {
  return async dispatch => {
    const day1 = Number(date + 86400);
    // const day1 = Number(date + 777600);
    // dispatch({ type: GETTING_EVENT_LIST });
    const eventList = await firebaseApp.firestore()
    .collection('competitions')
    .where('timestampStart', "<", day1)
    //so that we only show Oasby
    .where('timestampStart', ">", 1620561681)
    .get()

    const EventList = []

    eventList.docs.forEach((doc) => {
      if (doc.id === "H21PLtSB3WeQBUqDsLCa" || doc.id === "1cb02L5NAu0jVnSuCwxa" || doc.id === "6sl0nXR8Yug0wC4tyGEm" || doc.id === 'RCBu8mcAxENz8BD2xUf1' || doc.id === 'Qx0UvIb1iGYnAGKma1J4' || doc.id === 'iUPGOUypUdUr7ghjXyi4' || doc.id === 'k7Q8CjSGBfpCGoXSPDUl') {
        return
      } else {
        const resultObj = doc.data();
        resultObj.uid = doc.id;
        EventList.push(resultObj);
      }
      
    })

    const EventListSorted = _.sortBy(EventList, 'timestampStart').reverse();
    
    dispatch({
      type: EVENTS_FETCH,
      payload: EventListSorted
    })
  }
}

export function eventsFetchPhotographer(date) {
  return async dispatch => {
    const day1 = Number(date + 604800);
    // dispatch({ type: GETTING_EVENT_LIST });
    const eventList = await firebaseApp.firestore()
    .collection('competitions')
    .where('timestampStart', "<", day1)
    .get()

    const EventList = []

    eventList.docs.forEach((doc) => {
      if (doc.id === "1cb02L5NAu0jVnSuCwxa" || doc.id === "6sl0nXR8Yug0wC4tyGEm") {
        return
      } else {
        const resultObj = doc.data();
        resultObj.uid = doc.id;
        EventList.push(resultObj);
      }
      
    })

    const EventListSorted = _.sortBy(EventList, 'timestampStart').reverse();
    
    dispatch({
      type: EVENTS_FETCH,
      payload: EventListSorted
    })
  }
}

export function individualEventFetch(uid) {
  return async dispatch => {
    const event = await firebaseApp.firestore()
    .collection('competitions')
    .doc(uid)
    .get()

    const eventData = event.data();
    eventData.uid = event.id

    dispatch({
      type: IND_EVENT_FETCH,
      payload: eventData
    })
  }
}

export function searchNumber(number, event) {
  return async dispatch => {
    dispatch({
      type: SEARCHING_FOR_IMAGES
    })
    const string = number.toString()

    //////// GETTING_IMAGES
    const images = await firebaseApp.firestore()
    .collection('competitions')
    .doc(event)
    .collection('images')
    .where('number', '==', string)
    .get()

    const ImageList = []

    images.docs.forEach((doc) => {
        const imgObj = doc.data();
        imgObj.uid = doc.id;
        imgObj.type = 'photo'
        ImageList.push(imgObj);      
    })

    const ImageListSorted = _.sortBy(ImageList, 'dateTimeOriginal');

    //////// GETTING DRESSAGE 
    const dressage = await firebaseApp.firestore()
    .collection('competitions')
    .doc(event)
    .collection('dressageSheets')
    .where('number', '==', string)
    .get()

    const DressageList = []

    dressage.docs.forEach((doc) => {
        const imgObj = doc.data();
        imgObj.uid = doc.id;
        imgObj.type = 'dressageSheet'
        DressageList.push(imgObj);      
    })

    const dressageListSorted = _.sortBy(DressageList, 'timestamp');
    console.log(dressageListSorted)

    const concatArrays = ImageListSorted.concat(dressageListSorted)
    dispatch({
      type: SEARCHED_FOR_IMAGES_OLD,
      payload: concatArrays
    })

  }
}

export function searchNumberES(number, event, eventid) {
    return async (dispatch) => {
      console.log('searching for images')
      dispatch({
        type: SEARCHING_FOR_IMAGES
      })
      console.log('Data: ', event, number, eventid)
      const response = await apiInstanceSearchNumber.post('/images/get', {event, number, eventid})
      // const response = await apiInstanceSearchNumberApp.post('/images/app/get', {event, number, eventid})

      const {data} = response

      const {drPhotos, sjPhotos, xcPhotos, xcWarning} = data
      console.log('data: ', data)

      let sjPhotosLet
      if (sjPhotos === "We can't see that number entered at this event") {
        console.log('here')
        sjPhotosLet = "Please try searching by time"
      } else {
        sjPhotosLet = sjPhotos
      }

      let xcPhotosLet
      if (xcPhotos === "We can't see that number entered at this event") {
        console.log('here1')
        xcPhotosLet = "Please try searching by time"
      } else {
        xcPhotosLet = xcPhotos
      }

      let drPhotosLet
      if (drPhotos === "We can't see that number entered at this event") {
        console.log('here3')
        drPhotosLet = "Please try searching by time"
      } else {
        drPhotosLet = drPhotos
      }

      dispatch({
        type: SEARCHED_FOR_IMAGES,
        drPhotos: drPhotosLet,
        sjPhotos: sjPhotosLet,
        xcPhotos: xcPhotosLet,
        xcWarning,
      })
    }
}

export function setImageModal(index, bool) {
  return dispatch => {

    dispatch({
      type: SET_IMAGE_MODAL,
      payload: index,
      visible: bool,
    })
    
  }
}

export function closeImageModal() {
  return dispatch => {
    dispatch({
      type: CLOSE_IMAGE_MODAL,
    })
  }
}

export function searchByTime(eventid, day, hour, min, disc) {
  
  return async dispatch => {
    dispatch({
      type: SEARCHING_FOR_IMAGES
    })
    const dayInt = parseInt(day)
    console.log(eventid, day, min, hour, disc)

    const hours = hour * 3600000

    const mins = min * 60000

    const minsEnd = (30 * 60000) + mins
  

    const firstTime = (dayInt + hours + mins) / 1000

    const lastTime = (dayInt + hours + minsEnd) / 1000

      // //////// GETTING_IMAGES
      const images = await firebaseApp.firestore()
      .collection('competitions')
      .doc(eventid)
      .collection('images')
      .where('dateTimeOriginal', '>=', firstTime)
      .where('dateTimeOriginal', '<', lastTime)
      .where('disc', '==', disc)
      .get()

      console.log('images: ', images.docs)

      if (disc === 'XC') {
        const XCImageList = [];
        const numberList = [];
        const groupArray = [];

        images.docs.forEach((doc) => {
          const imgObj = doc.data();
          imgObj.uid = doc.id;
          imgObj.type = 'photo'
          XCImageList.push(imgObj);
          numberList.push(imgObj.photographerUid)
        })
        
        const numberListUnique = [...new Set(numberList)];
        console.log('numberListUnique: ', numberListUnique)

        for (let num of numberListUnique) {
          const individualPhotographerArray = [];
          for (let img of XCImageList) {
            if (img.photographerUid === num) {
              individualPhotographerArray.push(img)
            }
          }

          console.log('individualPhotographerArray:', individualPhotographerArray)

          const individualPhotographerArraySorted = _.sortBy(individualPhotographerArray, 'dateTimeOriginal').reverse();
          groupArray.push(individualPhotographerArraySorted)
        }
        console.log('groupArray: ', groupArray)
        dispatch({
          type: SEARCHED_BY_TIME_XC,
          payload: groupArray
        })

      } else if (disc === 'Dressage') {
        const DrImageList = []
        const DrNumberList = [];
        const DrGroupArray = [];

        images.docs.forEach((doc) => {
          const imgObj = doc.data();
          imgObj.uid = doc.id;
          imgObj.type = 'photo'
          DrImageList.push(imgObj);
          DrNumberList.push(imgObj.photographerUid)     
        })

        const drNumberListUnique = [...new Set(DrNumberList)];

        const drIndividualPhotographerArraySorted = _.sortBy(DrImageList, 'dateTimeOriginal').reverse();

        for (let tog of drNumberListUnique) {
          for (let img of drIndividualPhotographerArraySorted) {
            if (img.photographerUid === tog) {
              DrGroupArray.push(img)
            }
          }

          // const drIndividualPhotographerArraySorted = _.sortBy(drIndividualPhotographerArray, 'dateTimeOriginal').reverse();
          // DrGroupArray.push(drIndividualPhotographerArraySorted)
        }

        dispatch({
          type: SEARCHED_BY_TIME_DR,
          payload: DrGroupArray
        })
      } else {
        const ImageList = []

        images.docs.forEach((doc) => {
          const imgObj = doc.data();
          imgObj.uid = doc.id;
          imgObj.type = 'photo'
          ImageList.push(imgObj);      
        })

        const ImageListSorted = _.sortBy(ImageList, 'dateTimeOriginal');

        dispatch({
          type: SEARCHED_BY_TIME_SJ,
          payload: ImageListSorted
        })
      }

  }
}

export function searchByTimeMain(eventid, day, hour, min, disc) {
  return async dispatch => {
    dispatch({
      type: SEARCHING_FOR_IMAGES
    })
    const dayInt = parseInt(day)

    console.log('searchByTimeMain', eventid, day, hour, min, disc)

    const hours = hour * 3600000

    const mins = min * 60000

    const minsEnd = (30 * 60000) + mins
  

    const firstTime = (dayInt + hours + mins) / 1000

    const lastTime = (dayInt + hours + minsEnd) / 1000

      // //////// GETTING_IMAGES
      const images = await firebaseApp.firestore()
      .collection('competitions')
      .doc(eventid)
      .collection('images')
      .where('dateTimeOriginal', '>=', firstTime)
      .where('dateTimeOriginal', '<', lastTime)
      .where('disc', '==', disc)
      .get()

      console.log('images: ', images.docs)
      const ImageList = []

        images.docs.forEach((doc) => {
          console.log(doc.id)
          const imgObj = doc.data();
          imgObj.uid = doc.id;
          imgObj.type = 'photo'
          ImageList.push(imgObj);      
        })

      const ImageListSorted = _.sortBy(ImageList, 'dateTimeOriginal');

      dispatch({
        type: SEARCHED_BY_TIME_MAIN,
        payload: ImageListSorted
      })
  }
}

export function searchByTimeArena(eventid, day, hour, min, disc) {
  return async dispatch => {
    dispatch({
      type: SEARCHING_FOR_IMAGES
    })
    const dayInt = parseInt(day)

    console.log('searchByTimeHere', eventid, day, hour, min, disc)

    const hours = hour * 3600000

    const mins = min * 60000

    // const minsEnd = hours + 3600000

    const minsEnd = (30 * 60000) + mins
  

    const firstTime = (dayInt + hours + mins) / 1000

    const lastTime = (dayInt + hours + minsEnd) / 1000

    console.log('firstTime: ', firstTime)
    console.log('lastTime: ', lastTime)

      // //////// GETTING_IMAGES
      const images = await firebaseApp.firestore()
      .collection('competitions')
      .doc(eventid)
      .collection('images')
      .where('dateTimeOriginal', '>=', firstTime)
      .where('dateTimeOriginal', '<', lastTime)
      .where('arena', '==', disc)
      .get()

      const ImageList = []

        images.docs.forEach((doc) => {
          const imgObj = doc.data();
          imgObj.uid = doc.id;
          imgObj.type = 'photo'
          ImageList.push(imgObj);      
        })

      const ImageListSorted = _.sortBy(ImageList, 'dateTimeOriginal');

      dispatch({
        type: SEARCHED_BY_TIME_MAIN,
        payload: ImageListSorted
      })
  }
}

export function searchPrizeGiving(eventid, section) {

  return async dispatch => {

    dispatch({
      type: SEARCHING_FOR_IMAGES
    })

    try {
      const num = String(section)
      const images = await firebaseApp.firestore()
      .collection('competitions')
      .doc(eventid)
      .collection('images')
      .where('disc', '==', 'Prize giving')
      .where('fenceNumber', '==', num)
      .get()
  
      const ImageList = []
  
        images.docs.forEach((doc) => {
          const imgObj = doc.data();
          imgObj.uid = doc.id;
          imgObj.type = 'photo'
          ImageList.push(imgObj);      
        })
  
      const ImageListSorted = _.sortBy(ImageList, 'dateTimeOriginal');
      console.log(ImageListSorted)
      dispatch({
        type: PRIZE_GIVING,
        payload: ImageListSorted
      })
    } catch (error) {
      console.log(error)
    }


  }
}