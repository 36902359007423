import axios from 'axios';

export const apiInstance = axios.create({
    baseURL: 'https://us-central1-athalens-51eb0.cloudfunctions.net/newStripeCharge'
})

export const apiInstanceSearchNumber = axios.create({
    baseURL: 'https://us-central1-athalens-51eb0.cloudfunctions.net/searchNumberES'
})

export const apiInstanceSearchNumberTest = axios.create({
    baseURL: 'https://us-central1-athalens-51eb0.cloudfunctions.net/searchNumberTest'
})

export const apiInstanceSearchNumberApp = axios.create({
    baseURL: 'https://us-central1-athalens-51eb0.cloudfunctions.net/appSearchNumbers'
})

export const generateUUID = () => { // Public Domain/MIT
    var d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function'){
        d += performance.now(); //use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
    });
}

export const getTimeStamp = (day, month, year) => {
    console.log(day, month, year)
    const dayStr = oneOrTwo(day)
    const monthStr = oneOrTwo(month)
    const yearStr = oneOrTwo(year)

    const dateString = yearStr + '-' + monthStr + '-' + dayStr+ 'T00:00:00.000Z'
    console.log(dateString)
    const newDate = new Date(dateString);
    const mili = newDate.getTime()/1000
    console.log(mili)
    return mili
}

export const getTimePretty = (time) => {
    const hour = oneOrTwo(new Date(time).getHours())
    const min = oneOrTwo(new Date(time).getMinutes())  
    const offSet = new Date(time).getTimezoneOffset()/60
    const offSetNumber = Number(offSet)
    const newHour = Number(hour) + offSetNumber
    return newHour + ':' + min
}


function oneOrTwo(item) {
    if (typeof item !== 'string') {
        const str = item.toString()

        if (str.length === 1) {
            return '0' + str
        } else {
            return str
        }

    } else {

        if (item.length === 1) {
            return '0' + item
        } else {
            return item
        }
    }
}