import React from 'react';
// import {useDispatch} from 'react-redux';
import {containerStyle, textStyle} from "../../Styles";
import GridItem from "../Grid/GridItem.js";
// import {onShowModal, onDeleteSelect, sharePost} from '../../actions';
import { makeStyles } from "@material-ui/core/styles";

const style = {
  ...containerStyle,
  ...textStyle
}
const useStyles = makeStyles(style);

export default function Event(props) {
    const classes = useStyles();
    const { event } = props;

    const link = '/event-view/' + event.uid


    return (
      <GridItem xs={12} sm={12} md={3}>
        <a href={link} style={{textDecoration: 'none'}}>
        <div className={classes.postDiv} >
          <div className={classes.postDiv2}>
            {event.profile.profilePicture && <img
              className={classes.thumbnailProfile}
              src={event.profile.profilePicture.url64}
              alt={'User name'}
            />}
            <div>
              <p className={classes.name}>
                {event.name}
              </p>
              <p className={classes.date}>
              {event.date}
              </p>
            </div>
          </div>
          {event.profile.profilePicture &&
            <img
              src={event.profile.profilePicture.url || event.profile.profilePicture.url1080}
              className={classes.postImageNoShadow}
              alt={'users'}
            />}
        </div>
        </a>
      </GridItem>
    )
}