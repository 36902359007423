import React, {useState} from "react";
import {useDispatch} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import {Title} from '../../Common';
import {containerStyle, textStyle} from "../../../Styles";
import {Fade, TextField, Button} from '@material-ui/core';
import {addSponsor} from '../../../ActionReducers/Actions';

const style = {
    ...containerStyle,
    ...textStyle
  }
const useStyles = makeStyles(style);

export default function AdminAddSponsor() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [eventID, setEventID] = useState()
    const [sponsorImage, setSponsorImage] = useState()

    console.log(sponsorImage)
    return (
        <Fade in={true} out={true} timeout={{enter: 100}}>
        <div className={classes.answerDiv}>
          <div className={classes.raisedCard}>
          <Title title="Select event" />

            <TextField
                type='eventid'
                id="eventid"
                label="eventid"
                variant="outlined"
                className={classes.detailsInput}
                onChange={(a) => {setEventID(a.target.value)}}
            />
            <input type="file" style={{ backgroundColor: '#d1faf0', padding: 20, borderRadius: 5}} onChange={e => setSponsorImage(e.target.files)} accept=".jpg,.jpeg,.png"/>
          <br/>
          <Button
            variant="contained"
            color="primary"
            // onClick={() => console.log(eventID, sponsorImage[0].name)}
            onClick={() => dispatch(addSponsor(eventID, sponsorImage))}
        >Enter</Button>
          </div>
        </div>
        </Fade>
    );
}