import React, {useState} from 'react';
import XCImageView from './XCImageView';
import {Error} from '../../Common';
import { getTimePretty } from '../../../Utils';
import { Button } from "@material-ui/core";
import SearchByTime from '../Components/SearchByTime';

export default function XCView(props) {
    
    const {images, loadingImages, eventData, eventid, warning} = props
    const [showSearchByTime, setShowSearchByTime] = useState(false)

    if (typeof images === 'string') {
        return (
            <div>
            {!showSearchByTime && <Error title={images} />}
            <br/>
            <br/>
            {!showSearchByTime && <Button
                color="primary"
                variant="outlined"
                onClick={() => setShowSearchByTime(true)}
            >
                Search by time
            </Button>}
            
            {showSearchByTime && <SearchByTime eventid={eventid} eventData={eventData} loadingImages={loadingImages} disc={'XC'}/>}

            {showSearchByTime && <Button
                color="primary"
                variant="outlined"
                onClick={() => setShowSearchByTime(false)}
            >
                Cancel
            </Button>}
            </div>
        )
    } else if (typeof images === 'number') {
        const time = getTimePretty(images)

        const message = "We took pictures of you going XC at " + time + " we will upload your photos asap."

        return <Error title={message} />
    } else {
        return (
            <div>
                {images.map((image, index) => <XCImageView images={image} index={index} warning={warning[index]}/>)}
                {!showSearchByTime && <Error title={"If you think we have missed some then try searching by time, using the button below."} />}
                <br/>
                {!showSearchByTime && <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => setShowSearchByTime(true)}
                >
                    Search by time
                </Button>}
                
                {showSearchByTime && <SearchByTime eventid={eventid} eventData={eventData} loadingImages={loadingImages} disc={'XC'}/>}

                {showSearchByTime && <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => setShowSearchByTime(false)}
                >
                    Cancel
                </Button>}
            </div>
        )
    }
}