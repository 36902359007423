import React, {useState} from "react";
import {useDispatch, useSelector} from 'react-redux'
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {Title} from '../Common';
import {containerStyle} from "../../Styles";
import {TextField, Button, CircularProgress, Fade} from '@material-ui/core';
import {emailCheck} from '../../ActionReducers/Actions';
import * as EmailValidator from 'email-validator';

const useStyles = makeStyles(containerStyle);

export default function Signin() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const [email, setEmail] = useState('') 
    const [error, setError] = useState(false)
    const {authLoading} = useSelector(state => state.auth);

    function checkEmail() {
        const valid = EmailValidator.validate(email);
        if (!valid) {
            setError(true)
        } else {
            dispatch(emailCheck(email, history))
        }
    }

    return (
        <Fade in={true} out={true} timeout={{enter: 100}}>
        <div className={classes.answerDiv}>
          <div className={classes.raisedCard}>
          <Title title="Please enter your email to continue." />
            <TextField
              error={error}
              required
              type='email'
              id="email"
              label="Email"
              variant="outlined"
              className={classes.detailsInput}
              onChange={(a) => {setEmail(a.target.value); setError(false)}}
            />

            <br />
            <div>
            {!authLoading && <Button variant="contained" color="primary" onClick={() => checkEmail()}>Next</Button>}
            {authLoading &&  <CircularProgress />}
          </div>
          </div>
          
        </div>
        </Fade>
    );
}