import React from "react";
import {useSelector} from 'react-redux'
import { makeStyles } from "@material-ui/core/styles";
import {Title, SubTitle} from '../Common';
import {containerStyle, textStyle} from "../../Styles";
import {Fade} from '@material-ui/core';
import OrderItemDetail from '../Basket/OrderItemDetail';
import _ from 'lodash';

const style = {
    ...containerStyle,
    ...textStyle
  }
const useStyles = makeStyles(style);

export default function Basket() {
    const classes = useStyles();
    const {orderDetailToView} = useSelector(state => state.basket)
    const array = _.map(orderDetailToView.items, (val, uid) => {
        return { ...val, uid };
      });

    function timeConverter(UNIX_timestamp){
        var a = new Date(UNIX_timestamp);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds();
        var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
        return time;
      }
    return (
        <Fade in={true} out={true} timeout={{enter: 100}}>
        <div className={classes.answerDiv}>
          <div className={classes.raisedCard}>
          <Title title="Order detail" />
          <SubTitle title={timeConverter(orderDetailToView.timeOfOrder)} />
            { 
                array.map((item) => {
                    return <OrderItemDetail item={item}/>
                })
            }

            {
                <div>
                    {showPostage(array)}
                    
                </div>
            }
            <br/>
            {
                <div>
                    {showTotalExc(array, classes)}
                    
                </div>
            }
            {
                <div>
                    {showTotal(array)}
                    
                </div>
            }
          </div>
          
        </div>
        </Fade>
    );
}

function showPostage(basket) {
    const postage = []
    basket.forEach((doc) => {
        postage.push(doc.item.pAndP)
    })

    const sorted = _.sortBy(postage).reverse();
    const price = sorted[0].toFixed(2);
    const text = 'Postage: £' + price;
    return <div style={{display: 'flex', justifyContent: 'flex-end'}}><SubTitle title={text} /></div>
}

function showTotal(basket) {
    const postage = []
    const total = []
    basket.forEach((doc) => {
        postage.push(doc.item.pAndP)
        if (!doc.quantity) {
            total.push(doc.item.price)
          } else {
                const price = doc.item.price * doc.quantity
                total.push(price)
          }
    })

    const sorted = _.sortBy(postage).reverse();
    const postagePrice = sorted[0];
    const imagePrice = total.reduce((a, b) => a + b)
    const price = (postagePrice + imagePrice).toFixed(2)
    const text = 'Total: £' + price;
    return <div style={{display: 'flex', justifyContent: 'flex-end'}}><SubTitle title={text} /></div>
}

function showTotalExc(basket, classes) {
    const postage = []
    const total = []
    basket.forEach((doc) => {
        postage.push(doc.item.pAndP)
        if (!doc.quantity) {
            total.push(doc.item.price)
        } else {
            const price = doc.item.price * doc.quantity
            total.push(price)
        }
    })

    const sorted = _.sortBy(postage).reverse();
    const postagePrice = sorted[0];
    const imagePrice = total.reduce((a, b) => a + b)
    const price = ((postagePrice + imagePrice)/1.2).toFixed(2)
    const text = 'Total excl VAT: £' + price;
    return <div style={{display: 'flex', justifyContent: 'flex-end'}}><p class={classes.date} >{text}</p></div>
}