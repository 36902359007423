import { combineReducers } from 'redux';
import {EventReducer} from './EventReducer';
import {BasketReducer} from './BasketReducer';
import {AuthReducer} from './AuthReducer';
import {PhotoReducer} from './PhotoReducer';
import {AdminReducer} from './AdminReducer';
import { RiderReducer } from './RiderReducer';

export default combineReducers({
  events: EventReducer,
  basket: BasketReducer,
  auth: AuthReducer,
  photo: PhotoReducer,
  admin: AdminReducer,
  rider: RiderReducer,
});
