import { firebaseApp } from '../../firebase';

import {
    SET_USER,
    IS_NEW_USER,
    IS_NEW_USER_ERROR,
    SIGN_IN,
    SIGN_IN_ERROR,
    CREATE_ACCOUNT,
    CREATE_ACCOUNT_ERROR,
    AUTH_LOADING,
    SIGN_OUT,
    SIGN_OUT_ERROR,
    GET_USER,
    FORGOTTEN_PASSWORD,
} from './Types';

export const setUser = (uid) => {
    return dispatch => {
        dispatch({
            type: SET_USER,
            payload: uid,
          });
    }
}

export const emailCheck = (email, history) => {
    return async dispatch => {
        dispatch({
            type: AUTH_LOADING
        })
        let newUser

        try {
            const user = await firebaseApp.auth().fetchSignInMethodsForEmail(email)
            if (user.length === 0) {
                newUser = true
            } else {
                newUser = false
            }
            dispatch({
                type: IS_NEW_USER,
                email,
                newUser
            });
            history.push('/password')
        } catch (error) {
            dispatch({
                type: IS_NEW_USER_ERROR,
            });
        }
        
    }
}


export const signIn = (email, password, history) => {
    return async dispatch => {
        dispatch({
            type: AUTH_LOADING
        })
        try {
            const userCredential = await firebaseApp.auth().signInWithEmailAndPassword(email, password)
            const {user} = userCredential
            dispatch({
                type: SIGN_IN,
                payload: user.uid
            })
            history.push('/image-cropper');
        } catch (error) {
            dispatch({
                type: SIGN_IN_ERROR,
                payload: error.message
            })
        }
    }
}

export const createAccount = (email, password, history) => {
    return async dispatch => {
        dispatch({
            type: AUTH_LOADING
        })
        try {
            const userCredential = await firebaseApp.auth().createUserWithEmailAndPassword(email, password)
            const {user} = userCredential
            console.log('userCredential: ', userCredential)
            console.log('user: ', user)
            console.log('user.uid: ', user.uid)

            const userAccount = {
                address: {
                    AddressLine1: null
                },
                lastName: null,
                name: null,
                profile: {
                    email: email
                },
                userType: 'Athlete'
            }
            const createUser = await firebaseApp.firestore().collection('users').doc(user.uid).set(userAccount)
            dispatch({
                type: CREATE_ACCOUNT,
                payload: user.uid,
                createUser,
            })
            console.log('createUser: ', createUser)
            history.push('/image-cropper');
        } catch (error) {
            dispatch({
                type: CREATE_ACCOUNT_ERROR,
                payload: error.message
            })
        }
    }
}

export const signOut = () => {
    return async dispatch => {
        dispatch({
            type: AUTH_LOADING
        })
        try {
            const signout = await firebaseApp.auth().signOut()
            dispatch({
                type: SIGN_OUT,
                signout
            })
        } catch (e) {
            dispatch({
                type: SIGN_OUT_ERROR,
                payload: e.message
            })
        }
    }
}

export const getUser = (user) => {
    return dispatch => {
        firebaseApp.firestore()
        .collection('users')
        .doc(user)
        .onSnapshot((snapshot) => {
            if (snapshot.data()) {
                const data = snapshot.data()
                data.uid = snapshot.id
                dispatch({
                    type: GET_USER,
                    payload: data
                })
            }
            
        })
    }
}

export const forgottenPassword = email => {
    return dispatch => {
        firebaseApp.auth().sendPasswordResetEmail(email)
            .then(() => {
                dispatch({
                    type: FORGOTTEN_PASSWORD,
                    message: 'We have sent an email to ' + email
                })
            }).catch(function (e) {
                dispatch({
                    type: FORGOTTEN_PASSWORD,
                    message: 'Error sending link to ' + email
                })
            }) 
    }
}