import {colors} from './colors';
import {fontSizes, fontWeight} from './fontSizes';

export const textStyle = {
    main: {
        color: colors.primaryBlack,
        fontSize: fontSizes.small,
        fontWeight: fontWeight.lightMedium,
    },
    name: {
        color: '#6a6a6a',
        paddingRight: '10px',
        textAlign: 'left',
        fontSize: '15px',
        fontWeight: '400',
        marginTop: '10px',
        textTransform: 'uppercase',
        fontFamily: '"Trebuchet MS", sans-serif',
        userSelect: 'none',
    },      
    date: {
        color: '#18cca3',
        paddingRight: '10px',
        textAlign: 'left',
        fontSize: '10px',
        fontWeight: '400',
        marginTop: '-15px',
        fontFamily: '"Trebuchet MS", sans-serif',
        userSelect: 'none',
    },
    parallaxTitle: {
        color: '#fff',
        paddingRight: '10px',
        textAlign: 'left',
        fontSize: '48px',
        fontWeight: '400',
        marginTop: '-15px',
        fontFamily: '"Trebuchet MS", sans-serif',
        userSelect: 'none',
    },
    parallaxText: {
        color: '#fff',
        paddingRight: '10px',
        textAlign: 'left',
        fontSize: '16px',
        fontWeight: '400',
        marginTop: '-15px',
        fontFamily: '"Trebuchet MS", sans-serif',
        userSelect: 'none',
    },
    title: {
        color: '#000',
        paddingRight: '10px',
        textAlign: 'center',
        fontSize: '28px',
        fontWeight: '400',
        marginTop: '-15px',
        fontFamily: '"Trebuchet MS", sans-serif',
        userSelect: 'none',
    },
    error: {
        color: 'red',
        paddingRight: '15px',
        paddingLeft: '15px',
        textAlign: 'center',
        margin: 'auto',
        fontSize: '18px',
        fontWeight: '400',
        marginTop: '10px',
        fontFamily: '"Trebuchet MS", sans-serif',
        userSelect: 'none',
    },
    subTitle: {
        color: '#000',
        paddingRight: '10px',
        textAlign: 'center',
        fontSize: '18px',
        fontWeight: '400',
        fontFamily: '"Trebuchet MS", sans-serif',
        userSelect: 'none',
    },
    checkout: {
        color: '#000',
        paddingRight: '10px',
        textAlign: 'left',
        fontSize: '12px',
        fontWeight: '400',
        fontFamily: '"Trebuchet MS", sans-serif',
        userSelect: 'none',
        lineHeight: '12px',
        marginBottom: 5,
        marginTop: 5,
    },
    checkoutTitle: {
        color: '#000',
        paddingRight: '10px',
        textAlign: 'left',
        fontSize: '14px',
        fontWeight: '600',
        fontFamily: '"Trebuchet MS", sans-serif',
        userSelect: 'none',
        lineHeight: '12px',
        marginBottom: 5,
        marginTop: 5,
    },
    orderTotal: {
        color: '#18cca3',
        paddingRight: '10px',
        textAlign: 'left',
        fontSize: '18px',
        fontWeight: '600',
        fontFamily: '"Trebuchet MS", sans-serif',
        userSelect: 'none',
        lineHeight: '12px',
        marginBottom: 5,
        marginTop: 5, 
    },
    description: {
        color: '#000',
        paddingRight: '10px',
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: '300',
        fontFamily: '"Trebuchet MS", sans-serif',
        userSelect: 'none',
    },
    leftText: {
        color: '#000',
        paddingLeft: '10px',
        textAlign: 'left',
        fontSize: '14px',
        fontWeight: '400',
        fontFamily: '"Trebuchet MS", sans-serif',
        userSelect: 'none',
    },
    centerText: {
        color: '#000',
        paddingLeft: '10px',
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: '400',
        fontFamily: '"Trebuchet MS", sans-serif',
        userSelect: 'none',
    },
}