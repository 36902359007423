export const fontSizes = {
    extraSmall: 14,
    small: 18,
    medium: 25,
    large: 40,
}

export const fontWeight = {
    light: '300',
    lightMedium: '400',
    medium: '500',
    bold: '600'
}