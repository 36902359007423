import React, {useEffect} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import {useDispatch, useSelector} from 'react-redux'

import { makeStyles } from "@material-ui/core/styles";
import {ParallaxTitle, ParallaxText, Title, Footer} from '../Common';
import {containerStyle} from "../../Styles";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Parallax from '../Grid/Parallax';
import {eventsFetch} from '../../ActionReducers/Actions';
import Event from './Event';

// Sections for this page

const useStyles = makeStyles(containerStyle);

export default function Home() {
    const classes = useStyles();
    const dispatch = useDispatch()
    useEffect(() => {
      const date = (Date.now()/1000)
      dispatch(eventsFetch(date))
    }, [dispatch])

    const {eventList} = useSelector(state => state.events)
    const Background = 'https://firebasestorage.googleapis.com/v0/b/athalens-51eb0-event-profile/o/home3.jpg?alt=media&token=48b3db0e-6ce5-433e-9155-417f7cf897f9'
    const title = 'Athalens photography'
    const subTitle = 'Please choose from the list of photos below'
    const title1 = 'Recent events'
    console.log('hello')
    return (
      <div className={classes.answerDiv}>
        <Parallax filter image={Background} fixedImage={true}>
                <div className={classes.container}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <ParallaxTitle title={title} />
                    <ParallaxText title={subTitle} />                    
                </GridItem>
                </GridContainer>
                
                </div>
          </Parallax>
          <div className={classNames(classes.main, classes.mainRaised)}>
            <Title title={title1} />
            {/* <Error title={'Please make sure you choose the correct competition for Houghton as there are lots of different arenas. Photos for Houghton will be going up every evening.'} /> */}
            <GridContainer>
              {showEventList(eventList)}
            </GridContainer>
          </div>
          <Footer />
      </div>
    );
}

function showEventList(eventList) {
  if (eventList) {
    return eventList.map(event => <Event event={event} key={event.uid}/>)
  } else {
    return
  }
  
}