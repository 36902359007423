import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import MyCheckoutForm from './MyCheckoutForm';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe('pk_live_uCe2jgOtt833q2yJZtYrNDHq');

export default function Checkout1(props) {
    const {price, user} = props
    console.log('checking out here................')
    return (
        <Elements stripe={stripePromise}>
            <MyCheckoutForm price={price} user={user}/>
        </Elements>
    );
};