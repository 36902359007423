import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {Link} from "react-router-dom";

import {containerStyle} from "../../Styles";

const useStyles = makeStyles(containerStyle);
export function SlimButton(props) {
    const {title, to, onClick} = props;
    const classes = useStyles();
    return (
        <div>
            <Link
                to={to}
            >
                <button
                    className={classes.slimButton}
                    onClick={onClick}
                >{title}</button>
            </Link>
        </div>
        
    );
}