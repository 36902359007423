import React, {useState} from "react";
import {useDispatch} from 'react-redux'
import { makeStyles } from "@material-ui/core/styles";
import {Title} from '../../Common';
import {containerStyle, textStyle} from "../../../Styles";
import { Button, CircularProgress, Select, FormControl, InputLabel } from "@material-ui/core";
import {searchByTime} from '../../../ActionReducers/Actions';
const style = {
    ...containerStyle,
    ...textStyle
  }
const useStyles = makeStyles(style);

export default function SearchByTime(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {eventid, eventData, loadingImages, disc} = props;
    const [day, setDay] = useState(eventData.timestampStart * 1000)
    const [hour, setHour] = useState()
    const [min, setMin] = useState()

    const dayArray = []
    for (var i = 0; i <= eventData.durationDays - 1; i++) {
        const secs = (eventData.timestampStart + (i*86400)) * 1000
        const actualDate = new Date(secs)
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
        const daysNumber = actualDate.getDay()

        const dayOfTheWeek = days[daysNumber];
        const date = {
            secs,
            dayOfTheWeek
        }
        dayArray.push(date);
    }
    
    return (
        <div>
            <Title title='Search by time' />
        {/* ////////DAY///////// */}
        {eventData && eventData.durationDays > 1 && <FormControl
                className={classes.formControl}
                onChange={(e) => setDay(e.target.value)}
              >
                <InputLabel htmlFor="age-native-simple">Day</InputLabel>
                <Select
                  native
                  inputProps={{
                      name: 'Discipline',
                      id: 'age-native-simple',
                  }}
                > 
                    <option key={0} value=''></option>
                    {dayArray.map((opt, index) => {
                      return <option key={index} value={opt.secs}>{opt.dayOfTheWeek}</option>
                    })}
                </Select>
        </FormControl>}
        <br/>
        <br/>
        {eventData && <FormControl
                className={classes.formControl}
                onChange={(e) => setHour(e.target.value)}
              >
                <InputLabel htmlFor="age-native-simple">Hour</InputLabel>
                <Select
                  native
                  inputProps={{
                      name: 'Discipline',
                      id: 'age-native-simple',
                  }}
                > 
                      <option key={0} value=''></option>
                      <option key={8} value={7}>07:00</option>
                      <option key={9} value={8}>08:00</option>
                      <option key={10} value={9}>09:00</option>
                      <option key={11} value={10}>10:00</option>
                      <option key={12} value={11}>11:00</option>
                      <option key={13} value={12}>12:00</option>
                      <option key={14} value={13}>13:00</option>
                      <option key={15} value={14}>14:00</option>
                      <option key={16} value={15}>15:00</option>
                      <option key={17} value={16}>16:00</option>
                      <option key={18} value={17}>17:00</option>
                      <option key={19} value={18}>18:00</option>
                      <option key={20} value={19}>19:00</option>
                      <option key={21} value={20}>20:00</option>
                      <option key={22} value={21}>21:00</option>
                </Select>
        </FormControl>}
        <br/>
        <br/>
        {eventData && <FormControl
                className={classes.formControl}
                onChange={(e) => setMin(e.target.value)}
              >
                <InputLabel htmlFor="age-native-simple">Minute</InputLabel>
                <Select
                  native
                  inputProps={{
                      name: 'Discipline',
                      id: 'age-native-simple',
                  }}
                > 
                      <option key={0} value=''></option>
                      <option key={1} value={0}>0-30min</option>
                      <option key={2} value={30}>30-60min</option>

                </Select>
        </FormControl>}


            <div>
                <br />
        {!loadingImages && <Button
                color="primary"
                variant="contained"
                onClick={() => dispatch(searchByTime(eventid, day, hour, min, disc))}
                disabled={checkDisabled(hour, min)}
            >
                Search by time
            </Button>}
        {loadingImages && <CircularProgress />}
        <br/>
        <br/>
        </div>
          
        </div>
    );
}

function checkDisabled(hour, min) {
  if (!hour || !min) {
    return true
  } else {
    return false
  }
}