import React from "react";
import {useSelector} from 'react-redux'
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {Title, SubTitle} from '../Common';
import {containerStyle, textStyle} from "../../Styles";
import {Button, Fade} from '@material-ui/core';

const style = {
    ...containerStyle,
    ...textStyle
  }
const useStyles = makeStyles(style);

export default function PaymentSuccess() {
    const classes = useStyles();
    const history = useHistory();
    const {currentOrder} = useSelector(state => state.basket)

    function anyPrints() {
        const printsArray = []
        currentOrder.forEach((doc) => {
            if (doc.item.print) {
                printsArray.push(1)
            }
        })
        let prints
        if (printsArray.length >0) {
            prints = true
        } else {
            prints = false
        }
        return prints
    }

    return (
        <Fade in={true} out={true} timeout={{enter: 1000}}>
            <div className={classes.answerDiv}>
                <div className={classes.raisedCard}>
                {currentOrder && <Title title="Payment Successful" />}
                {currentOrder && <SubTitle title="Click on the photos to open them in a new tab where you can download them." />}
                {currentOrder && anyPrints() && <SubTitle title="Prints can take up to 5 days to arrive." />}
                {currentOrder && currentOrder.map((item) => {
                    return <div style={{display: 'flex', flexDirection: 'column'}}>
                            <img src={item.selectedImage.urlCropped || item.selectedImage.url} alt="horse" style={{ width: '300px', height: 'auto', margin: 'auto', marginTop: 5, marginBottom: 5}}/>
                            <div style={{marginBottom: 10}}>
                            <Button variant="contained" color="primary" href={item.selectedImage.urlCropped || item.selectedImage.url} target="blank">
                                Download image
                            </Button>
                            </div>
                        </div>
                })}
                {!currentOrder && <div><Button variant="contained" color="primary" onClick={() => history.push('/orders')}>View orders</Button></div>}
                {currentOrder && <div><Button variant="contained" color="primary" onClick={() => history.push('/')}>Continue Shopping</Button></div>}
                </div>
            </div>
        </Fade>
    );
}