import {
    FETCH_ORDERS,
    SET_ORDER_DETAIL,
    SET_OPEN,
    ADMIN_CROP,
    GET_LIVE_ORDER,
    SUBMIT_ORDER,
    SUBMITTING_ORDER,
    SUBMIT_ORDER_ERROR,
    CREATE_COMPETITION,
    FIND_NUMBERS,
    GET_PHOTOGRAPHERS_PHOTOS,
    GO_TO_DISCIPLINES,
    EVENTS_DISCIPLINE_FETCH,
    EVENTS_DISCIPLINE_SEND,
  } from '../Actions/Types';
  
  const INITIAL_STATE = {
      open: false,
      error: null,
      loading: false,
      cropped: false,
      numberSorting: [],
      photographerPhotos: [],
      event: null,
      eventDisciplineList: [],
  };
  
  export function AdminReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_ORDERS:
            return { ...state, orders: action.payload };
        case SET_ORDER_DETAIL:
            return { ...state, order: action.order, orderKey: action.orderKey}
        case SET_OPEN:
            return { ...state, photo: action.photo, open: action.open};
        case ADMIN_CROP:
            return { ...state, open: false}
        case GET_LIVE_ORDER:
            return { ...state, liveOrder: action.payload, prints: action.prints, cropped: action.cropped};
        case SUBMIT_ORDER:
            return {...state, loading: false, error: false}
        case SUBMITTING_ORDER:
            return {...state, loading: true, error: false}
        case SUBMIT_ORDER_ERROR:
            return {...state, loading: false, error: action.payload}
        case FIND_NUMBERS:
            return {...state, numberSorting: action.payload};
        case GET_PHOTOGRAPHERS_PHOTOS:
            return {...state, photographerPhotos: action.payload};
        case CREATE_COMPETITION:
            return state;
        case GO_TO_DISCIPLINES:
            return { ...state, event: action.payload}
        case EVENTS_DISCIPLINE_FETCH:
            return { ...state, eventDisciplineList: action.payload}
        case EVENTS_DISCIPLINE_SEND:
            return state;
      default:
        return state;
    }
  };
  