import React, {useEffect} from "react";
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import {SubTitle, Title, LeftText} from '../../Common';
import {containerStyle, textStyle} from "../../../Styles";
import {Fade, Button, ListItem, Divider} from '@material-ui/core';
import {goToNextOrder, goToPreviousOrder, setOpen} from '../../../ActionReducers/Actions';
import AdminCropModal from './AdminCropModal';
import {getLiveOrder, submitOrder} from '../../../ActionReducers/Actions';

const style = {
    ...containerStyle,
    ...textStyle
  }
const useStyles = makeStyles(style);

export default function AdminOrderDetail() {
    const classes = useStyles();
    const {order, orderKey, orders, prints, liveOrder, loading, error} = useSelector(state => state.admin)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getLiveOrder(order))
    }, [dispatch, order])

    console.log('userUID', order.userUid)

    return (
        <Fade in={true} out={true} timeout={{enter: 100}}>
        <div className={classes.answerDiv}>
          <div className={classes.raisedCard}>
          <Title title="Admin Order Detail" />
          <SubTitle title={order.uid} />
          <SubTitle title={orderKey + '/' + orders.length} />
          <LeftText title={order.user.name + ' ' + order.user.lastName} />
          <LeftText title={order.user.address.AddressLine1} />
          <LeftText title={order.user.address.AddressLine2} />
          <LeftText title={order.user.address.City} />
          <LeftText title={order.user.address.County} />
          <LeftText title={order.user.address.PostCode} />
          <LeftText title={order.user.address.PhoneNumber} />

          {prints && prints.map((photo) => {
              return (
                  <div>
                    <ListItem button key={4} onClick={() => dispatch(setOpen(true, photo))}>
                            <img src={photo.selectedImage.urlCropped || photo.selectedImage.url} alt='h' width='700' />
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                            {photo.selectedImage.urlCropped && <LeftText title='Cropped' />}
                            {!photo.selectedImage.urlCropped && <LeftText title='Not cropped' />}
                            <LeftText title={photo.item.name} />
                            </div>
                            
                    </ListItem>
                    <Divider />
                </div>
              )
          })}
          {liveOrder && !liveOrder.orderNumber && <Button variant="contained" color="primary" onClick={() => dispatch(submitOrder(liveOrder, prints))}>Submit order</Button>}
          <br/>
          {loading && <LeftText title='Submitting order' />}
          {liveOrder && liveOrder.orderNumber && <LeftText title={liveOrder.orderNumber} />}
          {error && <LeftText title={error} />}
          {orderKey < orders.length -1 && liveOrder && liveOrder.orderNumber && <Button variant="contained" color="primary" onClick={() => dispatch(goToNextOrder(orderKey, orders))}>Next</Button>}
          <br/>
          {orderKey > 0 && <Button onClick={() => dispatch(goToPreviousOrder(orderKey, orders))}>Back</Button>}


          </div>
          <AdminCropModal />
        </div>

        </Fade>
    );
}