import React, {useEffect} from "react";
import {useSelector} from 'react-redux'
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {Title} from '../Common';
import {containerStyle, textStyle} from "../../Styles";
import {Button, Fade, Divider} from '@material-ui/core';
import _ from 'lodash';

const style = {
    ...containerStyle,
    ...textStyle
  }
const useStyles = makeStyles(style);

export default function Checkout() {
    const classes = useStyles();
    const history = useHistory();
    const {userDoc} = useSelector(state => state.auth)
    const {basket} = useSelector(state => state.basket)
      useEffect(() => {
            if (!userDoc || !basket) {
                  history.push('/basket')
            }
      }, [history, userDoc, basket])
    return (
        <Fade in={true} out={true} timeout={{enter: 100}}>
        <div className={classes.answerDiv}>
          <div className={classes.raisedCard}>
          <Title title="Place your order" />
          <Button variant="contained" color="primary" onClick={() => history.push('/add-card')}>Buy now</Button>
          <br/>
            {basket && <div className={classes.raisedCheckout}>
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <p className={classes.checkout}>Items: </p>
                        {showItemTotal(basket, classes)}
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <p className={classes.checkout}>Postage:</p>
                        {showPostage(basket, classes)}
                  </div>
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <p className={classes.checkoutTitle}>Order total:</p>
                        {showTotal(basket, classes)}
                  </div>
                  <br/>
                  <Divider />
                  <br/>
                  <p className={classes.checkout}>Order totals include VAT.</p>
            </div>}    
          {userDoc && userDoc.address && <div className={classes.raisedCheckout}>
                <p className={classes.checkoutTitle}>Deliver to: {userDoc.name} {userDoc.lastName}</p>
                <p className={classes.checkout}>{userDoc.address.AddressLine1}</p>
                {userDoc.address.AddressLine2 && <p className={classes.checkout}>{userDoc.address.AddressLine2}</p>}
                <p className={classes.checkout}>{userDoc.address.City}</p>
                <p className={classes.checkout}>{userDoc.address.County}</p>
                <p className={classes.checkout}>{userDoc.address.PostCode}</p>
                <br/>
                <Button variant="outlined" color="primary" onClick={() => history.push('/address')} >Edit Address</Button>
          </div>}

           
          </div>
          
        </div>
        </Fade>
    );
}

function showPostage(basket, classes) {
      const postage = []
      basket.forEach((doc) => {
          postage.push(doc.item.pAndP)
      })
  
      const sorted = _.sortBy(postage).reverse();
      const price = sorted[0].toFixed(2);
      const text = '£' + price;
      return <p className={classes.checkoutTitle}>{text}</p>
  }

  function showItemTotal(basket, classes) {
      const total = []
      basket.forEach((doc) => {
          if (!doc.quantity) {
            total.push(doc.item.price)
          } else {
                const price = doc.item.price * doc.quantity
                total.push(price)
          }
          
      })
  
      const imagePrice = total.reduce((a, b) => a + b)
      const price = (imagePrice).toFixed(2)
      const text = '£' + price;
      return<p className={classes.checkoutTitle}>{text}</p>
  }
  
  function showTotal(basket, classes) {
      const postage = []
      const total = []
      basket.forEach((doc) => {
          postage.push(doc.item.pAndP)
          if (!doc.quantity) {
            total.push(doc.item.price)
          } else {
                const price = doc.item.price * doc.quantity
                total.push(price)
          }
      })
  
      const sorted = _.sortBy(postage).reverse();
      const postagePrice = sorted[0];
      const imagePrice = total.reduce((a, b) => a + b)
      const price = (postagePrice + imagePrice).toFixed(2)
      const text = '£' + price;
      return<p className={classes.orderTotal}>{text}</p>
  }