import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

import {containerStyle} from "../../Styles";
const useStyles = makeStyles(containerStyle);
export function BackButton(props) {
    const {onClick, title} = props;
    const classes = useStyles();

    let text;
    if (title) {
        text = title
    } else {
        text = 'Back'
    }
    return (
        <button
            className={classes.backButton}
            onClick={onClick}
        >{text}</button>
    );
}