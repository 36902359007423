import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux'
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {Title, SubTitle} from '../Common';
import {containerStyle} from "../../Styles";
import {Button, Fade} from '@material-ui/core';
import {addToBasket} from '../../ActionReducers/Actions';
import "cropperjs/dist/cropper.css";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import BasketConfirmation from '../Basket/BasketConfirmation';
import Watermark from '../../Images/watermark.png'

const useStyles = makeStyles(containerStyle);

export default function ImageCropper() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {user} = useSelector(state => state.auth)
    const {product} = useSelector(state => state.basket)
    const {imageInModal, eventData} = useSelector(state => state.events)
    const history = useHistory();

    const [quantity, setQuantity] = useState(1)


    useEffect(() => {
        if (!product || !imageInModal || !eventData) {
            console.log('there is nothing', product,imageInModal,eventData)
            history.push('/')
        } else {
            console.log('there is something', product,imageInModal,eventData)
        }
    }, [product, imageInModal, eventData, history])

    
    function handleChange(event) {
        setQuantity(event.target.value);
    };


    return (
        <Fade in={true} out={true} timeout={{enter: 100}}>
        <div className={classes.answerDiv}>
          <Title title="Image" />
          {product && <SubTitle title={product.label} />}
            
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                {imageInModal && <Fade in={true} timeout={{enter: 100}}>
                    <div style={{position: 'relative'}}>
                        <img 
                            style={{ width: "300px", height: 'auto', marginBottom: '20px' }}
                            src={imageInModal.url}
                            alt="cropped"
                        />
                        <img
                            src={Watermark}
                            className={classes.watermarkCropped}
                            alt={'users'}
                        />
                    </div>
                    
                    
                </Fade>}
                {product && product.index !== 3 && <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">Quantity</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={quantity}
                            onChange={handleChange}
                            label="Age"
                            >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                            <MenuItem value={8}>8</MenuItem>
                            <MenuItem value={9}>9</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                        </Select>
                    </FormControl>}
                <Button style={{marginTop: 15}} variant="contained" color="primary" onClick={() => dispatch(addToBasket(imageInModal, product, eventData, user, history, quantity))}>
                Add to basket
                </Button>
                <BasketConfirmation />
            </div>
            
          </div>
          
        </Fade>
    );
}