import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";

import {textStyle} from '../../Styles'

// Sections for this page
const useStyles = makeStyles(textStyle);

export default function PrivacySection() {
  const classes = useStyles();
  return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
        <br/>
          <h1 className={classes.title}>
          Introduction
          </h1>
          <h5 className={classes.description}>
          At Athalens, we understand the importance of privacy and the protection of your personal information. This Privacy Policy explains how we collect, use, and disclose your personal information in connection with the Athalens mobile app (the “App”).
          </h5>
          <h5 className={classes.description}>
          By using the App, you consent to our collection, use, and disclosure of your personal information in accordance with this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not use the App.
          </h5>

          <h1 className={classes.title}>
          Collection of Personal Information
          </h1>
          <h5 className={classes.description}>
          We collect personal information when you register for an account, use the App, or contact us for support. The personal information we collect may include your name, email address, phone number, and other information you choose to provide us.
          </h5>
          <h5 className={classes.description}>
          We may also collect certain technical information automatically when you use the App, such as your device type, operating system, IP address, and other similar information.
          </h5>

          <h1 className={classes.title}>
          Use of Personal Information
          </h1>
          <h5 className={classes.description}>
          We use your personal information to provide you with the App and its features, to communicate with you, to respond to your inquiries and requests, and to personalize your experience with the App.
          </h5>
          <h5 className={classes.description}>
          We may also use your personal information to improve the App, to conduct research and analysis, and to enforce our terms of use and other policies.
          </h5>

          <h1 className={classes.title}>
          Disclosure of Personal Information
          </h1>
          <h5 className={classes.description}>
          We may disclose your personal information to third-party service providers who help us operate the App, such as hosting providers, analytics providers, and customer support providers.
          </h5>
          <h5 className={classes.description}>
          We may also disclose your personal information if required by law, or in connection with a merger, acquisition, or sale of all or a portion of our business.
          </h5>

          <h1 className={classes.title}>
          Protection of Personal Information
          </h1>
          <h5 className={classes.description}>
          We take reasonable measures to protect your personal information from unauthorized access, disclosure, or use. However, please note that no security system is foolproof, and we cannot guarantee the security of your personal information.
          </h5>
          <h5 className={classes.description}>
          Your Choices
          </h5>

          <h1 className={classes.title}>
          You may choose to opt out of certain communications from us, such as marketing emails. You may also have the ability to control certain information collected automatically by the App through your device settings.
          </h1>
          <h5 className={classes.description}>
          The App is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under the age of 13.
          </h5>

          <h1 className={classes.title}>
          Children’s Privacy
          </h1>
          <h5 className={classes.description}>
          The App is not intended for use by children under the age of 13. We do not knowingly collect personal information from children under the age of 13.
          </h5>

          <h1 className={classes.title}>
          Changes to this Privacy Policy
          </h1>
          <h5 className={classes.description}>
          We may update this Privacy Policy from time to time. If we make material changes to this Privacy Policy, we will provide notice to you in accordance with applicable law.
          </h5>

          <h1 className={classes.title}>
          Contact Us
          </h1>
          <h5 className={classes.description}>
          If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at info@athalens.com.
          </h5>
          <h1 className={classes.title}>
          Effective Date
          </h1>
          <h5 className={classes.description}>
          This Privacy Policy is effective as of 4/10/2023.
          </h5>
        </GridItem>
      </GridContainer>
  );
}