import {
    SET_RIDER,
    GET_RIDERS_ENTRIES,
    SET_RIDER_EVENT,
  } from '../Actions/Types';
  
  const INITIAL_STATE = {
    rider: null,
    riderEntries: [],
    totalEntries: [],
    riderEvent: [],
  };
  
  export function RiderReducer (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_RIDER:
            return { ...state, rider: action.payload};
        case GET_RIDERS_ENTRIES:
            return {...state, riderEntries: action.payload, totalEntries: action.payload1};
        case SET_RIDER_EVENT:
            return {...state, riderEvent: action.payload};
      default:
        return state;
    }
  };