import React from "react";
import {useDispatch, useSelector} from 'react-redux'
import { makeStyles } from "@material-ui/core/styles";
import {Title, SubTitle} from '../Common';
import {containerStyle} from "../../Styles";
import {Button, CircularProgress, Fade} from '@material-ui/core';
import {signOut} from '../../ActionReducers/Actions';

const useStyles = makeStyles(containerStyle);

export default function YourAccount() {
    const classes = useStyles();
    const {user, userDoc, authLoading} = useSelector(state => state.auth)
    const dispatch = useDispatch();

    return (
        <Fade in={true} out={true} timeout={{enter: 100}}>
        <div className={classes.answerDiv}>
          <div className={classes.raisedCard}>
          {user && <Title title="Your account" />}
          {!user && <Title title="You're signed out" />}
          {user && userDoc && <SubTitle title={userDoc.profile.email || ' '} />}
          {!user && !authLoading && <a href="/signin" style={{textDecoration: 'none'}}><Button variant="contained" color="primary" >Sign in</Button></a>}
          {user && !authLoading && <Button variant="contained" color="primary" onClick={() => dispatch(signOut())}>Sign out</Button>}
          {authLoading && <CircularProgress/>}
          </div>
          </div>
          
        </Fade>
    );
}