import React from 'react';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import _ from 'lodash';

export default function OrderItem(props) {
    const { item, onClick} = props;

    function timeConverter(UNIX_timestamp){
        var a = new Date(UNIX_timestamp);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds();
        var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
        return time;
      }
    
    const array = _.map(item.items, (val, uid) => {
        return { ...val, uid };
      });

    return (
      <div onClick={onClick}>
        <ListItem>
            <div style={{display:"flex", width: '300px', flexDirection:"column"}}>
                <div style={{display:"flex", flexDirection:"column", alignItems:"flex-start"}}>
                <ListItemText primary={timeConverter(item.timeOfOrder)}/>
                <div>
                {array.map((item) => {
                    return <img src={item.selectedImage.url64 || item.selectedImage.url} alt='horse' style={{height: 50, width: 50, margin: 5}}/>
                })}
                </div>
                </div>
            </div>
            
            
        </ListItem>
        <Divider />
      </div>
    )
}