import React from 'react';
// import {useDispatch} from 'react-redux';
// import {onShowModal, onDeleteSelect, sharePost} from '../../actions';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

export default function Event(props) {
    const { product, onClick } = props;
    const price = '£' + product.price + ' - ' + product.name
    return (
      <div>
        <ListItem style={{backgroundColor: '#fff'}} button onClick={onClick}>
            <ListItemText primary={price}/>
        </ListItem>
        <Divider />
      </div>
    )
}








        