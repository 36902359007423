import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
// import { useSpring, animated } from 'react-spring'; // web.cjs is required for IE 11 support
import {setOpen, cropImage} from '../../../ActionReducers/Actions';
import {Button} from '@material-ui/core';
import {SubTitle} from '../../Common';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
      display: 'flex',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: 5,
    padding: theme.spacing(2, 4, 3),
    flexDirection: 'column',
    width: '1000px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));


export default function AdminCropModal() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [cropper, setCropper] = useState();
  const [landscape, setOrientaion] = useState(true)

  const {open, photo, liveOrder} = useSelector(state => state.admin) 

  const handleClose = () => {
    dispatch(setOpen(false));
  };

  let width1;
    let height1;
    if (photo) {
        const {width, height} = photo.item
        width1 = width
        height1 = height
    } else {
        width1 = 0
        height1 = 0
    }

  function getCropData() {
        //   const cropData = cropper.getCroppedCanvas().toDataURL('image/jpeg');
          
          const cropOutline = cropper.getData();
          const imageData = cropper.getImageData();

          if (!landscape) {
            console.log('portrait')
            const {naturalHeight, naturalWidth} = imageData
            imageData.naturalHeight = naturalWidth
            imageData.naturalWidth = naturalHeight
          } else {
            console.log('landscape')
          }

          console.log('cropOutline: ', cropOutline)
          console.log('imageData: ', imageData)
          console.log(liveOrder)
          dispatch(cropImage(cropOutline, imageData, photo, liveOrder));
    }

    function setLandscape() {
        const ratio = width1 / height1
        cropper.setAspectRatio(ratio)
        setOrientaion(true)
    }

    function setPortrait() {
        const ratio = height1 / width1
        cropper.setAspectRatio(ratio)
        setOrientaion(false)
    }

  return (
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
          <div className={classes.paper}>
              <SubTitle title="Image Cropper" />
              {photo && <Cropper
                style={{ height: 600, maxWidth: "100%" }}
                aspectRatio={width1/height1}
                preview=".img-preview"
                src={photo.selectedImage.url}
                viewMode={2}
                guides={true}
                minCropBoxHeight={100}
                minCropBoxWidth={100}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={true}
                onInitialized={(instance) => {
                    setCropper(instance);
                    
                }}
            />}
                <br/>
                {landscape && photo && <Button style={{marginTop: 15}} variant="outlined" color="primary" onClick={() => setPortrait()}>
                    Set portrait
                </Button>}
                {!landscape && photo && <Button style={{marginTop: 15}} variant="outlined" color="primary" onClick={() => setLandscape()}>
                    Set landscape
                </Button>}
                <Button variant="contained" color="primary" onClick={() => getCropData()}>Crop</Button>
          </div>
      </Modal>
  );
}