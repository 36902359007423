import React, {useState} from "react";
import {useDispatch, useSelector} from 'react-redux'
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {Title} from '../Common';
import {containerStyle} from "../../Styles";
import {TextField, Button, Fade} from '@material-ui/core';
import {updateName} from '../../ActionReducers/Actions';

const useStyles = makeStyles(containerStyle);

export default function UpdateName() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const [name, setName] = useState()
    const [lastName, setLastName] = useState() 
    const [error, setError] = useState(false)
    const {userDoc} = useSelector(state => state.auth);

    function checkName() {
        if (!name) {
            setError(1)
        } else if (!lastName) {
            setError(2)
        } else {
            dispatch(updateName(userDoc, name, lastName, history))
        }
    }
    
    return (
        <Fade in={true} out={true} timeout={{enter: 100}}>
        <div className={classes.answerDiv}>
          <div className={classes.raisedCard}>
          <Title title="What's your name?" />
            <TextField
              error={error === 1}
              required
              id="first name"
              label="First name"
              variant="outlined"
              className={classes.detailsInput}
              onChange={(a) => {setName(a.target.value); setError(false)}}
            />

            <br />
            <TextField
              error={error === 2}
              required
              id="lasName"
              label="Last name"
              variant="outlined"
              className={classes.detailsInput}
              onChange={(a) => {setLastName(a.target.value); setError(false)}}
            />

            <br />
            <div>
            <Button variant="contained" color="primary" onClick={() => checkName()}>Confirm</Button>
          </div>
          </div>
          
        </div>
        </Fade>
    );
}