import firebase from 'firebase/app';
import "firebase/auth"
import "firebase/firestore"
import 'firebase/storage';

const config = {
  apiKey: "AIzaSyBEXcYwF9e7IPaItVCwBWvnemkQtOCxwTk",
  authDomain: "athalens-51eb0.firebaseapp.com",
  databaseURL: "https://athalens-51eb0.firebaseio.com",
  projectId: "athalens-51eb0",
  storageBucket: "athalens-51eb0.appspot.com",
  messagingSenderId: "690302351859"
};

export const firebaseApp = firebase.initializeApp(config);