import { firebaseApp } from '../../firebase';
import _ from 'lodash';

import {
    FETCH_ORDERS,
    SET_ORDER_DETAIL,
    SET_OPEN,
    ADMIN_CROP,
    GET_LIVE_ORDER,
    SUBMIT_ORDER,
    SUBMITTING_ORDER,
    SUBMIT_ORDER_ERROR,
    CREATE_COMPETITION,
    FIND_NUMBERS,
    GET_PHOTOGRAPHERS_PHOTOS,
    UNSORTED_SPONSOR_FINISH,
    GO_TO_DISCIPLINES,
    EVENTS_DISCIPLINE_FETCH,
    EVENTS_DISCIPLINE_SEND,
} from './Types';

export const goToDisciplines = (event, history) => {
    return dispatch => {
        dispatch({
            type: GO_TO_DISCIPLINES,
            payload: event
        })
        history.push('/admin/disciplines/add')
    }
}

export function fetchSectionList(event) {
    return async dispatch => {
      const eventList = await firebaseApp.firestore()
      .collection('eventSections')
      .where('event', "==", event.uid)
      .get()
  
      const EventList = []
  
      eventList.docs.forEach((doc) => {
          const resultObj = doc.data();
          EventList.push(resultObj);
      })

      dispatch({
        type: EVENTS_DISCIPLINE_FETCH,
        payload: EventList
      })
    }
  }

export function setDisciplines(disc, event) {
    return async dispatch => {
        const prom = [];
        for (const section in disc) {

            
            if (!disc[section]["Trot up"]) {
                disc[section]["Trot up"] = false
            }

            if (!disc[section]["Dressage"]) {
                disc[section]["Dressage"] = false
            }

            if (!disc[section]["Showjumping"]) {
                disc[section]["Showjumping"] = false
            }

            if (!disc[section]["XC"]) {
                disc[section]["XC"] = false
            }

            if (!disc[section]["Prize giving"]) {
                disc[section]["Prize giving"] = false
            }

            console.log('id: ', section, disc[section])
            const uid = event.uid + section
            const p = await firebaseApp.firestore().collection('eventSections').doc(uid).update(disc[section])
            prom.push(p)
        }

        const pr = Promise.all(prom)

        dispatch({
            type: EVENTS_DISCIPLINE_SEND,
            payload: pr
        })
    }
}

export const adminOrdersFetch = () => {
    return (dispatch) => {  
      firebaseApp.firestore()
      .collection('customerOrders')
      .where('timeOfOrder', '>', 1711980217000)
      .get()
      .then((querySnapshot) => {
        const adminOrderList = []
        querySnapshot.docs.forEach((doc) => {
          const orderObj = doc.data();
          orderObj.uid = doc.id;
          adminOrderList.push(orderObj);
        })

        console.log('adminOrderList: ', adminOrderList)

        adminOrderList.sort((a, b) => {
            return a.timeOfOrder - b.timeOfOrder;
          });
        

        console.log('adminOrderList2: ', adminOrderList)

        const prints = []
        
        adminOrderList.forEach(order => {
            if (checkIfPrint(order)) {
                prints.push(order)
            }
        })
        console.log('prints: ', prints)
        const checkOrderArray = []

        prints.forEach((doc) => {
            if (!doc.orderNumber) {
                checkOrderArray.push(doc)
            }
        })
        console.log('checkOrderArray: ', checkOrderArray)

        dispatch({
            type: FETCH_ORDERS,
            payload: checkOrderArray
        })
      });
    }
  }

function checkIfPrint(order) {
    let bol = false;
    const items = _.map(order.items, (val, uid) => {
        return { ...val, uid };
      });
    for (var i = 0; i < items.length; i++) {
        if (items[i].item.print === true) {
            bol = true;
            break;
        }
    }
    return bol;
  }


export const goToOrderDetail = (order, orderKey, history) => {
    return dispatch => {
        console.log(order, orderKey)
        dispatch({
            type: SET_ORDER_DETAIL,
            order,
            orderKey
        })

        history.push('/admin/orders/order-detail')
    }
}

export const goToNextOrder = (orderKey, orders) => {
    return dispatch => {
        const order = orders[orderKey + 1]
        dispatch({
            type: SET_ORDER_DETAIL,
            order,
            orderKey: orderKey+1
        })
    }
}

export const goToPreviousOrder = (orderKey, orders) => {
    return dispatch => {
        const order = orders[orderKey - 1]
        dispatch({
            type: SET_ORDER_DETAIL,
            order,
            orderKey: orderKey-1
        })
    }
}

export const setOpen = (open, photo) => {
    return (dispatch) => {
      dispatch({
        type: SET_OPEN,
        photo,
        open
      });
    } 
  };

export const cropImage = (cropOutline, imageData, photo, order) => {
    return async dispatch => {
        console.log(cropOutline)
        const crop = await firebaseApp.firestore().collection('adminCropped')
        .doc()
        .set({
            cropOutline,
            imageData,
            order: order.uid,
            photo: photo.uid,
            url: photo.selectedImage.url
        })

        dispatch({
            type: ADMIN_CROP,
            crop,
          })
    }
}

export const getLiveOrder = (order) => {
    return dispatch => {
        firebaseApp.firestore()
        .collection('customerOrders')
        .doc(order.uid)
        .onSnapshot((snapshot) => {
            const data = snapshot.data()
            console.log('orderNumber',data)
            data.uid = snapshot.id;
            console.log(data)

            console.log(data.items)
            const orderArray = _.map(data.items, (val, uid) => {
                return { ...val, uid };
              });
              console.log(data.orderArray)
            const prints = _.filter(orderArray,(itm) => {return itm.item.print === true})
            const orderedPrints = _.sortBy(prints, ['dateTimeOriginal'])

            let croppedArray = [];

            orderedPrints.forEach((doc) => {
                if (doc.selectedImage.urlCropped) {
                    croppedArray.push(doc)
                }
            })

            dispatch({
                type: GET_LIVE_ORDER,
                payload: data,
                prints: orderedPrints,
            })
        })
    }
}

export const submitOrder = (liveOrder, prints) => {
    return dispatch => {
        console.log('here33333')
        dispatch({ type: SUBMITTING_ORDER})

        const array = [];

        const items = _.map(prints, (val, uid) => {
            return { ...val, uid };
          });
        
        items.forEach((item) => {
            if (!item.selectedImage.urlCropped) {
                array.push(item)
            }
        })

        if (array.length > 0) {
            dispatch({
                type: SUBMIT_ORDER_ERROR,
                payload: 'Not all images are cropped'
            })
        } else {
            firebaseApp.firestore()
            .collection('submittedOrders')
            .doc(liveOrder.uid)
            .set(liveOrder)
            .then(() => {
                console.log('here1111')
                dispatch({ type: SUBMIT_ORDER })
            })
            .catch((e) => {
                console.log(e)
                console.log('here22222')
                dispatch({
                    type: SUBMIT_ORDER_ERROR,
                    payload: e.message,
                })
            })
        }
        
    }
}

export const createCompetition = (compName, time, duration, disc, esID, useES, url, thumb, monthNumber, day, year, arenas, useTime) => {
    return dispatch => {

        const arenasNumber = Number(arenas)

        let dateString;

        // do minus 1 so that it ends beffore midnight on the last day
        const timestampFinish = Number(time) + ((duration * 86400)-1);

        // get timestamp for end of competition
        const monthMil = timestampFinish * 1000
        const monthFin = new Date(monthMil)

        const monthFinish = monthFin.getMonth()
        const dayFinish = monthFin.getDate()

        const month1 = monthFinish + 1

        const months = ['n/a', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
        const month = months[monthNumber]
        const monthEnd = months[month1]

        if (duration > 1) {
            const dayEnd = Number(day) + Number(duration) - 1;
            if (month1 > monthNumber) {
                //if the competition finishes in the next month then it'll show the correct date
                dateString = day + '-' + month + ' ' + dayFinish + '-' + monthEnd + ' ' + year
                console.log('dateString: ', dateString)
            } else {
                //if the competition finishes in the same month
                dateString = day + '-' + dayEnd + ' ' + month + ' ' + year
                console.log('dateString: ', dateString)
            }
        } else {
            //If the competition is only 1 day
            dateString = day + ' ' + month + ' ' + year
        }

        const organiser = 'MLq36j6fnLWWLMphVxACskwW3Fl1';
        const profile = {
            'profilePicture': {
                'url': url,
                'url1080': 'https://firebasestorage.googleapis.com/v0/b/athalens-51eb0-test/o/bede.png?alt=media&token=88aaa115-d524-482e-ad47-81ef55d1f24c',
                'url64': thumb
            }
          }
        const sport = 'equestrian';
        
        const timestampStart = Number(time);
        const type = 'competitions';
        const myArr = disc.split(" ");
        const obj = {
            'date': dateString,
            'dateFormatted': dateString,
            'durationDays': duration,
            'message': '',
            'name': compName,
            organiser,
            profile,
            sport,
            timestampFinish,
            timestampStart,
            type,
            discipline: myArr,
            esID,
            useES,
            useESFull: useES,
            arenas: arenasNumber,
            useTime,
        }
        console.log('obj: ', obj)
        firebaseApp.firestore()
            .collection('competitions')
            .doc()
            .set(obj)
            .then((doc) => {
                dispatch({ type: CREATE_COMPETITION })
                console.log('done', obj)
                console.log('doc', doc)
            })
            .catch((e) => {
                console.log('error', e)
            })
    }
}

export const testCall = () => {
    return async dispatch => {
        // const h = await firebaseApp.firestore().collection('numberSorter').where('photographer', '==', '1').where('time', '<', 123).orderBy('time', 'desc').limit(1).get()
        const h = await firebaseApp.firestore().collection('competitions').doc('jEEaPljEJOe8mmWwhEi4').collection('images').where('dateTimeOriginal', '>', 0).where('dateTimeOriginal', '<', 20).where('discipline', '==', 'Showjumping').where('photographerUid', '==', 'kjdsfjgs').get()
        
        if (h.empty) {
            console.log('empty')
        } else {
            console.log('full')
        }
        
        dispatch({ type: CREATE_COMPETITION, payload: h })

    }
}

export const findNumbers = (photographerID, eventID) => {
    return async dispatch => {
        const p = await firebaseApp.firestore().collection('numberSorter').where('event', '==', eventID).where('photographer', '==', photographerID).get();

        const array = [];
        p.forEach((doc) => {
            const data = doc.data()
            data.uid = doc.id;
            array.push(data)
        })

        console.log(array)

        const arraySorted = _.sortBy(array, 'time');

        dispatch({
            type: FIND_NUMBERS,
            payload: arraySorted
        })
    }
}


export const getPhotographersPhotos = (uid) => {
    return async dispatch => {
        const p = await firebaseApp.firestore().collectionGroup('images').where('photographerUid', '==', uid).limit(50).get()

        const array = [];
        p.forEach((doc) => {
            const data = doc.data()
            data.uid = doc.id;
            array.push(data)
        })

        console.log(array)

        const arraySorted = _.sortBy(array, 'dateTimeOriginal');

        dispatch({
            type: GET_PHOTOGRAPHERS_PHOTOS,
            payload: arraySorted
        })
    }
}

export const addSponsor = (eventID, image) => {

    return async (dispatch) => {

        const promises = [];
        const nameArray = image[0].name.split('.')
        
        const storageRef = firebaseApp.storage('athalens-51eb0-sponsors').ref(`${eventID}/${image[0].name}`)

        const metadata = {
            contentType: `image/${nameArray[1]}`
        };
        
        const put = await storageRef.put(image[0], metadata)
        
        promises.push(put)
        
        const end = dispatch({
            type: UNSORTED_SPONSOR_FINISH,
        });

        promises.push(end)

        return Promise.all(promises)
    }
}

//YpzDDwz1FcqbqWVANgXy
//2BIcL5himJgyLCu1RUtEEkVkxi43