import React from 'react';
import GridContainer from "../../Grid/GridContainer.js";
import ImageList from './ImageList';
import {Error, SubTitle} from '../../Common';
import {useDispatch} from 'react-redux'
import {setImageModal} from '../../../ActionReducers/Actions';

export default function XCImageView(props) {
    
    const {images, index, warning} = props
    const cameraNumber = index + 1
    const title = 'Camera ' + cameraNumber
    
    let warningTitle;
    if (warning === 'correct') {
        warningTitle = ''
    } else if (warning === 'incorrect' || warning === 'automated') {
        warningTitle = 'There could be other riders in this folder.'
    } else {
        warningTitle = 'There could be some other riders in this folder.'
    }

    const dispatch = useDispatch()
    return (
        <div>
            <SubTitle title={title} />
            <Error title={warningTitle} />
            <br/>
            <GridContainer>                
                {showImageList(images, dispatch)}
            </GridContainer>
        </div>
        
    )
}

function showImageList(images, dispatch) {
    if (images) {
        if (images.length > 0) {

            return images.map((image, index) => <ImageList image={image} key={image.uid} setImage={() => dispatch(setImageModal(image, true))}/>)
        } else {
            const noPhotosYet = "We have photos of you on this camera, we will upload them asap."
            return <Error title={noPhotosYet} />
        }
    } else {
      return
    }
    
  }