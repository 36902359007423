import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { firebaseApp } from './firebase';
import { connect } from 'react-redux';
import {setUser, getUser} from './ActionReducers/Actions';
// import ReactPixel from 'react-facebook-pixel';
// import ReactGA from 'react-ga';
// import LinkedInTag from 'react-linkedin-insight';

import Home from './Components/Screens/Home';
import EventView from './Components/Screens/EventView';
import Signin from './Components/Screens/Signin';
import Password from './Components/Screens/Password';
import ImageCropper from './Components/Screens/ImageCropper';
import YourAccount from './Components/Screens/YourAccount';
import Basket from './Components/Screens/Basket';
import Address from './Components/Screens/Address';
import Checkout from './Components/Screens/Checkout';
import UpdateEmail from './Components/Screens/UpdateEmail';
import UpdateName from './Components/Screens/UpdateName';
import AddCard from './Components/Screens/AddCard';
import PaymentSuccess from './Components/Screens/PaymentSuccess';
import Orders from './Components/Screens/Orders';
import OrderDetail from './Components/Screens/OrderDetail';
import PhotographersEventList from './Components/Screens/PhotographersEventList';
import PhotoUpload from './Components/Screens/PhotoUpload';

import Privacy from './Components/Terms/Privacy';
import Terms from './Components/Terms/TermsAndConditions';
import Cookies from './Components/Terms/Cookies';

import AdminHome from './Components/Screens/Admin/AdminHome';
import AdminOrders from './Components/Screens/Admin/AdminOrders';
import AdminOrderDetail from './Components/Screens/Admin/AdminOrderDetail';

import CreateComp from './Components/Screens/Admin/CreateComp';
import AdminNumberSorter from './Components/Screens/Admin/AdminNumberSorter';
import ViewPhotographersPhotos from './Components/Screens/Admin/ViewPhotographersPhotos';
import AdminAddSponsor from './Components/Screens/Admin/AdminAddSponsor';

import {Header} from './Components/Common';

/// Second Version
import Home2 from './Components/SecondVersion/Home2';
import RiderSearch from './Components/SecondVersion/RiderSearch';
import RiderProfile from './Components/SecondVersion/RiderProfile';
import RiderEventView from './Components/SecondVersion/RiderEventView';
import AdminDisciplines from './Components/Screens/Admin/AdminDisciplines';
import AdminDisciplinesAdd from './Components/Screens/Admin/AdminDisciplinesAdd';

class RouterComponent extends Component {
  componentDidMount() {
    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        const uid = user.uid;
        this.props.setUser(uid)
        this.props.getUser(uid)
      } else {
        this.props.setUser(null)
      }
    });
  }

  render() {
    const { history } = this.props;
    return (
      <div>
        <Header />
        <Router history={history}>
          <Switch>
            <Route exact path='/' component={Home}/>
            <Route exact path='/event-view/:eventid' component={EventView}/>
            <Route exact path='/event-view/:eventid/:urlnumber' component={EventView}/>
            <Route exact path='/signin' component={Signin}/>
            <Route exact path='/password' component={Password}/>
            <Route exact path='/image-cropper' component={ImageCropper}/>
            <Route exact path='/your-account' component={YourAccount}/>
            <Route exact path='/basket' component={Basket}/>
            <Route exact path='/address' component={Address}/>
            <Route exact path='/checkout' component={Checkout}/>
            <Route exact path='/update-email' component={UpdateEmail}/>
            <Route exact path='/username' component={UpdateName}/>
            <Route exact path='/add-card' component={AddCard}/>
            <Route exact path='/payment-success' component={PaymentSuccess}/>
            <Route exact path='/orders' component={Orders}/>
            <Route exact path='/order-detail' component={OrderDetail}/>
            <Route exact path='/photographer-event-list' component={PhotographersEventList}/>
            <Route exact path='/photo-upload' component={PhotoUpload}/>
            <Route exact path='/privacy-policy' component={Privacy}/>
            <Route exact path='/terms' component={Terms}/>
            <Route exact path='/cookie-policy' component={Cookies}/>
            <Route exact path='/admin' component={AdminHome}/>
            <Route exact path='/admin/orders' component={AdminOrders}/>
            <Route exact path='/admin/orders/order-detail' component={AdminOrderDetail}/>
            <Route exact path='/admin/create-comp' component={CreateComp}/>
            <Route exact path='/admin/number-sorter' component={AdminNumberSorter}/>
            <Route exact path='/admin/view-photographers-photos' component={ViewPhotographersPhotos}/>
            <Route exact path='/admin/sponsors' component={AdminAddSponsor}/>
            <Route exact path='/admin/disciplines' component={AdminDisciplines}/>
            <Route exact path='/admin/disciplines/add' component={AdminDisciplinesAdd}/>

            <Route exact path='/home2' component={Home2}/>
            <Route exact path='/rider-search' component={RiderSearch}/>
            <Route exact path='/rider-profile' component={RiderProfile}/>
            <Route exact path='/rider-event-view' component={RiderEventView}/>
            
          </Switch>
        </Router>
      </div>
      
    )
  }
}

export default connect(null, { setUser, getUser })(RouterComponent);