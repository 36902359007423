import React, {useState} from 'react';
import GridContainer from "../../Grid/GridContainer.js";
import ImageList from './ImageList';
import {Error, SubTitle} from '../../Common';
import {useDispatch} from 'react-redux'
import { getTimePretty } from '../../../Utils/index.js';
import {setImageModal} from '../../../ActionReducers/Actions';
import { Button } from "@material-ui/core";
import SearchByTime from '../Components/SearchByTime';

export default function DrSjView(props) {
    
    const {images, discipline, eventid, eventData, loadingImages} = props
    const [showSearchByTime, setShowSearchByTime] = useState(false)
    const dispatch = useDispatch()
    return (
        <div>
            <SubTitle title={' '} />
            {!showSearchByTime && images && images.length > 0 && <Button
                color="primary"
                variant="outlined"
                onClick={() => setShowSearchByTime(true)}
            >
                Search by time
            </Button>}
            {showSearchByTime && <Button
                color="primary"
                variant="outlined"
                onClick={() => setShowSearchByTime(false)}
            >
                cancel
            </Button>}
            <br/>
            <br/>
            
            {showSearchByTime && <SearchByTime eventid={eventid} eventData={eventData} loadingImages={loadingImages} disc={'Showjumping'}/>}
            
            <GridContainer>
                {showImageList(images, dispatch, discipline, setShowSearchByTime)}
            </GridContainer>
        </div>
        
    )
}

function showImageList(images, dispatch, discipline, setShowSearchByTime) {
    if (images) {
        if (typeof images === 'string') {
            return <Error title={images} />
        } else if (typeof images === 'number') {
            const time = getTimePretty(images)
            let message;
            const message2 = "Try searching by time, using the button below, if there still aren't any photos then we are still uploading.";
            if (discipline === 'Showjumping') {
                message = "We took pictures of you showjumping at " + time + "."
            } else {
                message = "We took pictures of you doing dressage at " + time + ".Try searching by time, using the button below, if there still aren't any photos then we are still uploading."
            }
            return (
                <div style={{width: '100%'}}>
                    <Error title={message} />
                    <Error title={message2} />
                    <br/>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => setShowSearchByTime(true)}
                    >
                        Search by time
                    </Button>
                </div>
            )
        } else {
            if (images.length > 0) {

                return images.map((image, index) => <ImageList image={image} key={image.uid} setImage={() => dispatch(setImageModal(image, true))}/>)
            } else {
                const title1 = "We haven't managed to upload these photos yet."
                return <Error title={title1} />                
            }
        }

    } else {
      return
    }
    
  }