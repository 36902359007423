import React from 'react';
import {Title} from '../Common';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {Button} from '@material-ui/core';

export default function OrderItemDetail(props) {
    const { item } = props;
    const price = '£' + item.item.price

    return (
      <div>
        <ListItem>
            <div style={{display:"flex", width: '300px', flexDirection:"column"}}>
                <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"flex-end"}}>
                <img
                    src={item.selectedImage.urlCropped || item.selectedImage.url}
                    style={{height: '120px', width: 'auto', maxWidth: '250px'}}
                    alt='horse'
                />
                
                </div>
                <br/>
                <div style={{display:"flex", flexDirection:"column", alignItems:"flex-end"}}>
                <ListItemText primary={item.item.name}/>
                <br/>
                <Title title={price}/>
                <Button variant="contained" color="primary" href={item.selectedImage.urlCropped || item.selectedImage.url} target="blank">
                  Download image
                </Button>
                </div>
            </div>
            
            
        </ListItem>
        <Divider />
      </div>
    )
}