import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import {Footer} from "../Common";
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import Parallax from "../Grid/Parallax.js";

import {textStyle, containerStyle} from '../../Styles'
import TermsSection from './TermsSection';
// Sections for this page

const styles = {
  ...containerStyle,
  ...textStyle
}
const useStyles = makeStyles(styles);


export default function Terms() {
  const classes = useStyles();

  return (
    <div className={classes.answerDiv}>
      <Parallax filter image={require("../../Images/judge-cover.png")} fixedImage={true}>
      <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.parallaxTitle}>Terms and Conditions.</h1>
              <h4 className={classes.parallaxText}>
                Hopefully we'll never have to meet a judge, but if we do, we'll be going by these terms, so have a read before using the site.
              </h4>
              <h4 className={classes.parallaxText}>
                Terms were last updated on the 1st October 2023
              </h4>
            </GridItem>
          </GridContainer>
          </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
          <TermsSection />
      </div>
      <Footer />
    </div>
  );
}