import React from 'react';
import {useSelector} from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default function Drawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const {userDoc} = useSelector(state => state.auth)

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem button key={1} component="a" href="/">
            <ListItemText primary='Home'/>
        </ListItem>
        <Divider />
        <ListItem button key={2} component="a" href="/your-account">
            <ListItemText primary='Your account' />
        </ListItem>
        <Divider />
        <ListItem button key={3} component="a" href="/basket">
            <ListItemText primary='Basket' />
        </ListItem>
        <Divider />
        <ListItem button key={4} component="a" href="/orders">
            <ListItemText primary='Orders' />
        </ListItem>
        <Divider />
        {userDoc && userDoc.userType === 'Photographer' && <ListItem button key={5} component="a" href="/photographer-event-list">
            <ListItemText primary='Photographers' />
        </ListItem>}
        {userDoc && userDoc.admin && <ListItem button key={5} component="a" href="/admin">
            <ListItemText primary='Admin' />
        </ListItem>}
        <Divider />
      </List>
      
    </div>
  );

  return (
    <div>
      {['left',].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}><MenuIcon /></Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}