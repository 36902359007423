import React, {useEffect} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import {useDispatch} from 'react-redux'

import { makeStyles } from "@material-ui/core/styles";
import {ParallaxTitle, BigButton, Title, Footer} from '../Common';
import {containerStyle} from "../../Styles";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Parallax from '../Grid/Parallax';
import {eventsFetch} from '../../ActionReducers/Actions';
import { useHistory } from "react-router";

// Sections for this page

const useStyles = makeStyles(containerStyle);

export default function Home2() {
    const classes = useStyles();
    const history = useHistory()
    const dispatch = useDispatch()
    useEffect(() => {
      const date = (Date.now()/1000)
      dispatch(eventsFetch(date))
    }, [dispatch])

    const Background = 'https://firebasestorage.googleapis.com/v0/b/athalens-51eb0-event-profile/o/home3.jpg?alt=media&token=48b3db0e-6ce5-433e-9155-417f7cf897f9'
    const title = 'Athalens photography'
    const title1 = 'For British Eventing and FEI competitions click below'

    const title2 = 'For all other competitions click below'
    return (
      <div className={classes.answerDiv}>
        <Parallax filter image={Background} fixedImage={true}>
                <div className={classes.container}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <ParallaxTitle title={title} />
                </GridItem>
                </GridContainer>
                
                </div>
          </Parallax>
          <div className={classNames(classes.main, classes.mainRaised)}>
            <Title title={title1} />
            <BigButton title='View photos' onClick={() => history.push('/rider-search')}/>

            <Title title={title2} />
            <BigButton title='View photos' onClick={() => history.push('/')}/>
          </div>
          <Footer />
      </div>
    );
}