import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {containerStyle} from "../../Styles";
import { CSSTransition } from 'react-transition-group';
import { Button } from '@material-ui/core'
import './styles.css';

const useStyles = makeStyles(containerStyle);
export function BigButton(props) {
    const {title, onClick} = props;
    const classes = useStyles();

    return (
            <CSSTransition
                in={true}
                timeout={3000}
                classNames="alert"
                unmountOnExit
                appear={true}
            >
            <Button
                className={classes.bigButton}
                onClick={onClick}
                key={title}
            >
                {title}
            </Button>
            </CSSTransition>
    );
}