import React, {useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux'
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {Title, SubTitle} from '../Common';
import {containerStyle, textStyle} from "../../Styles";
import {Button, CircularProgress, Fade} from '@material-ui/core';
import {getBasket, goToCheckout} from '../../ActionReducers/Actions';
import BasketItem from '../Basket/BasketItem';
import _ from 'lodash';

const style = {
    ...containerStyle,
    ...textStyle
  }
const useStyles = makeStyles(style);

export default function Basket() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const {user, userDoc} = useSelector(state => state.auth)
    const {basket, basketLoading} = useSelector(state => state.basket)
    useEffect(() => {
        if (user) {
            dispatch(getBasket(user))
        }
    }, [dispatch, user])

    return (
        <Fade in={true} out={true} timeout={{enter: 100}}>
        <div className={classes.answerDiv}>
          <div className={classes.raisedCard}>
          <Title title="Basket" />
            {!user && <SubTitle title="Sign in to view your basket" />}
            {!user && <div>
                <Button variant="contained" color="primary" onClick={() => history.push('/signin')}>Sign in</Button>
            </div>}
            {user && basketLoading  && <CircularProgress />}
            {user && !basketLoading  && basket && basket.length > 0 && 
                basket.map((item) => {
                    return <BasketItem item={item} user={user}/>
                })
            }
            {user && !basketLoading  && basket && basket.length === 0 && <div>
                <SubTitle title="Basket empty" />
                </div>}
            {user && !basketLoading  && basket && basket.length > 0 &&
                <div>
                    {showPostage(basket)}
                    
                </div>}
            <br/>
            {user && !basketLoading  && basket && basket.length > 0 &&
                <div>
                    {showTotalExc(basket, classes)}
                    
                </div>}
            {user && !basketLoading  && basket && basket.length > 0 &&
                <div>
                    {showTotal(basket)}
                    
                </div>}
            {user && !basketLoading  && basket && basket.length > 0 &&
                <Button variant="contained" color="primary" onClick={() => dispatch(goToCheckout(userDoc, user, history))}>Checkout</Button>}
           
          </div>
          
        </div>
        </Fade>
    );
}

function showPostage(basket) {
    const postage = []
    basket.forEach((doc) => {
        postage.push(doc.item.pAndP)
    })

    const sorted = _.sortBy(postage).reverse();
    const price = sorted[0].toFixed(2);
    const text = 'Postage: £' + price;
    return <div style={{display: 'flex', justifyContent: 'flex-end'}}><SubTitle title={text} /></div>
}

function showTotal(basket) {
    const postage = []
    const total = []
    basket.forEach((doc) => {
        postage.push(doc.item.pAndP)
        if (!doc.quantity) {
            total.push(doc.item.price)
          } else {
                const price = doc.item.price * doc.quantity
                total.push(price)
          }
    })

    const sorted = _.sortBy(postage).reverse();
    const postagePrice = sorted[0];
    const imagePrice = total.reduce((a, b) => a + b)
    const price = (postagePrice + imagePrice).toFixed(2)
    const text = 'Total: £' + price;
    return <div style={{display: 'flex', justifyContent: 'flex-end'}}><SubTitle title={text} /></div>
}

function showTotalExc(basket, classes) {
    const postage = []
    const total = []
    basket.forEach((doc) => {
        postage.push(doc.item.pAndP)
        if (!doc.quantity) {
            total.push(doc.item.price)
        } else {
            const price = doc.item.price * doc.quantity
            total.push(price)
        }
    })

    const sorted = _.sortBy(postage).reverse();
    const postagePrice = sorted[0];
    const imagePrice = total.reduce((a, b) => a + b)
    const price = ((postagePrice + imagePrice)/1.2).toFixed(2)
    const text = 'Total excl VAT: £' + price;
    return <div style={{display: 'flex', justifyContent: 'flex-end'}}><p class={classes.date} >{text}</p></div>
}