import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";

import {textStyle} from '../../Styles'

// Sections for this page
const useStyles = makeStyles(textStyle);

export default function CookieSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <br/>
          <h1 className={classes.title}>
          1. Does Athalens use cookies?
          </h1>
          <h5 className={classes.description}>
          Yes. As described in Section 3.3 of our Privacy Policy, we use cookies and other technologies to ensure everyone who uses Athalens has the best possible experience. Cookies also help us keep your account safe. By continuing to visit or use our services, you are agreeing to the use of cookies and similar technologies for the purposes we describe in this policy.
          </h5>
          <h1 className={classes.title}>
          2. What is a cookie?
          </h1>
          <h5 className={classes.description}>
          A cookie is a small file placed onto your device that enables Athalens features and functionality. For example, cookies enable us to identify your device, secure your access to Athalens and our sites generally, and even help us know if someone attempts to access your account from a different device. Cookies also enable you to easily share content on Athalens and help us serve relevant ads to you.
          </h5>
          <h1 className={classes.title}>
          3. When does Athalens place cookies?
          </h1>
          <h5 className={classes.description}>
          We use cookies on our site and mobile applications. Any browser visiting these sites will receive cookies from us.
          </h5>
          <h1 className={classes.title}>
          4. What types of cookies does Athalens use?
          </h1>
          <h5 className={classes.description}>
          We use two types: persistent cookies and session cookies. A persistent cookie helps us recognize you as an existing user, so it&apos;s easier to return to Athalens or interact with our services without signing in again. After you sign in, a persistent cookie stays in your browser and will be read by Athalens when you return to our site. Session cookies only last for as long as the session (usually the current visit to a website or a browser session).
          </h5>
          <h1 className={classes.title}>
          5. Which Athalens entity is using cookies?
          </h1>
          <h5 className={classes.description}>
          Athalens Limited will be responsible for your personal data provided to, or collected by or for, our Services.
          </h5>
          <h1 className={classes.title}>
          6. What are cookies used for?
          </h1>
          <h5 className={classes.description}>
          Athalens uses cookies for a number of reasons, like protecting your Athalens data and account, helping us see which features are most popular, counting visitors to a page, improving our users’ experience, keeping our services secure, and just generally providing you with a better, more intuitive, and satisfying experience. The cookies we use generally fall into one of the following categories.
          </h5>
          <h5 className={classes.description}>
          Authentication - If you&apos;re signed in to Athalens, cookies help us show you the right information and personalize your experience.
          </h5>
          <h5 className={classes.description}>
          Security - We use cookies to enable and support our security features, and to help us detect malicious activity and violations of our User Agreement.
          </h5>
          <h5 className={classes.description}>
          Preferences, features and services - Cookies can tell us which language you prefer and what your communications preferences are. They can help you fill out forms on Athalens more easily.
          </h5>
          <h5 className={classes.description}>
          Advertising - We may use cookies to show you relevant advertising both on and off the Athalens site. We may also use a cookie to learn whether someone who saw an ad later visited and took an action (e.g. downloaded a white paper or made a purchase) on the advertiser's site. Similarly, our partners may use a cookie to determine whether we've shown an ad and how it performed, or provide us with information about how you interact with ads. We may also work with a partner to show you an ad on or off Athalens, such as after you've visited a partner's site or application.
          </h5>
          <h5 className={classes.description}>
          Performance, Analytics and Research - Cookies help us learn how well our site and plugins perform in different locations. We also use cookies to understand, improve, and research products, features, and services, including when you access Athalens from other websites, applications, or devices such as your work computer or your mobile device.
          </h5>
          <h1 className={classes.title}>
          7. How are cookies used for advertising purposes?
          </h1>
          <h5 className={classes.description}>
          Cookies and other ad technology such as beacons, pixels, and tags help us serve relevant ads to you more effectively. They also help us provide aggregated auditing, research, and reporting for advertisers, understand and improve our service, and know when content has been shown to you. Note: Because your web browser may request advertisements and beacons directly from third party ad network servers, these networks can view, edit, or set third party cookies, just as if you had requested a web page from their site. Ads served by Athalens may also set third party cookies.
          </h5>
          <h5 className={classes.description}>
          If you are logged in on CleanMySocial.com or another Service that references this Cookie Policy and one of our cookies on your device identifies you, your usage (such as your browsing behavior) and log data (such as your IP address), will be associated by us with your account. We also use aggregate data from third parties and data from your profile and Athalens activity.
          </h5>
          <h5 className={classes.description}>
          If you are a Athalens member but logged out of your account on a browser, Athalens may still continue to log your interaction with our services on that browser for up to 30 days in order to generate usage analytics for our services, which analytics we may share in aggregate form with our advertising customers.
          </h5>
          <h5 className={classes.description}>
          Unless you clear these cookies from your browser, we may use this information to:
          </h5>
          <h5 className={classes.description}>
          - provide more relevant, interest-based advertising
          </h5>
          <h5 className={classes.description}>
          - provide aggregate reports of ads activity to advertisers and websites hosting the ads
          </h5>
          <h5 className={classes.description}>
          - help website and app owners understand how visitors engage with their sites or apps
          </h5>
          <h5 className={classes.description}>
          - detect and defend against fraud and other risks to protect users and partners
          </h5>
          <h5 className={classes.description}>
          - improve our products
          </h5>
          <h5 className={classes.description}>
          We do not provide any personal information that we collect to advertisers unless you have given us permission to do so. You can opt-out of interest-based advertising from our customers, as well as our customers&apos; advertising on Athalens based on data collected through third party sites, by adjusting your settings. Please note that opting out will not remove advertising from the pages you visit; it will mean that the ads you see may not be matched to your interests.
          </h5>
          <h1 className={classes.title}>
          8. Controlling cookies
          </h1>
          <h5 className={classes.description}>
          Most browsers allow you to control cookies through their settings preferences. However, if you limit the ability of websites to set cookies, you may worsen your overall user experience, since it will no longer be personalized to you. It may also stop you from saving customized settings like login information.
          </h5>
          <h1 className={classes.title}>
          9. What to do if you don&apos;t want cookies to be set or want them to be removed?
          </h1>
          <h5 className={classes.description}>
          If you do not want to receive cookies, you can also change your browser settings on your computer or other device you&apos;re using to access our services.
          </h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}