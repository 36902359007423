import React, { Component } from 'react';
import RouterComponent from './RouterComponent';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import ReduxThunk from 'redux-thunk';
import reducers from './ActionReducers/Reducers';
import {createBrowserHistory} from 'history';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const history = createBrowserHistory();

const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));

const theme = createMuiTheme({
  palette: {
     primary: {
        light: '#ffffff',
        main: '#18cca3',
        dark: '#13a07f'
     },
     secondary: {
       main: '#BE1F1F',
     },
  },
  typography: {
     useNextVariants: true
  }
});

class App extends Component {
  render() {
    return (
      <div>
        <MuiThemeProvider theme = { theme }>
          <Provider store={store}>
            <RouterComponent history={history} />
          </Provider>
        </MuiThemeProvider>
      </div>
    );
  }
}

export default App;

// on creation of this please run the following
// npm i --save @material-ui/core
// npm i @material-ui/styles
// npm i history
// npm i redux
// npm i redux-thunk
// npm i react-redux
// npm i react-router-dom
// npm i classnames

// npm i history --save @material-ui/core @material-ui/styles redux redux-thunk react-redux react-router-dom classnames