import React, {useState} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import {useDispatch} from 'react-redux'

import { makeStyles } from "@material-ui/core/styles";
import {ParallaxTitle, Title, Footer, Hit} from '../Common';
import {containerStyle} from "../../Styles";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Parallax from '../Grid/Parallax';
import {setRider} from '../../ActionReducers/Actions';
import { useHistory } from "react-router";
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom';
// Sections for this page

const useStyles = makeStyles(containerStyle);
const searchClient = algoliasearch('NM8GR5O3O4', '917310cc1a0a17b7214f7842dcaed63a');

export default function RiderSearch() {
    const classes = useStyles();
    const [show, showHits] = useState(false)
    const history = useHistory()
    const dispatch = useDispatch()

    const Background = 'https://firebasestorage.googleapis.com/v0/b/athalens-51eb0-event-profile/o/home3.jpg?alt=media&token=48b3db0e-6ce5-433e-9155-417f7cf897f9'
    const title = 'Athalens photography'
    const title1 = 'Search for riders name'

    const TheHit = ({hit}) => {
      return <Hit hit={hit} onClick={() => dispatch(setRider(hit, history))} />
    }

    return (
      <div className={classes.answerDiv}>
        <Parallax filter image={Background} fixedImage={true}>
                <div className={classes.container}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <ParallaxTitle title={title} />
                </GridItem>
                </GridContainer>
                
                </div>
          </Parallax>
          <div className={classNames(classes.main, classes.mainRaised)}>
            <Title title={title1} />

            <InstantSearch searchClient={searchClient} indexName="RiderID">
                <SearchBox onKeyDown={()=>showHits(true)}/>
                {show && <Hits hitComponent={TheHit} onClick={(hit) => console.log(hit)}/>}
            </InstantSearch>
          </div>
          <Footer />
      </div>
    );
}