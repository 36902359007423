import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";

import {textStyle} from '../../Styles'

// Sections for this page
const useStyles = makeStyles(textStyle);

export default function TermsSection() {
  const classes = useStyles();
  return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <br/>
          <h1 className={classes.title}>
          Introduction
          </h1>
          <h5 className={classes.description}>
          When you use our Services you agree to all of these terms. Your use of our Services is also subject to our Cookie Policy and our Privacy Policy, which covers how we collect, use, share, and store your personal information.<br/>You agree that by clicking “Join Now”, “Sign Up” or similar, registering, accessing or using our services (described below), you are agreeing to enter into a legally binding contract with Athalens Limited. (even if you are using our Services on behalf of a company). If you do not agree to this contract (“Contract” or “User Agreement”), do not click “Join Now” (or similar) and do not access or otherwise use any of our Services.
          </h5>
          <h1 className={classes.title}>
          1. Services
          </h1>
          <h5 className={classes.description}>
          This Contract applies to Athalens.com, Athalens-branded apps and other Athalens-related sites, apps, communications and other services that state that they are offered under this Contract (“Services”), including the offsite collection of data for those Services. Registered users of our Services are “Members” and unregistered users are “Visitors”. This Contract applies to both.
          </h5>
          <h1 className={classes.title}>
          2. Athalens
          </h1>
          <h5 className={classes.description}>
          You are entering into this Contract with Athalens Limited. (also referred to as “we” and “us”). Your personal data provided to, or collected by or for, our Services is controlled by Athalens Limited.
          </h5>
          <h1 className={classes.title}>
          3. Members and Visitors
          </h1>
          <h5 className={classes.description}>
          When you register and join the Athalens Service, you become a Member. If you have chosen not to register for our Services, you may access certain features as a visitor.
          </h5>
          <h1 className={classes.title}>
          4. Change
          </h1>
          <h5 className={classes.description}>
          We may modify this Contract, our Privacy Policy and our Cookies Policies from time to time. If we make material changes to it, we will provide you notice through our Services, or by other means, to provide you the opportunity to review the changes before they become effective. If you object to any changes, you may close your account by contacting support at +44 7880 239001. Your continued use of our Services after we publish or send a notice about our changes to these terms means that you are consenting to the updated terms.
          </h5>
          <h1 className={classes.title}>
          5. Obligations
          </h1>
          <h1 className={classes.title}>
          5.1 Service Eligibility
          </h1>
          <h5 className={classes.description}>
          You’re eligible to enter into this Contract and you are at least our “Minimum Age.” The Services are not for use by anyone under the age of 16. To use the Services, you agree that: (1) you must be the “Minimum Age” (described below) or older; (2) you will only have one Athalens account, which must be in your real name; and (3) you are not already restricted by Athalens from using the Services.
          </h5>
          <h1 className={classes.title}>
          5.2 Your Account
          </h1>
          <h5 className={classes.description}>
          Members are account holders. You agree to: (1) try to choose a strong and secure password; (2) keep your password secure and confidential; (3) not transfer any part of your account (e.g., connections) and (4) follow the law and our list of Dos and Don’ts. You are responsible for anything that happens through your account unless you close it or report misuse.
          </h5>
          <h5 className={classes.description}>
          As between you and others (including your employer, school or university), your account belongs to you. However, if the Services were purchased by another party for you to use (e.g. your employer, school or university), the party paying for such Service has the right to control access to and get reports on your use of such paid Service; however, they do not have rights to your personal account.
          </h5>
          <h1 className={classes.title}>
          5.3 Payment
          </h1>
          <h5 className={classes.description}>
          If you buy any of our paid Services (“Premium Services”), you agree to pay us the applicable fees and taxes and to additional terms specific to the paid Services. Failure to pay these fees will result in the termination of your paid Services. Also, you agree that:
          </h5>
          <h5 className={classes.description}>
          a. Your purchase may be subject to foreign exchange fees or differences in prices based on location (e.g. exchange rates).
          </h5>
          <h5 className={classes.description}>
          b. We may store and continue billing your payment method (e.g. credit card) even after it has expired, to avoid interruptions in your Services and to use to pay other Services you may buy.
          </h5>
          <h5 className={classes.description}>
          c. If you purchase a subscription, your payment method automatically will be charged at the start of each subscription period for the fees and taxes applicable to that period. To avoid future charges, cancel before the renewal date.
          </h5>
          <h5 className={classes.description}>
          d. All of your purchases of Services are subject to Athalens’s refund policy.
          </h5>
          <h5 className={classes.description}>
          e. We may calculate taxes payable by you based on the billing information that you provide us at the time of purchase.
          </h5>
          <h1 className={classes.title}>
          5.4 Notices and Service Messages
          </h1>
          <h5 className={classes.description}>
          You agree that we will provide notices to you in the following ways: (1) a notice within the Service, or (2) a message sent to the contact information you provided us (e.g., email, mobile number, physical address). You agree to keep your contact information up to date.
          </h5>
          <h1 className={classes.title}>
          5.5 Sharing
          </h1>
          <h5 className={classes.description}>
          We are not obligated to publish any information or content on our Service and can remove it in our sole discretion, with or without notice.
          </h5>
          <h1 className={classes.title}>
          6. Rights and Limits
          </h1>
          <h1 className={classes.title}>
          6.1 Your License to Athalens
          </h1>
          <h5 className={classes.description}>
            As between you and Athalens, you own the content and information that you submit or post to the Services and you are only granting Athalens and our affiliates the following non-exclusive license: a worldwide, transferable and sublicensable right to use, copy, modify, distribute, publish, and process, information and content that you provide through our Services, without any further consent, notice and/or compensation to you or others.
          </h5>
          <h5 className={classes.description}>
          These rights are limited in the following ways:
          </h5>
          <h5 className={classes.description}>
          a. You can end this license for specific content by deleting such content from the Services, or generally by closing your account, except for the reasonable time it takes to remove from backup and other systems. We will not include your content in advertisements for the products and services of third parties to others without your separate consent (including sponsored content). However, we have the right, without payment to you or others, to serve ads near your content and information, and your social actions on sponsored content and company pages may be visible, as noted in the Privacy Policy.
          </h5>
          <h5 className={classes.description}>
          b. While we may edit and make formatting changes to your content (such as translating it, modifying the size, layout or file type or removing metadata), we will not modify the meaning of your expression.
          </h5>
          <h5 className={classes.description}>
          c. Because you own your content, you may choose to make it available to others.
          </h5>
          <h5 className={classes.description}>
          d. You agree that we may access, store and use any information that you provide in accordance with the terms of the Privacy Policy and your choices (including settings).
          </h5>
          <h5 className={classes.description}>
          e. By submitting suggestions or other feedback regarding our Services to Athalens, you agree that Athalens can use and share (but does not have to) such feedback for any purpose without compensation to you.
          </h5>
          <h5 className={classes.description}>
          f. You agree to only provide content or information that does not violate the law nor anyone’s rights (including intellectual property rights). You also agree that your profile information will be truthful. Athalens may be required by law to remove certain information or content in certain countries.
          </h5>
          <h1 className={classes.title}>
          6.2 Service Availability
          </h1>
          <h5 className={classes.description}>
          We may change, suspend or end any Service, or change and modify prices prospectively in our discretion. To the extent allowed under law, these changes may be effective upon notice provided to you. We may change or discontinue any of our Services. We don’t promise to store or keep showing any information and content that you’ve posted. Athalens is not a storage service. You agree that we have no obligation to store, maintain or provide you a copy of any content or information that you or others provide, except to the extent required by applicable law and as noted in our Privacy Policy.
          </h5>
          <h1 className={classes.title}>
          6.3 Other Content, Sites and Apps
          </h1>
          <h5 className={classes.description}>
          a. Your use of others’ content and information posted on our Services, is at your own risk.
          </h5>
          <h5 className={classes.description}>
          b. Others may offer their own products and services through Athalens, and we aren’t responsible for those third-party activities.
          </h5>
          <h5 className={classes.description}>
          c. You may encounter content or information that might be inaccurate, incomplete, delayed, misleading, illegal, offensive or otherwise harmful. Athalens generally does not review content provided by our Members or others. You agree that we are not responsible for others’ (including other Members’) content or information. We cannot always prevent this misuse of our services, and you agree that we are not responsible for any such misuse.
          </h5>
          <h5 className={classes.description}>
          d. You agree you are responsible for deciding if you want to access or use third party apps or sites that link from our Services. Third party apps and sites have their own legal terms and privacy policies, and you may be giving others permission to use your information in ways we would not. Except to the limited extent it may be required by applicable law, Athalens is not responsible for these other sites and apps, you agree to use these at your own risk.
          </h5>
          <h1 className={classes.title}>
          6.4 Limits
          </h1>
          <h5 className={classes.description}>
          Athalens reserves the right to limit your use of the Services. Athalens reserves the right to restrict, suspend, or terminate your account if Athalens believes that you may be in breach of this Contract or law or are misusing the Services (e.g. violating any Do and Don’ts).
          </h5>
          <h1 className={classes.title}>
          6.5 Intellectual Property Rights
          </h1>
          <h5 className={classes.description}>
          Athalens reserves all of its intellectual property rights in the Services. Using the Services does not give you any ownership in our Services or the content or information made available through our Services. Trademarks and logos used in connection with the Services are be the trademarks of their respective owners. Athalens and other Athalens trademarks, service marks, graphics, and logos used for our Services are trademarks or registered trademarks of Athalens.
          </h5>
          <h1 className={classes.title}>
          7. Disclaimer; Limit of Liability and Unique Claims
          </h1>
          <h1 className={classes.title}>
          7.1 No Warranty
          </h1>
          <h5 className={classes.description}>
          TO THE EXTENT ALLOWED UNDER LAW, Athalens AND ITS AFFILIATES (AND THOSE THAT Athalens WORKS WITH TO PROVIDE THE SERVICES) (A) DISCLAIM ALL IMPLIED WARRANTIES AND REPRESENTATIONS (E.G. WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, ACCURACY OF DATA, AND NON-INFRINGEMENT); (B) DO NOT GUARANTEE THAT THE SERVICES WILL FUNCTION WITHOUT INTERRUPTION OR ERRORS, AND (C) PROVIDE THE SERVICE (INCLUDING CONTENT AND INFORMATION) ON AN “AS IS” AND “AS AVAILABLE” BASIS.
          </h5>
          <h5 className={classes.description}>
          SOME LAWS DO NOT ALLOW CERTAIN DISCLAIMERS, SO SOME OR ALL OF THESE DISCLAIMERS MAY NOT APPLY TO YOU.
          </h5>
          <h1 className={classes.title}>
          7.2 Exclusion of Liability
          </h1>
          <h5 className={classes.description}>
          TO THE EXTENT PERMITTED UNDER LAW (AND UNLESS Athalens HAS ENTERED INTO A SEPARATE WRITTEN AGREEMENT THAT OVERRIDES THIS CONTRACT), Athalens AND ITS AFFILIATES (AND THOSE THAT Athalens WORKS WITH TO PROVIDE THE SERVICES) SHALL NOT BE LIABLE TO YOU OR OTHERS FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY LOSS OF DATA, OPPORTUNITIES, REPUTATION, PROFITS OR REVENUES, RELATED TO THE SERVICES (E.G. OFFENSIVE OR DEFAMATORY STATEMENTS, DOWN TIME OR LOSS, USE OF, OR CHANGES TO, YOUR INFORMATION OR CONTENT).
          </h5>
          <h5 className={classes.description}>
          IN NO EVENT SHALL THE LIABILITY OF Athalens AND ITS AFFILIATES (AND THOSE THAT Athalens WORKS WITH TO PROVIDE THE SERVICES) EXCEED, IN THE AGGREGATE FOR ALL CLAIMS, AN AMOUNT THAT EXCEEDS THE TOTAL FEES PAID TO Athalens DURING THE 12-MONTH PERIOD BEFORE THE EVENT GIVING RISE TO THE LIABILITY.
          </h5>
          <h5 className={classes.description}>
          THIS LIMITATION OF LIABILITY IS PART OF THE BASIS OF THE BARGAIN BETWEEN YOU AND Athalens AND SHALL APPLY TO ALL CLAIMS OF LIABILITY (E.G. WARRANTY, TORT, NEGLIGENCE, CONTRACT, LAW) AND EVEN IF Athalens OR ITS AFFILIATES HAS BEEN TOLD OF THE POSSIBILITY OF ANY SUCH DAMAGE, AND EVEN IF THESE REMEDIES FAIL THEIR ESSENTIAL PURPOSE.
          </h5>
          <h5 className={classes.description}>
          SOME LAWS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY, SO THESE LIMITS MAY NOT APPLY TO YOU.
          </h5>
          <h1 className={classes.title}>
          7.3 Unique Claims
          </h1>
          <h5 className={classes.description}>
          We both agree that this Contract is personal and unique to you and Athalens, and each we agree that we will not directly or indirectly attempt to form, aid or abet, or certify a class action or similar group of plaintiffs whether or not constituting a class against you or Athalens.
          </h5>
          <h1 className={classes.title}>
          8. Termination
          </h1>
          <h1 className={classes.title}>
          8.1 Both you and Athalens may terminate this Contract at any time with notice to the other. On termination, you lose the right to access or use the Services as a Member.
          </h1>
          <h1 className={classes.title}>
          8.2 The following shall survive termination:
          </h1>
          <h5 className={classes.description}>
          a. Our rights to use and disclose your feedback;
          </h5>
          <h5 className={classes.description}>
          b. Sections 4, 6 and 7 of this Contract;
          </h5>
          <h5 className={classes.description}>
          c. Any amounts owed by either party prior to termination remain owed after termination.
          </h5>
          <h1 className={classes.title}>
          9. Dispute Resolution
          </h1>
          <h5 className={classes.description}>
          You agree that the laws of the United Kingdom, excluding its conflict of laws rules, shall exclusively govern any dispute relating to this Contract and/or the Services. We both agree that all of these claims can only be litigated in the courts in the United Kingdom, and we each agree to personal jurisdiction in those courts. We both agree that in any action or proceeding instituted by a party arising in whole or in part under, related to, based on or in connection with this Contract or the subject matter hereof, the prevailing party will be entitled to receive from the losing party reasonable attorney&apos;s fees, costs and expenses incurred in connection therewith, including any appeals there from.
          </h5>
          <h1 className={classes.title}>
          10. General Terms
          </h1>
          <h5 className={classes.description}>
          If a court with authority over this Contract finds any part of it not enforceable, you and us agree that the court should modify the terms to make that part enforceable while still achieving its intent. If the court cannot do that, you and us agree to ask the court to remove that unenforceable part and still enforce the rest of this Contract. To the extent allowed by law, the English language version of this Contract is binding and other translations are for convenience only. This Contract (including additional terms that may be provided by us when you engage with a feature of the Services) is the only agreement between us regarding the Services and supersedes all prior agreements for the Services.
          </h5>
          <h5 className={classes.description}>
          If we don&apos;t act to enforce a breach of this Contract, that does not mean that Athalens has waived its right to enforce this Contract. You may not assign or transfer this Contract (or your membership or use of Services) to anyone without our consent. However, you agree that Athalens may assign this Contract to its affiliates or a party that buys it without your consent. There are no third party beneficiaries to this Contract.
          </h5>
          <h5 className={classes.description}>
          We reserve the right to change the terms of this Contract and will provide you notice if we do and we agree that changes cannot be retroactive. If you don&apos;t agree to these changes, you must stop using the Services.
          </h5>
          <h5 className={classes.description}>
          You agree that the only way to provide us legal notice is at the address in Section 24.
          </h5>
          <h1 className={classes.title}>
          11. “Dos” and “Don’ts”
          </h1>
          <h1 className={classes.title}>
          11.1 Dos - You agree that you will:
          </h1>
          <h5 className={classes.description}>
          a. Comply with all applicable laws, including, without limitation, privacy laws, intellectual property laws, anti-spam laws, export control laws, tax laws, and regulatory requirements;
          </h5>
          <h5 className={classes.description}>
          b. Provide accurate information to us and keep it updated;
          </h5>
          <h5 className={classes.description}>
          c. Use your real name on your profile;
          </h5>
          <h5 className={classes.description}>
          d. Use the Services in a professional manner.
          </h5>
          <h1 className={classes.title}>
          11.2 Don&apos;ts - You agree that you will not:
          </h1>
          <h5 className={classes.description}>
          a. Act in an unlawful or unprofessional manner in connection with our Services, including being dishonest, abusive or discriminatory;
          </h5>
          <h5 className={classes.description}>
          b. Post inaccurate, defamatory obscene, shocking, hateful, threatening or otherwise inappropriate content or airing personal grievances or disputes;
          </h5>
          <h5 className={classes.description}>
          c. Use an image that is not your likeness or a head-shot photo for your profile;
          </h5>
          <h5 className={classes.description}>
          d. Create a false identity on Athalens;
          </h5>
          <h5 className={classes.description}>
          e. Misrepresent your identity (e.g. by using a pseudonym), your current or previous positions, qualifications or affiliations with a person or entity;
          </h5>
          <h5 className={classes.description}>
          f. Create a Member profile for anyone other than yourself (a real person);
          </h5>
          <h5 className={classes.description}>
          g. Use or attempt to use another&apos;s account;
          </h5>
          <h5 className={classes.description}>
          h. Harass, abuse or harm another person;
          </h5>
          <h5 className={classes.description}>
          i. Send or post any unsolicited or unauthorized advertising, “junk mail,” “spam,” “chain letters” or any form of solicitation unauthorized by Athalens;
          </h5>
          <h5 className={classes.description}>
          j. Develop, support or use software, devices, scripts, robots, or any other means or processes (including crawlers, browser plugins and add-ons, or any other technology or manual work) to scrape the Services or otherwise copy profiles and other data from the Services;
          </h5>
          <h5 className={classes.description}>
          k. Bypass or circumvent any access controls or Service use limits;
          </h5>
          <h5 className={classes.description}>
          l. Copy, use, disclose or distribute any information obtained from the Services, whether directly or through third parties (such as search engines), without the consent of Athalens;
          </h5>
          <h5 className={classes.description}>
          m. Use, disclose or distribute any data obtained in violation of this policy;
          </h5>
          <h5 className={classes.description}>
          n. Disclose information that you do not have the consent to disclose (such as confidential information of others (including your employer, school or university));
          </h5>
          <h5 className={classes.description}>
          o. Violate the intellectual property rights of others, including copyrights, patents, trademarks, trade secrets, or other proprietary rights;
          </h5>
          <h5 className={classes.description}>
          p. Violate the intellectual property or other rights of Athalens Limited., Including, without limitation, (i) copying or distributing our technology; (ii) using the word “Athalens” or our logos in any business name, email, or URL;
          </h5>
          <h5 className={classes.description}>
          q. Post anything that contains software viruses, worms, or any other harmful code;
          </h5>
          <h5 className={classes.description}>
          r. Reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for the Services or any related technology that is not open source;
          </h5>
          <h5 className={classes.description}>
          s. Imply or state that you are affiliated with or endorsed by Athalens without our express consent (e.g., representing yourself as an accredited Athalens trainer);
          </h5>
          <h5 className={classes.description}>
          t. Rent, lease, loan, trade, sell/re-sell access to the Services or related data;
          </h5>
          <h5 className={classes.description}>
          u. Sell, sponsor, or otherwise monetize any Service without Athalens’s consent;
          </h5>
          <h5 className={classes.description}>
          v. Deep-link to our Services for any purpose other than to promote your profile without Athalens’s consent;
          </h5>
          <h5 className={classes.description}>
          w. Remove any copyright, trademark or other proprietary rights notices contained in or on our Service;
          </h5>
          <h5 className={classes.description}>
          x. Remove, cover or obscure any advertisement included on the Services;
          </h5>
          <h5 className={classes.description}>
          y. Use bots or other automated methods to access the Services;
          </h5>
          <h5 className={classes.description}>
          z. Monitor the Services’ availability, performance or functionality for any competitive purpose;
          </h5>
          <h5 className={classes.description}>
          aa. Engage in “framing,” “mirroring,” or otherwise simulating the appearance or function of the Services;
          </h5>
          <h5 className={classes.description}>
          ab. Overlaying or otherwise modifying the Services or their appearance;
          </h5>
          <h5 className={classes.description}>
          ac. Access the Services except through the interfaces expressly provided by Athalens, such as its mobile applications and Athalens.com;
          </h5>
          <h5 className={classes.description}>
          ad. Use a Service for tasks that it is not intended for;
          </h5>
          <h5 className={classes.description}>
          ae. Override any security feature of the Services; and/or
          </h5>
          <h5 className={classes.description}>
          af. Interfere with the operation of, or place an unreasonable load on, the Services (e.g., spam, denial of service attack, viruses, gaming algorithms).
          </h5>
          <h1 className={classes.title}>
          12. Complaints Regarding Content
          </h1>
          <h5 className={classes.description}>
          Athalens will respond to notices of alleged copyright infringement and terminate accounts of repeat infringers according to the process set out in the U.S. Digital Millennium Copyright Act. If you have a good faith belief that your work has been copied in a way that constitutes copyright infringement, or that your intellectual property rights have been otherwise violated, you can submit a notice of claimed infringement to the Company’s designated agent by email at info@morzillo.com. The notice must contain, at least, the following information:
          </h5>
          <h5 className={classes.description}>
          - An identification of the copyrighted work claimed to have been infringed.
          </h5>
          <h5 className={classes.description}>
          - An identification of the material that is claimed to be infringing and information reasonably sufficient to permit the Company to locate the material.
          </h5>
          <h5 className={classes.description}>
          - Information reasonably sufficient to permit the Company to contact you, such as an address, telephone number, and, if available, an email address.
          </h5>
          <h5 className={classes.description}>
          - An express statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner.
          </h5>
          <h5 className={classes.description}>
          - An express statement that the information in the notice is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of the copyright that you claim is being infringed upon.
          </h5>
          <h5 className={classes.description}>
          - A physical or electronic signature (i.e., /s/NAME) of the owner or person authorized to act on behalf of the owner of the copyright that you claim is being infringed upon.
          </h5>
          <h1 className={classes.title}>
          13. How To Contact Us
          </h1>
          <h5 className={classes.description}>
          If you want to send us notices or service of process, please contact us at: Norrells, Sarsden, Oxfordshire, OX76PS, United Kingdom, Attn: Notice
          </h5>
        </GridItem>
      </GridContainer>
  );
}