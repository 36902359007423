import React, {useState} from "react";
import {useDispatch} from 'react-redux'
import { makeStyles } from "@material-ui/core/styles";
import {Title} from '../../Common';
import {containerStyle, textStyle} from "../../../Styles";
import { Button, CircularProgress, Select, FormControl, InputLabel } from "@material-ui/core";
import {searchPrizeGiving} from '../../../ActionReducers/Actions';
const style = {
    ...containerStyle,
    ...textStyle
  }
const useStyles = makeStyles(style);

export default function PrizeGiving(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {eventid, eventData, loadingImages, prizes} = props;
    const [section, setSection] = useState()

    console.log('section: ', section)
    
    return (
        <div>
            <Title title='Search prize giving' />
        {/* ////////DAY///////// */}
        {eventData && <FormControl
                className={classes.formControl}
                onChange={(e) => setSection(e.target.value)}
              >
                <InputLabel htmlFor="age-native-simple">Section</InputLabel>
                <Select
                  native
                  inputProps={{
                      name: 'Section',
                      id: 'age-native-simple',
                  }}
                > 
                    <option key={0} value=''></option>
                    {prizes.map((opt, index) => {
                      return <option key={index} value={index}>{opt}</option>
                    })}
                </Select>
        </FormControl>}
       
            <div>
                <br />
        {!loadingImages && <Button
                color="primary"
                variant="contained"
                onClick={() => dispatch(searchPrizeGiving(eventid, section))}
                disabled={checkDisabled(section)}
            >
                Search prize giving
            </Button>}
        {loadingImages && <CircularProgress />}
        <br/>
        <br/>
        </div>
          
        </div>
    );
}

function checkDisabled(section) {
  if (!section) {
    return true
  } else {
    return false
  }
}