import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

import {textStyle} from "../../Styles";
const useStyles = makeStyles(textStyle);
export function Hit({hit, onClick}) {

    const classes = useStyles();
    
    return (
        <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <div onClick={() => onClick(hit)} style={{borderBottomWidth: 1, width: '250px', borderBottomColor: '#A1A4A6', borderBottomStyle: 'solid'}}>
                <p className={classes.centerText}>{hit.Rider}</p>
            </div>
        </div>
        
    );
}