import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {Title, Error, SubTitle} from '../Common';
import {containerStyle, textStyle} from "../../Styles";
import {Fade, Button, CircularProgress} from '@material-ui/core';
import {unSortedfilesSelected, loadUnsortedImages} from '../../ActionReducers/Actions';
import {Select, FormControl, InputLabel} from '@material-ui/core';

const style = {
    ...containerStyle,
    ...textStyle
  }
const useStyles = makeStyles(style);

export default function PhotoUpload() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [error, setError] = useState('')
    const [disc, setDisc] = useState();
    const [fenceNumber, setFenceNumber] = useState();
    const [automated, setAutomated] = useState();
    const [arena, setArena] = useState(null);

    const {
        event,
        unsortedSelectedFiles,
        unsortedTotalFiles,
        unsortedImagesLoaded,
    } = useSelector(state => state.photo)
    const {userDoc} = useSelector(state => state.auth)
      useEffect(() => {
        if (!event) {
            history.push('/photographer-event-list')
        }
      }, [event, history])

    
    function fileUploadHandler() {
        const eventchosen = event.uid
        const { name, lastName, uid } = userDoc;
        const Name = name + ' ' + lastName;
        console.log("disc: ", disc)
        if (!unsortedSelectedFiles) {
          setError('Please select photos')
        } else if (!disc || disc === 'none') {
          setError('Please select discipline')
        } else if (disc === 'XC' && !fenceNumber) {
          setError('Please select fence number')
        } else if (disc === 'XC' && fenceNumber && !automated) {
          setError('Please select automated or handheld')
        } else if (disc === 'Showjumping' && event.arenas > 1 && !arena) {
          setError('Please select arena')
        } else if (disc === 'Prize giving' && !fenceNumber) {
          setError('Please select section')
        } else {
          setError('')
          dispatch(loadUnsortedImages(unsortedSelectedFiles, eventchosen, Name, uid, disc, fenceNumber, automated, arena));
        }
      }
    
      function showFilesUploaded() {
        if (unsortedTotalFiles === 0 && unsortedImagesLoaded === 0) {
          return (
            <Button variant="contained" color="primary" onClick={() => fileUploadHandler()}>Upload</Button>
          )
        } else if (unsortedImagesLoaded < unsortedTotalFiles) {
          return (
            <div>
              <CircularProgress />
              <SubTitle title={`${unsortedImagesLoaded} of ${unsortedTotalFiles} loaded`} />
            </div>
          )
        } else {
          return (
            <div>
              <SubTitle title="Upload finished" />
              <Button variant="contained" color="primary" onClick={() => fileUploadHandler()}>Upload</Button>
            </div>
          )
        }
      }

      const fenceNumbers = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40]
      
      function setDiscipline(e) {
        setDisc(e)
        setFenceNumber(null)
        setAutomated(null)
        setArena(null)
      }

      return (
        <Fade in={true} out={true} timeout={{enter: 100}}>
        <div className={classes.answerDiv}>
          <div className={classes.raisedCard}>
            
          {event && <Title title={"Upload images for " + event.name} />}
          {event && event.discipline &&
              <FormControl
                className={classes.formControl}
                onChange={(e) => setDiscipline(e.target.value)}
              >
                <InputLabel htmlFor="age-native-simple">Discipline</InputLabel>
                <Select
                  native
                  inputProps={{
                      name: 'Discipline',
                      id: 'age-native-simple',
                  }}
                > 
                    <option value='none'></option>
                    {event.discipline.map((opt, index) => {
                      return <option key={index} value={opt}>{opt}</option>
                    })}
                </Select>
                <br/>
            </FormControl>}
            {event && event.discipline && disc === 'XC' &&
              <FormControl
                className={classes.formControl}
                onChange={(e) => setFenceNumber(e.target.value)}
              >
                <InputLabel htmlFor="age-native-simple">Fence number</InputLabel>
                <Select
                  native
                  inputProps={{
                      name: 'Discipline',
                      id: 'age-native-simple',
                  }}
                > 
                    <option value='none'></option>
                    {fenceNumbers.map((opt, index) => {
                      return <option key={index} value={opt}>{opt}</option>
                    })}
                </Select>
                <br/>
            </FormControl>}
            {event && event.discipline && disc === 'Showjumping' && event.arenas > 1 &&
              <FormControl
                className={classes.formControl}
                onChange={(e) => setArena(e.target.value)}
              >
                <InputLabel htmlFor="age-native-simple">Arena name</InputLabel>
                <Select
                  native
                  inputProps={{
                      name: 'Discipline',
                      id: 'age-native-simple',
                  }}
                > 
                    <option value='none'></option>
                    {event.arenaNames.map((opt, index) => {
                      return <option key={index} value={opt}>{opt}</option>
                    })}
                </Select>
                <br/>
            </FormControl>}
            {event && event.discipline && disc === 'XC' &&
              <FormControl
                className={classes.formControl}
                onChange={(e) => setAutomated(e.target.value)}
              >
                <InputLabel htmlFor="age-native-simple">Automated or Handheld</InputLabel>
                <Select
                  native
                  inputProps={{
                      name: 'Discipline',
                      id: 'age-native-simple',
                  }}
                > 
                    <option value='none'></option>
                    <option key={false} value={false}>Handheld</option>
                    <option key={true} value={true}>Automated</option>
                </Select>
            </FormControl>}
            {event && event.discipline && disc === 'Prize giving' &&
              <FormControl
                className={classes.formControl}
                onChange={(e) => setFenceNumber(e.target.value)}
              >
                <InputLabel htmlFor="age-native-simple">Section</InputLabel>
                <Select
                  native
                  inputProps={{
                      name: 'Section',
                      id: 'age-native-simple',
                  }}
                > 
                    <option value='none'></option>
                    {event.prizes.map((opt, index) => {
                      return <option key={index} value={index}>{opt}</option>
                    })}
                </Select>
            </FormControl>}
            <br/>
            <br/>
          {!event && <Button variant="contained" color="primary" onClick={() => history.push('/photographer-event-list')}>Choose event</Button>}
          {event && <input type="file" multiple="multiple" style={{ backgroundColor: '#d1faf0', padding: 20, borderRadius: 5}} onChange={e => {setError(''); dispatch(unSortedfilesSelected(e.target.files))}} accept=".jpg,.jpeg"/>}
          <br/>
          {showFilesUploaded()}
            <Error title={error} />
          </div>
          
        </div>
        </Fade>
    );
}