import {
    GET_PRODUCTS,
    ADD_TO_BASKET,
    ON_ADD_TO_BASKET,
    ADD_TO_BASKET_ERROR,
    BASKET_LOADING,
    GO_TO_BASKET,
    GET_BASKET,
    DELETE_BASKET_ITEM,
    UPDATE_QUANTITY,
    GET_ADDRESS,
    SET_ADDRESS,
    UPDATE_EMAIL,
    UPDATE_NAME,
    SET_BASKET_AS_ORDER,
    SET_ORDER,
    GET_ORDERS,
    ORDER_DETAIL,
  } from '../Actions/Types';
  
  const INITIAL_STATE = {
    products: [],
    product: null,
    basketConfirmVisible: false,
    basketLoading: false,
    orderList: null,
    orderDetailToView: null,
  };
  
  export function BasketReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
      case GET_PRODUCTS:
        return { ...state, products: action.payload };
      case ADD_TO_BASKET:
        return {...state, basketLoading: false, basketConfirmVisible: true};
      case ON_ADD_TO_BASKET:
        return {...state, product: action.payload};
      case GO_TO_BASKET:
        return {...state, basketConfirmVisible: false};
      case BASKET_LOADING:
        return {...state, basketLoading: true, basketError: ''};
      case ADD_TO_BASKET_ERROR:
        return {...state, basketError: 'Error loading item to basket, please try again.', basketLoading: false}
      case GET_BASKET:
        return {...state, basket: action.payload, basketLoading: false};
      case DELETE_BASKET_ITEM:
        return state;
      case UPDATE_QUANTITY:
        return state;
      case GET_ADDRESS:
        return {...state, address: action.address, userData: action.user};
      case SET_ADDRESS:
        return state;
      case UPDATE_EMAIL:
        return state;
      case UPDATE_NAME:
        return state;
      case SET_BASKET_AS_ORDER:
        return {...state, currentOrder: action.payload};
      case SET_ORDER:
        return state;
      case GET_ORDERS:
        return {...state, orderList: action.payload, basketLoading: false};
      case ORDER_DETAIL:
        return {...state, orderDetailToView: action.payload};
      default:
        return state;
    }
  };
  