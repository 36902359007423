import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

import {textStyle, containerStyle} from "../../Styles";

const style = {
    ...containerStyle,
    ...textStyle
  }
  const useStyles = makeStyles(style);
export function RiderEvent(props) {
    const {event, onClick} = props;
    const classes = useStyles();
    return (
        <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
            <div style={{width: '300px'}}>
                <div onClick={onClick} className={classes.postDiv} >
                    <div className={classes.postDiv2}>
                        <div style={{padding: '10px'}}>
                            <p className={classes.name}>
                                {event.event_name}
                            </p>
                            <p className={classes.date}>
                                {event.start_date}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

 
