import React, { useState } from 'react';
// import {useDispatch} from 'react-redux';
// import {onShowModal, onDeleteSelect, sharePost} from '../../actions';
import { makeStyles } from "@material-ui/core/styles";
import {Modal} from '@material-ui/core'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Button, CircularProgress } from "@material-ui/core";
import OrderList from '../Basket/OrderList';
import Watermark from '../../Images/watermark.png'

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      justifyContent: 'center',
      overflowY: 'auto',
      opacity: 1,
      outline: 'none',
      "&:focus": {
        outline: 'none',
      },
      backgroundColor: 'rgba(0, 0, 0, 0.7);'
    },
    paper: {
      maxWidth: '700px',
      borderWidth: 0,
    },
    modalImage: {
        width: '700px',
        minHeight: '40vh',
        maxHeight: '70vh',
        objectFit: 'contain',
        marginTop: '10vh',
        zIndex: 2
    },
    modalImageMobile: {
        width: '100%',
        objectFit: 'contain',
        marginTop: '15vh',
        minHeight: '40vh',
        maxHeight: '80vh',
        zIndex: 2
    },
    imgDivMobile: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '40vh',
      maxHeight: '70vh',
      width: '100%'
    },
    imgDiv: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '40vh',
      width: '700px'
    },
    watermark: {
      width: '700px',
      height: 'auto',
      objectFit: 'cover',
      position: 'absolute',
      top: '10vh',
      left: 0,
      right: 0,
      zIndex: 2,
      margin: 'auto',
      textAlign: 'center',
      opacity: 0.6,
    },
    watermarkMobile: {
      width: '100%',
      height: 'auto',
      objectFit: 'cover',
      position: 'absolute',
      top: '18vh',
      left: 0,
      right: 0,
      zIndex: 2,
      margin: 'auto',
      textAlign: 'center',
      opacity: 0.6,
    }
}));

export default function ImageModal(props) {
    // const dispatch = useDispatch()
    const classes = useStyles();
    const { open, image, handleClose, isMobile } = props;
    const [order, showOrder] = useState(false)
    let classType;
    let classTypeDiv;
    let classTypeWatermark;
    if (isMobile) {
        classType = 'modalImageMobile'
        classTypeDiv = 'imgDivMobile'
        classTypeWatermark = 'watermarkMobile'
    } else {
        classType = 'modalImage'
        classTypeDiv = 'imgDiv'
        classTypeWatermark = 'watermarkMobile'
    }

    return (
        <Modal
            className={classes.modal}
            open={open}
            onClose={() => {showOrder(false); handleClose()}}
            closeAfterTransition
            BackdropProps={{
            timeout: 500,
            }}
        >
              {image &&
              <div style={{outline: 0, justifyContent: 'center'}}>
                <TransformWrapper>
                  <TransformComponent>
                    <div className={classes[classTypeDiv]}>
                    <CircularProgress style={{ position: 'absolute', marginTop: '50%', marginLeft: '50%', zIndex: 1}}/>
                      <img
                        src={image.urlSocial || image.url}
                        className={classes[classType]}
                        alt={'users'}
                        onLoad={() => console.log('loaded')}
                      />
                      <img
                        src={Watermark}
                        className={classes[classTypeWatermark]}
                        alt={'users'}
                    />
                    </div>
                    
                  </TransformComponent>
                </TransformWrapper>
                {!order && isMobile && <Button variant="contained" color="light" style={{margin: 10}} onClick={() => {showOrder(false); handleClose()}}>Close</Button>}
                {!order && <Button variant="contained" color="primary" style={{margin: 10}} onClick={() => {showOrder(true)}}>Order</Button>}
                {order && <Button variant="contained" color="light" style={{margin: 10}} onClick={() => {showOrder(false); handleClose()}}>Close</Button>}
                {order && <div style={{height: 'auto', backgroundColor:'#fff'}}>
                  <OrderList event image />
                  </div>}
              </div>}
      </Modal>
    )
}