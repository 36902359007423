import React, {useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import {Title} from '../../Common';
import {containerStyle, textStyle} from "../../../Styles";
import {Fade, TextField, Button, ListItem, ListItemText, Divider} from '@material-ui/core';
import {findNumbers} from '../../../ActionReducers/Actions';
import {getTimePretty} from '../../../Utils/index';

const style = {
    ...containerStyle,
    ...textStyle
  }
const useStyles = makeStyles(style);

export default function AdminNumberSorter() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [photographerID, setPhotographerID] = useState()
    const [eventID, setEventID] = useState()
    const {numberSorting} = useSelector(state => state.admin)

    return (
        <Fade in={true} out={true} timeout={{enter: 100}}>
        <div className={classes.answerDiv}>
          <div className={classes.raisedCard}>
          <Title title="Select event" />

            <TextField
                type='eventid'
                id="eventid"
                label="eventid"
                variant="outlined"
                className={classes.detailsInput}
                onChange={(a) => {setEventID(a.target.value)}}
            />
             <TextField
                type='photographer id'
                id="photographer id"
                label="photographer"
                variant="outlined"
                className={classes.detailsInput}
                onChange={(a) => {setPhotographerID(a.target.value)}}
            />
          <br/>
          <Button variant="contained" color="primary" onClick={() => dispatch(findNumbers(photographerID, eventID))}>Enter</Button>
          </div>
          <div className={classes.raisedCard}>
          {numberSorting.length > 0 && numberSorting.map((event, index) => {
              return (
                  <div key={index}>
                    <ListItem >
                        <ListItemText primary={event.number} secondary={getTimePretty(event.time)} />
                    </ListItem>
                    <Divider />
                </div>
              )
          })}
          </div>
        </div>
        </Fade>
    );
}