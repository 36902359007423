import { firebaseApp } from '../../firebase';
import {generateUUID} from '../../Utils';
import {
  GO_TO_PHOTO_UPLOAD,
  UNSORTED_FILES_SELECTED,
  UNSORTED_LOAD_IMAGES_START,
  UNSORTED_LOADED_SINGLE_IMAGE,
  UNSORTED_LOAD_IMAGES_FINISHED,
} from './Types';

export const goToUploadPhotos = (event, history) => {
    return dispatch => {
        dispatch({
            type: GO_TO_PHOTO_UPLOAD,
            payload: event
        })
        history.push('/photo-upload')
    }
}

export const unSortedfilesSelected = (selectedfiles) => {
    return {
      type: UNSORTED_FILES_SELECTED,
      payload: selectedfiles
    };
  }

export const loadUnsortedImages = (unsortedSelectedFiles, eventchosen, Name, uid, disc, fenceNumber, automated, arena) => {

    return async (dispatch) => {
        dispatch({
            type: UNSORTED_LOAD_IMAGES_START,
            payload: unsortedSelectedFiles.length
        });

        const promises = [];
        for (var i = 0; i < unsortedSelectedFiles.length; i++) {
            let imageFile = unsortedSelectedFiles[i];
            const imageUID = generateUUID();
            console.log(imageUID)
            let metadata;
            if (disc === 'XC' && fenceNumber && automated) {
                metadata = {
                    customMetadata: {
                        'photographerUid': uid,
                        'photographerName': Name,
                        'eventUid': eventchosen,
                        'imageUID': imageUID,
                        "disc": disc,
                        "fenceNumber": fenceNumber,
                        "automated": automated,
                    }
                }
            } else if (disc === 'Showjumping' && arena) {
                metadata = {
                    customMetadata: {
                        'photographerUid': uid,
                        'photographerName': Name,
                        'eventUid': eventchosen,
                        'imageUID': imageUID,
                        "disc": disc,
                        "fenceNumber": fenceNumber,
                        "arena": arena,
                    }
                }
            } else if (disc === 'Prize giving' && fenceNumber) {
                metadata = {
                    customMetadata: {
                        'photographerUid': uid,
                        'photographerName': Name,
                        'eventUid': eventchosen,
                        'imageUID': imageUID,
                        "disc": disc,
                        "fenceNumber": fenceNumber,
                        "arena": arena,
                    }
                }
            } else {
                metadata = {
                    customMetadata: {
                        'photographerUid': uid,
                        'photographerName': Name,
                        'eventUid': eventchosen,
                        'imageUID': imageUID,
                        "disc": disc,
                    }
                }
            }

            const storageRef = firebaseApp.storage().ref(`britishEventing/${eventchosen}/unsorted${imageUID}`)
            
            const put = await storageRef.put(imageFile, metadata)
           
            const disp = dispatch({
                type: UNSORTED_LOADED_SINGLE_IMAGE,
            });
            
            promises.push(put)
            
            promises.push(disp)
        }

        const end = dispatch({
            type: UNSORTED_LOAD_IMAGES_FINISHED,
        });
        promises.push(end)
        return Promise.all(promises)
    }
}