import React, {useState} from "react";
import {useDispatch} from 'react-redux'
import { makeStyles } from "@material-ui/core/styles";
import {Title, SubTitle} from '../../Common';
import {containerStyle} from "../../../Styles";
import {Select, FormControl, InputLabel, TextField, Fade, Button} from '@material-ui/core';
import {createCompetition} from '../../../ActionReducers/Actions';
import { getTimeStamp } from "../../../Utils";

const useStyles = makeStyles(containerStyle);

export default function CreateComp() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [compName, setCompName] = useState()

    const [duration, setDuration] = useState()
    const [disc, setDisc] = useState()

    const [day, setDay] = useState(1)
    const [month, setMonth] = useState(1)
    const [year, setYear] = useState(2023)

    const [esID, setESID] = useState()
    const [useES, setES] = useState(true)

    const [url, setUrl] = useState()
    const [thumb, setThumb] = useState()
    const [arenas, setArenas] = useState()

    const [useTime, setUseTime] = useState(false)

    console.log('useTime: ' ,useTime, typeof useTime)
    console.log('useES: ' ,useES, typeof useES)

    function createComp() {
        const time = getTimeStamp(day, month, year)
        dispatch(createCompetition(compName, time, duration, disc, esID, useES, url, thumb, month, day, year, arenas, useTime))
    }

    function setESBool(value) {
        if (value && typeof value === "string") {
             if (value.toLowerCase() === "true") return setES(true);
             if (value.toLowerCase() === "false") return setES(false);
        }
        return setES(value);
    }

    function setUseTimeBool(value) {
        if (value && typeof value === "string") {
             if (value.toLowerCase() === "true") return setUseTime(true);
             if (value.toLowerCase() === "false") return setUseTime(false);
        }
        return setUseTime(value);
    }
    
    return (
        <Fade in={true} out={true} timeout={{enter: 100}}>
        <div className={classes.answerDiv}>
          <div className={classes.raisedCard}>
            <Title title="Create competition" />
            <TextField
                type='name'
                id="name"
                label="Comp name"
                variant="outlined"
                className={classes.detailsInput}
                onChange={(a) => {setCompName(a.target.value)}}
            />
            <br/>
            {/* <TextField
                type='number'
                id="time"
                label="Time - seconds"
                variant="outlined"
                className={classes.detailsInput}
                onChange={(a) => {setTime(a.target.value)}}
            />
            <br/> */}

            <select value={day} onChange={(e) => setDay(e.target.value)}>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
                <option value={10}>10</option>
                <option value={11}>11</option>
                <option value={12}>12</option>
                <option value={13}>13</option>
                <option value={14}>14</option>
                <option value={15}>15</option>
                <option value={16}>16</option>
                <option value={17}>17</option>
                <option value={18}>18</option>
                <option value={19}>19</option>
                <option value={20}>20</option>
                <option value={21}>21</option>
                <option value={22}>22</option>
                <option value={23}>23</option>
                <option value={24}>24</option>
                <option value={25}>25</option>
                <option value={26}>26</option>
                <option value={27}>27</option>
                <option value={28}>28</option>
                <option value={29}>29</option>
                <option value={30}>30</option>
                <option value={31}>31</option>
            </select>
            <br/>
            <select value={month} onChange={(e) => setMonth(e.target.value)}>
                <option value={1}>Jan</option>
                <option value={2}>Feb</option>
                <option value={3}>Mar</option>
                <option value={4}>Apr</option>
                <option value={5}>May</option>
                <option value={6}>Jun</option>
                <option value={7}>Jul</option>
                <option value={8}>Aug</option>
                <option value={9}>Sep</option>
                <option value={10}>Oct</option>
                <option value={11}>Nov</option>
                <option value={12}>Dec</option>
            </select>
            <br/>
            <select value={year} onChange={(e) => setYear(e.target.value)}>
                <option value={2023}>2023</option>
                <option value={2024}>2024</option>
            </select>
            <br/>
            <TextField
                type='number'
                id="duration"
                label="Duration - days"
                variant="outlined"
                className={classes.detailsInput}
                onChange={(a) => {setDuration(a.target.value)}}
            />
            <br/>
            <TextField
                type='name'
                id="url"
                label="Main Image url"
                variant="outlined"
                className={classes.detailsInput}
                onChange={(a) => {setUrl(a.target.value)}}
            />
            <br/>
            <TextField
                type='name'
                id="url"
                label="Thumbnail logo url"
                variant="outlined"
                className={classes.detailsInput}
                onChange={(a) => {setThumb(a.target.value)}}
            />
            <br/>
            <FormControl
                className={classes.formControl}
                onChange={(e) => setDisc(e.target.value)}
              >
                <InputLabel htmlFor="age-native-simple">Discipline</InputLabel>
                <Select
                  native
                  inputProps={{
                      name: 'Discipline',
                      id: 'age-native-simple',
                  }}
                > 
                    <option value='none'></option>
                    <option value={'Dressage Showjumping XC'}>Dressage, Showjumping, XC</option>
                    <option value={'Showjumping XC'}>Showjumping, XC</option>
                    <option value={'Showjumping'}>Showjumping</option>
                    <option value={'XC'}>XC</option>
                    <option value={'Dressage'}>Dressage</option>
                </Select>
            </FormControl>
            <br/>
            <TextField
                type='name'
                id="name"
                label="ES ID"
                variant="outlined"
                className={classes.detailsInput}
                onChange={(a) => {setESID(a.target.value)}}
            />
            <br/>
            <SubTitle title='Use Eventing Scores'/>
            <select value={useES} onChange={(e) => setESBool(e.target.value)}>
                <option value={true}>true</option>
                <option value={false}>false</option>
            </select>
            <br/>
            <SubTitle title='Use Time'/>
            <select value={useTime} onChange={(e) => setUseTimeBool(e.target.value)}>
                <option value={true}>true</option>
                <option value={false}>false</option>
            </select>
            <br/>
            <TextField
                type='number'
                id="arenas"
                label="Number of arenas"
                variant="outlined"
                className={classes.detailsInput}
                onChange={(a) => {setArenas(a.target.value)}}
            />
            <br/>
            {/* <Button  variant="contained" color="primary"  onClick={() => getTimeStamp(day, month, year)} >Enter</Button> */}

            {compName && duration && url && disc && esID && thumb && <Button variant="contained" color="primary" onClick={() => createComp()}>Enter</Button>}
          </div>
          </div>
          
        </Fade>
    );
}