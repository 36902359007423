import { firebaseApp } from '../../firebase';
import {
  SET_RIDER,
  GET_RIDERS_ENTRIES,
  SET_RIDER_EVENT,
} from './Types';

export const setRider = (rider, history) => {
    return dispatch => {
        dispatch({
            type: SET_RIDER,
            payload: rider
        })
        history.push('/rider-profile')
    }
}

export const getRidersEntries = (rider) => {
    return async dispatch => {
        const entries = await firebaseApp.firestore().collection('es1').where('RiderID', '==', rider.objectID).get()

        const totalEntries = [];

        entries.docs.forEach((doc) => {
            totalEntries.push(doc.data())
        })

        console.log(totalEntries)

        const sortedResults = totalEntries.sort((a, b) => parseFloat(b.DR_TimeDue1) - parseFloat(a.DR_TimeDue1));

        const filteredResult = sortedResults.reduce((unique, o) => {
            if(!unique.some(obj => obj.esID === o.esID)) {
              unique.push(o);
            }
            return unique;
        },[]);

        console.log(sortedResults)

        dispatch({
            type: GET_RIDERS_ENTRIES,
            payload: filteredResult,
            payload1: sortedResults
        })
    }
}

export const setRiderEvent = (event, totalEntries, history) => {
    return dispatch => {
        const eventEntries = [];


        totalEntries.forEach((el) => {
          if (el.event_name === event.event_name) {
              eventEntries.push(el)
          }
        });

        dispatch({
            type: SET_RIDER_EVENT,
            payload: eventEntries,
        })

        history.push('/rider-event-view')
    }
}