import React from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import {textStyle} from '../../Styles'

// Sections for this page
const useStyles = makeStyles(textStyle);

export function Footer() {
    const history = useHistory();
    const classes = useStyles();

    return (
      <div className='footer'>
        <h4
          style={{
            paddingTop: '40px'
          }}
          className={classes.description}
        >
          +44 7880 239001
        </h4>
        <h4
           className={classes.description}
        >
          info@athalens.com
        </h4>
        <h4
            className={classes.description}
           onClick={() => history.push('/privacy-policy')}
        >
          Privacy Policy
        </h4>
        <h4
          className={classes.description}
           onClick={() => history.push('/terms')}
        >
          Terms of Use
        </h4>
        <h4
          className={classes.description}
           onClick={() => history.push('/cookie-policy')}
        >
          Cookie Policy
        </h4>
      </div>
    )
}