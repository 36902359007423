import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {Title} from '../../Common';
import {containerStyle, textStyle} from "../../../Styles";
import {Fade, ListItemText, Button, ListItem, Divider} from '@material-ui/core';
import {fetchSectionList, setDisciplines} from '../../../ActionReducers/Actions';

const style = {
    ...containerStyle,
    ...textStyle
  }
const useStyles = makeStyles(style);

export default function AdminDisciplinesAdd() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const {event, eventDisciplineList} = useSelector(state => state.admin)

    const [disc, setDisc] = useState({}) 

      useEffect(() => {
        dispatch(fetchSectionList(event))
      }, [dispatch])

      const handleChange = (i) => {
        if (disc[i.id]) {
          if (disc[i.id][i.value]) {
            const v = disc[i.id]
            setDisc({
              ...disc,
              [i.id]: {
                ...v,
                [i.value]: !v
              }
            })
          } else {
            const c = disc[i.id]
            setDisc({
              ...disc,
              [i.id]: {
                ...c,
                [i.value]: true
              }
            })
          }
        } else {
          setDisc({
            ...disc,
            [i.id]: {
              [i.value]: true
            }
          })
        }
      }

      return (
        <Fade in={true} out={true} timeout={{enter: 100}}>
        <div className={classes.answerDiv}>
          <div className={classes.raisedCard}>
          <Title title={event.name} />
          {eventDisciplineList && eventDisciplineList.length > 1 && eventDisciplineList.map((event1) => {
              return (
                  <div>
                    <ListItem button key={4}>
                        <ListItemText primary={event1.classCode} secondary={event1.section} />
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                          <div style={{display: 'flex', flexDirection: 'column', width: 30}}>
                            <label>Trot</label>
                            <input type="checkbox" id={event1.section} name="Trot up" value="Trot up" onChange={(a) => handleChange(a.target)}/>
                          </div>
                          <div style={{display: 'flex', flexDirection: 'column', width: 30}} onChange={(a) => handleChange(a.target)}>
                          <label>Dr</label>
                          <input type="checkbox" id={event1.section} name="dr" value="Dressage" />
                          </div>
                          <div style={{display: 'flex', flexDirection: 'column', width: 30}} onChange={(a) => handleChange(a.target)}>
                          <label>SJ</label>
                          <input type="checkbox" id={event1.section} name="sj" value="Showjumping" />
                          </div>
                          <div style={{display: 'flex', flexDirection: 'column', width: 30}} onChange={(a) => handleChange(a.target)}>
                          <label>XC</label>
                          <input type="checkbox" id={event1.section} name="xc" value="XC" />
                          </div>
                          <div style={{display: 'flex', flexDirection: 'column', width: 30}} onChange={(a) => handleChange(a.target)}>
                          <label>Prize</label>
                          <input type="checkbox" id={event1.section} name="prize" value="Prize giving" />
                          </div>
                        </div>
                    </ListItem>
                    <Divider />
                </div>
              )
          })}
          <br/>
          <Button onClick={() => dispatch(setDisciplines(disc, event))}>Submit</Button>
          </div>
          
        </div>
        </Fade>
    );
}