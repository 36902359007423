import React, {useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import { useHistory } from "react-router-dom";
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {Button, CircularProgress} from '@material-ui/core';
import {apiInstance} from '../../Utils';
import {Error} from '../Common';
import {paymentSuccess} from '../../ActionReducers/Actions';
import { firebaseApp } from '../../firebase';

export default function MyCheckoutForm(props) {
  const stripe = useStripe();
  const dispatch = useDispatch();
  const {basket} = useSelector(state => state.basket);
  const history = useHistory();
  const elements = useElements();
  const {price, user} = props;
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)

  const handleFormSubmit = async evt => {
    evt.preventDefault();
    setError('')
    setLoading(true)
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement('card');
    const amount = (price * 100).toFixed(0);
    const name = user.name + ' ' + user.lastName
    const {AddressLine1, AddressLine2, City, County, PostCode} = user.address

    const user1 = firebaseApp.auth().currentUser;

    let email = 'will@athalens.com'

    if (user1.email) {
      email = user1.email
    }
     
    console.log('email123', email)
    const shipping = {
      name,
      address: {
        line1: AddressLine1,
        line2: AddressLine2 || ' ',
        city: City,
        state: County,
        postal_code: PostCode,
        country: 'GB'
      },
    }

    apiInstance.post('/payments/create', {
      amount,
      shipping: {
        name,
        address: {
          line1: AddressLine1,
          city: City,
          state: County,
          postal_code: PostCode,
          country: 'GB'
        },
      },
      email
    }).then(({ data: clientSecret }) => {
      stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name,
          address: shipping.address
        }
        
      }).then(({ paymentMethod }) => {
        stripe.confirmCardPayment(clientSecret, {
          
          payment_method: paymentMethod.id
        })
        .then(({ paymentIntent, error }) => {

          if (paymentIntent) {
            if (paymentIntent.status === 'succeeded') {
              setLoading(false)
              console.log('success')
              return dispatch(paymentSuccess(user, basket, history))
            } else {
              setError('Something went wrong. Please try again.')
              console.log('fail 1')
              setLoading(false)
            }
          } else if (error) {
            if (error.message) {
              setError(error.message)
              console.log('fail 2')
              setLoading(false)
            } else {
              setError('Something went wrong. Please try again.')
              console.log('fail 3')
              setLoading(false)
            }
          } else {
            setError('Something went wrong. Please try again.')
            console.log('fail 4')
            setLoading(false)
          }

        })
        .catch((erro) => {
          console.log('fail 5: ', erro)
          setError('Something went wrong. Please try again.')
          setLoading(false)
        })

      })
      .catch((err) => {
        console.log('fail 6: ', err )
        setError('Something went wrong. Please try again.')
        setLoading(false)
      })


    })
    .catch((error) => {
      console.log('fail 7: ', error)
      setError('Something went wrong. Please try again.')
      setLoading(false)
    });
  }

  const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#000",
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#18cca3"
        },
        "::placeholder": {
          color: "#18cca3"
        }
      },
      invalid: {
        iconColor: "#ffc7ee",
        color: "#ffc7ee"
      }
    },
    hidePostalCode: true
  };

  return (
    <div>
      <form onSubmit={handleFormSubmit}>
        <CardElement options={CARD_OPTIONS}/>
        <br/>
        {!loading && <Button type="submit" variant="contained" color="primary" disabled={!stripe}>
          Pay £{price}
        </Button>}
        {loading && <CircularProgress />}
        <Error title={error} />
      </form>
    </div>
    
  );
};