export const SET_USER = 'set_user';

export const DEFAULT_CASE = 'default_case';
export const EVENTS_FETCH = 'events_fetch';
export const IND_EVENT_FETCH = 'ind_event_fetch';
export const SEARCHING_FOR_IMAGES = 'searching_for_images';
export const SEARCHED_FOR_IMAGES = 'searched_for_images';
export const SEARCHED_FOR_IMAGES_OLD = 'searched_for_images_old';
export const SET_IMAGE_MODAL = 'set_image_modal';
export const SET_NUMBER = 'set_number';
export const CLOSE_IMAGE_MODAL = 'close_image_modal';

export const GET_PRODUCTS = 'get_products'
export const ADD_TO_BASKET = 'add_to_basket';
export const ON_ADD_TO_BASKET = 'on_add_to_basket';
export const ADD_TO_BASKET_ERROR = 'add_to_basket_error';
export const BASKET_LOADING = 'basket_loading';
export const GO_TO_BASKET = 'go_to_basket';
export const GET_BASKET = 'get_basket';
export const DELETE_BASKET_ITEM = 'delete_basket_item';
export const UPDATE_QUANTITY = 'update_quantity';
export const GET_ADDRESS = 'get_address';
export const SET_ADDRESS = 'set_address';
export const UPDATE_EMAIL = 'update_email';
export const UPDATE_NAME = 'update_name';

export const FORGOTTEN_PASSWORD = 'forgotten_password';

export const SET_BASKET_AS_ORDER = 'set_basket_as_order';
export const SET_ORDER = 'set_order';

export const IS_NEW_USER = 'is_new_user';
export const IS_NEW_USER_ERROR = 'is_new_user_error';
export const SIGN_IN = 'sign_in';
export const SIGN_IN_ERROR = 'sign_in_error';
export const CREATE_ACCOUNT = 'create_account';
export const CREATE_ACCOUNT_ERROR = 'create_account_error';
export const AUTH_LOADING = 'auth_loading';
export const SIGN_OUT = 'sign_out';
export const SIGN_OUT_ERROR = 'sign_out_error';
export const GET_USER = 'get_user';
export const GET_ORDERS = 'get_orders';
export const ORDER_DETAIL = 'order_detail';

export const GO_TO_PHOTO_UPLOAD = 'go_to_photo_upload';
export const UNSORTED_FILES_SELECTED = 'unsorted_files_selected';
export const UNSORTED_LOAD_IMAGES_START = 'unsorted_load_images_start';
export const UNSORTED_LOADED_SINGLE_IMAGE = 'unsorted_loaded_single_image';
export const UNSORTED_LOAD_IMAGES_FINISHED = 'unsorted_load_images_finished';

export const FETCH_ORDERS = 'fetch_orders';
export const SET_ORDER_DETAIL = 'set_order_detail';
export const GO_TO_NEXT_ORDER = 'got_to_next_order';
export const SET_OPEN = 'set_open';
export const ADMIN_CROP = 'admin_crop';
export const GET_PHOTOGRAPHERS_PHOTOS = 'get_photographers_photos';

export const GET_LIVE_ORDER = 'get_live_order';
export const SUBMIT_ORDER = 'submit_order';
export const SUBMITTING_ORDER = 'submitting_order';
export const SUBMIT_ORDER_ERROR = 'submitting_order_error';

export const CREATE_COMPETITION = 'create_competition';

export const GET_IMAGES_ES = 'get_images_es';
export const FIND_NUMBERS = 'find_numbers';

export const SEARCHED_BY_TIME_SJ = 'searched_by_time_sj';
export const SEARCHED_BY_TIME_XC = 'searched_by_time_xc';
export const SEARCHED_BY_TIME_DR = 'searched_by_time_dr';
export const SEARCHED_BY_TIME_MAIN = 'searched_by_time_main';
export const PRIZE_GIVING = 'prize_giving';


export const SET_RIDER = 'set_rider';
export const GET_RIDERS_ENTRIES = 'get_riders_entries';
export const SET_RIDER_EVENT = 'set_rider_event';

export const UNSORTED_SPONSOR_FINISH = 'unsorted_sponsor_finish';
export const GO_TO_DISCIPLINES = 'go_to_disciplines'
export const EVENTS_DISCIPLINE_FETCH = 'events_discipline_fetch';
export const EVENTS_DISCIPLINE_SEND = 'events_discipline_send';