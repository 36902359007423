import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {goToBasket, continueShopping} from '../../ActionReducers/Actions';
import {SubTitle} from '../Common';
import { makeStyles } from "@material-ui/core/styles";
import {Modal} from '@material-ui/core'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
    modal: {
      display: 'flex',
      justifyContent: 'center',
      overflowY: 'auto',
      opacity: 1,
      outline: 'none',
      "&:focus": {
        outline: 'none',
      },
      backgroundColor: 'rgba(0, 0, 0, 0.7);'
    },
    paper: {
      width: '300px',
      borderWidth: 0,
      backgroundColor: '#fff',
      marginTop: '20vh',
      borderRadius: 5,
      padding: '20px',
      justifyContent: 'center'
    },
}));

export default function BasketConfirmation() {
    const dispatch = useDispatch()
    const history = useHistory();

    const classes = useStyles();
    const {basketConfirmVisible} = useSelector(state => state.basket)
    const {eventData, number} = useSelector(state => state.events)
    return (
        <Modal
            className={classes.modal}
            open={basketConfirmVisible}
            onClose={() => dispatch(goToBasket(history))}
            closeAfterTransition
            BackdropProps={{
            timeout: 500,
            }}
        >
              <div style={{outline: 0, justifyContent: 'center'}}>
                <TransformWrapper>
                  <TransformComponent>
                    <div className={classes.paper}>
                      <SubTitle title="Item added successfully, would you like to..."/>
                      <div style={{display: 'flex', flexDirection: 'column'}}>
                        <Button variant="contained" color="primary" onClick={() => dispatch(goToBasket(history))}>Go to basket</Button>
                        <SubTitle title='Or' />
                        <Button variant="contained" color="primary" onClick={() => dispatch(continueShopping(eventData.uid, number, history))}>Continue shopping</Button>
                      </div>
                    </div>
                  </TransformComponent>
                </TransformWrapper>
              </div>
      </Modal>
    )
}