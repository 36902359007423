import React, {useEffect, useState} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import {useDispatch, useSelector} from 'react-redux'

import { makeStyles } from "@material-ui/core/styles";
import {ParallaxTitle, ParallaxText, Title, Error} from '../Common';
import {containerStyle} from "../../Styles";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Parallax from '../Grid/Parallax';
import Background from '../../Images/website_background_75.png'
import {individualEventFetch, setImageModal, setNumber, searchNumberES, searchNumber} from '../../ActionReducers/Actions';
import { Button, TextField, CircularProgress, Tab, Tabs } from "@material-ui/core";
import ImageModal from './ImageModal';
import {isMobileOnly} from 'react-device-detect';
import DrSjView from './ImageView/DrSjView';
import DrView from './ImageView/DrView';
import XCView from './ImageView/XCView';
import ImageListOld from './ImageView/ImageListOld';
import SearchByTimeMain from './Components/SearchByTimeMain';
import SearchByTimeArena from './Components/SearchByTimeArena';
import PrizeGiving from './Components/PrizeGiving';

const useStyles = makeStyles(containerStyle);

export default function EventView(props) {
    const classes = useStyles();
    const dispatch = useDispatch()
    const {eventid} = props.match.params;
    const [tabValue, setTabValue] = useState(0);
    const [prizeGiving, setPrizeGiving] = useState(false)
    const {eventData, number, drPhotos, sjPhotos, xcPhotos, xcWarning, prizeGivingPhotos, imagesOld, loadingImages, modalVisible, imageInModal, searchByTimeMainPhotos} = useSelector(state => state.events)
  
    useEffect(() => {
        dispatch(individualEventFetch(eventid))
    }, [dispatch, eventid])

    const title = 'Search by number'
    let name;
    if (eventData) {
        name = eventData.name
    } else {
        name = 'Loading...'
    }

    let message = '';
    if (eventData) {
        message = eventData.message
    } else {
        message = ''
    }

    let backgroundImage = Background;

    if (eventData) {
        backgroundImage = 'https://firebasestorage.googleapis.com/v0/b/athalens-51eb0-profile-pics/o/athalens-color.png?alt=media&token=24d88ce6-8cfa-4312-a160-e7416c55e150'
    } else {
        backgroundImage = 'https://firebasestorage.googleapis.com/v0/b/athalens-51eb0-profile-pics/o/athalens-color.png?alt=media&token=24d88ce6-8cfa-4312-a160-e7416c55e150'
    }

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };
    console.log('drPhotos: ', drPhotos)
    console.log('sjPhotos: ', sjPhotos)
    console.log('xcPhotos: ', xcPhotos)
    return (
      <div className={classes.answerDiv}>
            <Parallax filter image={backgroundImage} fixedImage={false}>
                <div className={classes.container}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <ParallaxTitle title={name} />
                    <ParallaxText title={message} />                    
                </GridItem>
                </GridContainer>
                </div>
          </Parallax>
          <div className={classNames(classes.main, classes.mainRaised)}>
          <div>
          {eventData && !eventData.useTime && <Title title={title} />}
            {eventData && !eventData.useTime && <TextField
                label="Number"
                type="number"
                placeholder="123"
                onChange={(e) => {dispatch(setNumber(e.target.value))}}
            />}
            <div>
                <br />
            <div style={{display: 'flex', flexDirection: 'column', width: '200px', alignItems: 'center', margin: 'auto'}}>
            {!loadingImages && eventData && eventData.useES && !eventData.useTime && <Button
                color="primary"
                variant="contained"
                onClick={() => {setPrizeGiving(false); dispatch(searchNumberES(number, eventData.esID, eventid))}}
                disabled={!number}
            >
                Search number
            </Button>}
            <br/>
            {eventData && !eventData.useTime && eventData.prizeGiving && !prizeGiving && <Button
                color="primary"
                variant="contained"
                onClick={() => setPrizeGiving(true)}
            >
                Prize giving
            </Button>}
            </div>
            {!loadingImages && eventData && !eventData.useES && !eventData.useTime && <Button
                color="primary"
                variant="contained"
                onClick={() => dispatch(searchNumber(number, eventid))}
                loading={loadingImages}
            >
                Search
            </Button>}

            {eventData && eventData.useTime && eventData.arenas == 1 && <SearchByTimeMain eventid={eventid} eventData={eventData} loadingImages={loadingImages} disc={eventData.discipline[0]}/>}
            {eventData && eventData.useES && prizeGiving && <PrizeGiving eventid={eventid} eventData={eventData} loadingImages={loadingImages} prizes={eventData.prizes}/>}
            {eventData && eventData.useTime && eventData.arenas > 1 && <SearchByTimeArena eventid={eventid} eventData={eventData} loadingImages={loadingImages} disc={eventData.discipline[0]}/>}

            <br/>
            {loadingImages && <CircularProgress />}

            
            </div></div>
            <br />
            {/* <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => dispatch(testCall())}
                    loading={loadingImages}
                >
                    test call
                </Button> */}

            {/* This is the tab for the discipline */}
            {!prizeGiving && eventData && eventData.useES && !eventData.useTime && <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                <Tabs value={tabValue} textColor="primary" indicatorColor="primary" onChange={handleChange} aria-label="basic tabs example">
                    {eventData.discipline[0] && <Tab value={0} label={getShort(eventData.discipline[0])}/>}
                    {eventData.discipline[1] && <Tab value={1} label={getShort(eventData.discipline[1])}/>}
                    {eventData.discipline[2] && eventData.discipline[2] !== 'Prize giving' && <Tab value={2} label={getShort(eventData.discipline[2])}/>}
                </Tabs>
            </div>}

            {!prizeGiving && eventData && eventData.useES && !eventData.useTime && drPhotos && tabValue === 0 && eventData.discipline[0] === 'Dressage' && <DrView images={drPhotos} eventid={eventid} eventData={eventData} loadingImages={loadingImages} />}
            {!prizeGiving && eventData && eventData.useES && !eventData.useTime && sjPhotos && tabValue === 0 && eventData.discipline[0] === 'Showjumping' && <DrSjView images={sjPhotos} discipline={'Showjumping'} eventid={eventid} eventData={eventData} loadingImages={loadingImages} />}
            {!prizeGiving && eventData && eventData.useES && !eventData.useTime && sjPhotos && tabValue === 1 && eventData.discipline[1] === 'Showjumping' && <DrSjView images={sjPhotos} discipline={'Showjumping'} eventid={eventid} eventData={eventData} loadingImages={loadingImages} />}
            {!prizeGiving && eventData && eventData.useES && !eventData.useTime && xcPhotos && tabValue === 1 && eventData.discipline[1] === 'XC' && <XCView images={xcPhotos} warning={'xcWarning'} eventid={eventid} eventData={eventData} loadingImages={loadingImages}/>}
            {!prizeGiving && eventData && eventData.useES && !eventData.useTime && xcPhotos && tabValue === 2 && eventData.discipline[2] === 'XC' && <XCView images={xcPhotos} warning={xcWarning} eventid={eventid} eventData={eventData} loadingImages={loadingImages}/>}

            {eventData && !eventData.useES && !eventData.useTime && 
                <GridContainer>
                    {showImageList(imagesOld, dispatch)}
                </GridContainer>
            }

            {eventData && !eventData.useES && eventData.useTime && 
                <GridContainer>
                    {showImageList(searchByTimeMainPhotos, dispatch)}
                </GridContainer>
            }

            {eventData && eventData.useES && prizeGiving && 
                <GridContainer>
                    {showImageList(prizeGivingPhotos, dispatch)}
                </GridContainer>
            }
            <ImageModal
                open={modalVisible}
                image={imageInModal}
                handleClose={() => dispatch(setImageModal(null, false))}
                isMobile={isMobileOnly}
            />
          </div>
      </div>
    );
}

function showImageList(images, dispatch) {
    console.log('images: ', images)
    if (images) {
        if (images.length > 0) {

            return images.map((image, index) => <ImageListOld image={image} key={image.uid} setImage={() => dispatch(setImageModal(image, true))}/>)
        } else {
            const title = "We haven't managed to upload these photos yet."
            return <Error title={title} />
        }
    } else {
      return
    }
    
  }

function getShort(disc) {
    if (disc === 'Showjumping') {
        return 'SJ'
    } else if (disc === 'Dressage') {
        return 'DR'
    } else {
        return 'XC'
    }
}