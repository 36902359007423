import React, {useState} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import {useSelector} from 'react-redux'
import {Tab, Tabs } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import {ParallaxTitle, Title, Footer} from '../Common';
import {containerStyle} from "../../Styles";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Parallax from '../Grid/Parallax';
// import {getRidersEntries} from '../../ActionReducers/Actions';
// import { useHistory } from "react-router";
// Sections for this page

const useStyles = makeStyles(containerStyle);

export default function RiderEventView() {
    const classes = useStyles();
    const [tabValue, setTabValue] = useState(0);

    // const history = useHistory()
    // const dispatch = useDispatch()
    const {rider, riderEvent} = useSelector(state => state.rider)
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };
    // useEffect(() => {
    //   if (rider) {
    //       dispatch(getRidersEntries(rider))
    //   } else {
    //     history.push('/rider-search')
    //   }
    // }, [dispatch, rider, history])

    console.log(riderEvent)
    const Background = 'https://firebasestorage.googleapis.com/v0/b/athalens-51eb0-profile-pics/o/athalens-color.png?alt=media&token=24d88ce6-8cfa-4312-a160-e7416c55e150'

    return (
      <div className={classes.answerDiv}>
        <Parallax filter image={Background} fixedImage={true}>
                <div className={classes.container}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    {rider && <ParallaxTitle title={rider.Rider} />}
                </GridItem>
                </GridContainer>
                
                </div>
          </Parallax>
          <div className={classNames(classes.main, classes.mainRaised)}>
            <Title title={riderEvent[0].event_name} />
            <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                  <Tabs value={tabValue} textColor="primary" indicatorColor="primary" onChange={handleChange} aria-label="basic tabs example">
                      <Tab value={0} label={'Times'}/>
                      <Tab value={1} label={'Results'}/>
                      <Tab value={2} label={'Photos'}/>
                      <Tab value={2} label={'Photos'}/>
                  </Tabs>
              </div>
          </div>
          <Footer />
      </div>
    );
}
