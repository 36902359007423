import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import {Footer} from "../Common";
import GridContainer from "../Grid/GridContainer.js";
import GridItem from "../Grid/GridItem.js";
import Parallax from "../Grid/Parallax.js";

import {textStyle, containerStyle} from '../../Styles'
import PrivacySection from './PrivacySection';
// Sections for this page
const styles = {
  ...containerStyle,
  ...textStyle
}

const useStyles = makeStyles(styles);

export default function Privacy() {
    const classes = useStyles();
  
    return (
      <div>
        <Parallax filter image={require("../../Images/privacy.png")}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <h1 className={classes.parallaxTitle}>Privacy Policy.</h1>
                <h4 className={classes.parallaxText}>
                  The privacy policy explain what we do with your data and information we collect.
                </h4>
                <h4 className={classes.parallaxText}>
                  The privacy policy was last updated on the 4th October 2023
                </h4>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>
        <div className={classNames(classes.main, classes.mainRaised)}>
            <PrivacySection />
        </div>
        <Footer />
      </div>
    );
  }