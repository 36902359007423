import {
    EVENTS_FETCH,
    IND_EVENT_FETCH,
    SEARCHING_FOR_IMAGES,
    SEARCHED_FOR_IMAGES,
    SET_IMAGE_MODAL,
    SET_NUMBER,
    CLOSE_IMAGE_MODAL,
    GET_IMAGES_ES,
    SEARCHED_FOR_IMAGES_OLD,
    SEARCHED_BY_TIME_SJ,
    SEARCHED_BY_TIME_XC,
    SEARCHED_BY_TIME_DR,
    SEARCHED_BY_TIME_MAIN,
    PRIZE_GIVING,
  } from '../Actions/Types';
  
  const INITIAL_STATE = {
    user: null,
    eventList: null,
    eventData: null,
    loadingImages: false,
    images: null,
    modalVisible: false,
    imageInModal: null,
    number: null,
    imagesOld: null,
    drPhotos: null,
    sjPhotos: null,
    xcWarning: null,
    xcPhotos: null,
    searchByTimeMainPhotos: null,
    prizeGivingPhotos: null,
  };
  
  export function EventReducer (state = INITIAL_STATE, action) {
    switch (action.type) {
      case EVENTS_FETCH:
        return { ...state, eventList: action.payload}
      case IND_EVENT_FETCH:
        return { ...state, eventData: action.payload};
      case SEARCHING_FOR_IMAGES:
        return {...state, loadingImages: true, images: null};
      case SEARCHED_FOR_IMAGES:
        return {...state, loadingImages: false, drPhotos: action.drPhotos, sjPhotos: action.sjPhotos, xcWarning: action.xcWarning , xcPhotos: action.xcPhotos};
      case SET_IMAGE_MODAL:
        return {...state, imageInModal: action.payload, modalVisible: action.visible}
      case SET_NUMBER:
        return {...state, number: action.payload}
      case CLOSE_IMAGE_MODAL:
        return {...state, modalVisible: false};
      case GET_IMAGES_ES:
        return state;
      case SEARCHED_FOR_IMAGES_OLD:
        return {...state, loadingImages: false, imagesOld: action.payload};
      case SEARCHED_BY_TIME_SJ:
        return {...state, sjPhotos: action.payload, loadingImages: false}
      case SEARCHED_BY_TIME_XC:
        return {...state, xcPhotos: action.payload, loadingImages: false}
      case SEARCHED_BY_TIME_DR:
        return {...state, drPhotos: action.payload, loadingImages: false}
      case SEARCHED_BY_TIME_MAIN:
        return {...state, searchByTimeMainPhotos: action.payload, loadingImages: false};
      case PRIZE_GIVING:
        return {...state, prizeGivingPhotos: action.payload, loadingImages: false}
      default:
        return state;
    }
  };
  