import React from "react";
import {useSelector} from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import {Title} from '../../Common';
import {containerStyle, textStyle} from "../../../Styles";
import {Fade, Button} from '@material-ui/core';
const style = {
    ...containerStyle,
    ...textStyle
  }
const useStyles = makeStyles(style);

export default function AdminHome() {
    const classes = useStyles();
    const {userDoc} = useSelector(state => state.auth)

    return (
        <Fade in={true} out={true} timeout={{enter: 100}}>
        <div className={classes.answerDiv}>
          <div className={classes.raisedCard}>
          <Title title="Admin" />
          {userDoc && userDoc.admin &&
          <div>
            <a href='/admin/orders'>
            <Button variant="contained" color="primary" >Orders</Button>
            </a>
            <a href='/admin/create-comp'>
              <Button variant="contained" color="primary" >Create comp</Button>
            </a>
            <a href='/admin/number-sorter'>
              <Button variant="contained" color="primary" >View number sorter</Button>
            </a>
            <a href='/admin/view-photographers-photos'>
              <Button variant="contained" color="primary" >View photographers photos</Button>
            </a>
            <a href='/admin/sponsors'>
              <Button variant="contained" color="primary" >Add sponsors</Button>
            </a>
            <a href='/admin/disciplines'>
              <Button variant="contained" color="primary" >Disciplines</Button>
            </a>
          </div>
          }
          {!userDoc && <a href='/'>
            <Button variant="contained" color="primary" >Back</Button>
          </a>}
          {userDoc && !userDoc.admin && <a href='/'>
          <Button variant="contained" color="primary" >Back</Button>
          </a>}
          </div>
        </div>
        </Fade>
    );
}