import React, {useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux'
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {Title, SubTitle} from '../Common';
import {containerStyle, textStyle} from "../../Styles";
import {Divider, Button, CircularProgress, Fade} from '@material-ui/core';
import {getOrders, orderDetail} from '../../ActionReducers/Actions';
import OrderItem from '../Basket/OrderItem';

const style = {
    ...containerStyle,
    ...textStyle
  }
const useStyles = makeStyles(style);

export default function Orders() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const {user} = useSelector(state => state.auth);
    const {orderList, basketLoading} = useSelector(state => state.basket);

    useEffect(() => {
        if (user) {
            dispatch(getOrders(user))
        }
    }, [dispatch, user])

    return (
        <Fade in={true} out={true} timeout={{enter: 100}}>
        <div className={classes.answerDiv}>
          <div className={classes.raisedCard}>
          <Title title="Your orders" />
            {!user && <SubTitle title="Sign in to view your basket" />}
            {!user && <div>
                <Button variant="contained" color="primary" onClick={() => history.push('/signin')}>Sign in</Button>
            </div>}
            {!basketLoading && user && <SubTitle title="Click on the orders to view more detail." />}
            {basketLoading && <CircularProgress/>}
            {user && <Divider />}
            {!basketLoading && orderList && orderList.length > 0 && orderList.map((item) => {
                    return <OrderItem onClick={() => dispatch(orderDetail(item, history))} item={item} user={user}/>
                })}
            {user && <Button variant="contained" color="primary" onClick={() => history.push('/')}>Continue Shopping</Button>}
           
          </div>
          
        </div>
        </Fade>
    );
}