import React from 'react';
import { makeStyles } from "@material-ui/core/styles";

import {containerStyle} from "../../Styles";
const useStyles = makeStyles(containerStyle);
export function NextButton(props) {
    const {onClick, title} = props

    let text;
    if (title) {
        text = title
    } else {
        text = 'Next'
    }
    const classes = useStyles();
    return (
        <button
            className={classes.nextButton}
            onClick={onClick}
        >{text}</button>
    );
}